import { Stack, Grid, Typography, Backdrop, CircularProgress } from '@mui/material';
import { DefaultButton } from '../../ui/DefaultButton';
import { useState, useEffect } from 'react';
import { DefaultTextFIeld } from '../../ui/DefaultTextFIeld';
import { DefaultDropDown } from '../../ui/DefaultDropDown';
import { Formik } from 'formik';
import * as Yup from 'yup';
import api from '../../lib/axios'
import { enqueueSnackbar } from 'notistack';

export const TrackingNew = ({ trackingID, closeDetails, onDelete }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [order, setOrder] = useState("");
    const [endUser, setEndUser] = useState("");
    const [selectedPipeProfileName, setSelectedPipeProfileName] = useState(null);
    const [selectedFromSite, setSelectedFromSite] = useState(null);
    const [selectedToSite, setSelectedToSite] = useState(null);
    const [truckLoads, setTruckLoads] = useState("");
    const [notes, setNotes] = useState("");
    const [arrayPipeProfile, setArrayPipeProfile] = useState([]);
    const [arrayLocation, setArrayLocation] = useState([]);
    useEffect(() => {
        getSalesOrderDetail()
    }, [trackingID]);

    function getSalesOrderDetail() {
        setIsLoading(true)
        api.get('/apis/SalesOrders/salesOrderDetail').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayPipeProfile(resData.arrayPipeProfile)
                setArrayLocation(resData.arraySite)
                if (trackingID != "") {
                    getData(resData.arrayPipeProfile, resData.arraySite)
                } else {
                    if (order != "") {
                        setOrder("")
                        setOrder("")
                        setEndUser("")
                        setSelectedPipeProfileName(null)
                        setSelectedFromSite(null)
                        setSelectedToSite(null)
                        setTruckLoads("")
                        setNotes("")
                    }
                    setIsLoading(false)
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    function getData(arrayPipeProfile, arrayLocation) {
        // setIsLoading(true)
        api.get('/apis/SalesOrders/getById/' + trackingID).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var selectedPipeProfileName = arrayPipeProfile.filter(profileName => profileName._id == resData.pipeProfileID)
                if (selectedPipeProfileName.length > 0) {
                    selectedPipeProfileName = selectedPipeProfileName[0]
                } else {
                    selectedPipeProfileName = null
                }

                var selectedFromSite = arrayLocation.filter(location => location._id == resData.fromSiteID)
                if (selectedFromSite.length > 0) {
                    selectedFromSite = selectedFromSite[0]
                } else {
                    selectedFromSite = null
                }

                var selectedToSite = arrayLocation.filter(location => location._id == resData.toSiteID)
                if (selectedToSite.length > 0) {
                    selectedToSite = selectedToSite[0]
                } else {
                    selectedToSite = null
                }
                setOrder(resData.order)
                setEndUser(resData.endUser)
                setSelectedPipeProfileName(selectedPipeProfileName)
                setSelectedFromSite(selectedFromSite)
                setSelectedToSite(selectedToSite)
                setTruckLoads(resData.truckLoads)
                setNotes(resData.notes)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function apiCalling(order, endUser, selectedPipeProfileName, selectedFromSite, selectedToSite, truckLoads, notes) {
        var data = {
            order: order,
            endUser: endUser,
            pipeProfileID: selectedPipeProfileName._id,
            fromSiteID: selectedFromSite._id,
            toSiteID: selectedToSite._id,
            truckLoads: truckLoads,
            notes: notes,
        }
        if (trackingID != "") {
            return await api.put('/apis/SalesOrders/updateById/' + trackingID, data)
        } else {
            return await api.post('/apis/SalesOrders/add', data)
        }
    }

    return (
        <Stack
            width={700}
            zIndex={2}
            height={"100%"}
            borderRight={'1px solid'}
            borderColor={'grey.light'}
            sx={{
                bgcolor: "white",
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                    width: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                    width: '4px',
                    bgcolor: 'grey.400',
                },
            }}
        >
            <Stack p={3} gap={2} width="70%">
                <Typography fontWeight={500} fontSize={14} fontFamily={'Roboto'}>Tracking Details</Typography>
                <Formik
                    enableReinitialize
                    initialValues={{
                        submit: null,
                        order: order,
                        endUser: endUser,
                        selectedPipeProfileName: selectedPipeProfileName,
                        selectedFromSite: selectedFromSite,
                        selectedToSite: selectedToSite,
                        truckLoads: truckLoads,
                        notes: notes,
                    }}
                    validationSchema={Yup
                        .object()
                        .shape({
                            order: Yup.string().required('Order is required'),
                            endUser: Yup.string().required('End user is required'),
                            selectedPipeProfileName: Yup.object().required('Pipe profile is required'),
                            selectedFromSite: Yup.object().required('From site is required'),
                            selectedToSite: Yup.object().required('To site is required'),
                            truckLoads: Yup.string().required('Truck loads is required'),
                            notes: Yup.string().required('Notes is required'),
                        })}
                    onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                        console.log("onsubmit", values)
                        try {
                            setIsLoading(true)
                            setOrder(values.order)
                            setEndUser(values.endUser)
                            setSelectedPipeProfileName(values.selectedPipeProfileName)
                            setSelectedFromSite(values.selectedFromSite)
                            setSelectedToSite(values.selectedToSite)
                            setTruckLoads(values.truckLoads)
                            setNotes(values.notes)
                            var data = await apiCalling(values.order, values.endUser, values.selectedPipeProfileName, values.selectedFromSite, values.selectedToSite, values.truckLoads, values.notes)
                            setIsLoading(false)
                            if (data !== undefined && data.data.success) {
                                resetForm();
                                setStatus({ success: true });
                                setSubmitting(false);

                                enqueueSnackbar('Sale order ' + (trackingID == "" ? "added" : "updated"), {
                                    anchorOrigin: {
                                        horizontal: 'right',
                                        vertical: 'top'
                                    },
                                    variant: 'success'
                                });
                                closeDetails(true)
                            } else {
                                setStatus({ success: false });
                                setErrors({ submit: data.data.message });
                                setSubmitting(false);
                                enqueueSnackbar(data.data.message, {
                                    anchorOrigin: {
                                        horizontal: 'right',
                                        vertical: 'top'
                                    },
                                    variant: 'error'
                                });
                            }
                        } catch (err) {
                            console.error("error12", err);
                            setIsLoading(false)
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }

                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                        <form
                            onSubmit={handleSubmit}
                            style={{ height: "100%" }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <DefaultTextFIeld
                                        label={'Order'}
                                        placeholder={'E.g. SO-123'}
                                        value={values.order}
                                        name={"order"}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={Boolean(touched.order && errors.order)}
                                        helperText={touched.order && errors.order}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DefaultTextFIeld
                                        label={'End User'}
                                        placeholder={'E.g. ACME Energy, Inc.'}
                                        value={values.endUser}
                                        name={"endUser"}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={Boolean(touched.endUser && errors.endUser)}
                                        helperText={touched.endUser && errors.endUser}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DefaultDropDown
                                        label={'Pipe Profile'}
                                        placeholder={'Select Pipe Profile'}
                                        value={values.selectedPipeProfileName}
                                        name={"selectedPipeProfileName"}
                                        onBlur={handleBlur}
                                        onChange={(event, newValue) => {
                                            values.selectedPipeProfileName = newValue
                                            console.log("st", newValue)
                                        }}
                                        error={Boolean(touched.selectedPipeProfileName && errors.selectedPipeProfileName)}
                                        helperText={touched.selectedPipeProfileName && errors.selectedPipeProfileName}
                                        options={arrayPipeProfile}
                                        getOptionLabel={(option) => option.name}
                                        isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DefaultDropDown
                                        label={'From Site'}
                                        placeholder={'Select From Site'}
                                        value={values.selectedFromSite}
                                        name={"selectedFromSite"}
                                        onBlur={handleBlur}
                                        onChange={(event, newValue) => {
                                            values.selectedFromSite = newValue
                                            console.log("st", newValue)
                                        }}
                                        error={Boolean(touched.selectedFromSite && errors.selectedFromSite)}
                                        helperText={touched.selectedFromSite && errors.selectedFromSite}
                                        options={values.selectedToSite == null ? arrayLocation : arrayLocation.filter(location => location._id != values.selectedToSite._id)}
                                        getOptionLabel={(option) => option.siteName}
                                        isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DefaultDropDown
                                        label={'To Site'}
                                        placeholder={'Select To Site'}
                                        value={values.selectedToSite}
                                        name={"selectedToSite"}
                                        onBlur={handleBlur}
                                        onChange={(event, newValue) => {
                                            values.selectedToSite = newValue
                                            console.log("st", newValue)
                                        }}
                                        error={Boolean(touched.selectedToSite && errors.selectedToSite)}
                                        helperText={touched.selectedToSite && errors.selectedToSite}
                                        options={values.selectedFromSite == null ? arrayLocation : arrayLocation.filter(location => location._id != values.selectedFromSite._id)}
                                        getOptionLabel={(option) => option.siteName}
                                        isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DefaultTextFIeld
                                        label={'Truck Loads'}
                                        placeholder={'E.g. 10'}
                                        type={'number'}
                                        value={values.truckLoads}
                                        name={"truckLoads"}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={Boolean(touched.truckLoads && errors.truckLoads)}
                                        helperText={touched.truckLoads && errors.truckLoads}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DefaultTextFIeld
                                        label={'Notes'}
                                        placeholder={''}
                                        value={values.notes}
                                        name={"notes"}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={Boolean(touched.notes && errors.notes)}
                                        helperText={touched.notes && errors.notes}
                                        maxRows={10}
                                        multiline={true}
                                    />
                                </Grid>
                                <Stack direction={'row'} justifyContent={'flex-end'} gap={1.5} mt={5} flexGrow={1}>
                                    {trackingID != "" && < DefaultButton sx={{
                                        backgroundColor: "#EFEFEF", color: "#151D26", borderColor: "#F1F1F5", width: "100px", '&:hover': {
                                            backgroundColor: "#EFEFEF",
                                            color: "#151D26",
                                            borderColor: "#F1F1F5",
                                        },
                                    }}
                                        onClick={(e) => {
                                            onDelete()
                                        }}>
                                        Delete
                                    </DefaultButton>}
                                    <DefaultButton sx={{
                                        backgroundColor: "#EFEFEF", color: "#151D26", borderColor: "#F1F1F5", width: "100px", '&:hover': {
                                            backgroundColor: "#EFEFEF",
                                            color: "#151D26",
                                            borderColor: "#F1F1F5",
                                        },
                                    }}
                                        onClick={(e) => {
                                            closeDetails(false)
                                        }}>
                                        Cancel
                                    </DefaultButton>
                                    <DefaultButton sx={{
                                        backgroundColor: "#FCFCFE", color: "#151D26", borderColor: "#F1F1F5", width: "100px", '&:hover': {
                                            backgroundColor: "#FCFCFE",
                                            color: "#151D26",
                                            borderColor: "#F1F1F5",
                                        },
                                    }}
                                        disabled={isSubmitting}
                                        type="submit">
                                        Save
                                    </DefaultButton>
                                </Stack>
                            </Grid>
                        </form>
                    )}
                </Formik>

            </Stack>
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>

        </Stack >
    );
}