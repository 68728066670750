import mapboxgl from 'mapbox-gl';
import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { arrayInventoryStatus, getInventoryStatusColor } from '../../views/InventoryDetails/data/InventoryData'
import moment from 'moment';
import pin from '../../assets/images/pin.png';
import { useNavigate } from 'react-router-dom';
mapboxgl.accessToken = 'pk.eyJ1Ijoic3RlZWxkbmEiLCJhIjoiY2x3dGNzNjRtMDI4aTJqb24wc2tqeGF4byJ9.XjKo_-RCP80Ytrn6K07MxA';

export const Map = ({ sx, arrayInventory }) => {
    const navigate = useNavigate();
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(0);
    const [lat, setLat] = useState(0);
    const [zoom, setZoom] = useState(9);

    const initMap = () => {
        if (!map.current && mapContainer.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/steeldna/clwunfa5n01ak01pnflmb60p4',
                center: [lng, lat],
                zoom: zoom,
            });
            let arrayCluster = []
            arrayInventory.map((inventory) => {
                if (inventory.latitude != null && inventory.longitude != null && inventory.latitude != "" && inventory.longitude != "") {
                    arrayCluster.push(inventory)
                }
            })
            if (map.current && arrayCluster.length > 0) {
                map.current.setCenter([arrayCluster[0].longitude, arrayCluster[0].latitude])
            }

            const waypoints = arrayCluster.map((cluster) => ({
                type: "Feature",
                properties: cluster,
                geometry: {
                    type: "Point",
                    coordinates: [cluster.longitude, cluster.latitude, 0.0],
                },
            }));
            var objCluster = {
                "type": "FeatureCollection",
                "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
                "features": waypoints
            }


            map.current.on('load', () => {
                map.current.loadImage(pin, function (error, image) {
                    if (error) throw error;
                    map.current.addImage('custom-pin', image);
                });

                map.current.addSource('earthquakes', {
                    type: 'geojson',
                    data: objCluster,// 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
                    cluster: true,
                    clusterMaxZoom: 14,
                    clusterRadius: 50
                });

                map.current.addLayer({
                    id: 'clusters',
                    type: 'circle',
                    source: 'earthquakes',
                    filter: ['has', 'point_count'],
                    paint: {
                        // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
                        // with three steps to implement three types of circles:
                        //   * Blue, 20px circles when point count is less than 100
                        //   * Yellow, 30px circles when point count is between 100 and 750
                        //   * Pink, 40px circles when point count is greater than or equal to 750
                        'circle-color': [
                            'step',
                            ['get', 'point_count'],
                            'black',
                            100,
                            'black',
                            750,
                            'black'
                        ],
                        'circle-radius': [
                            'step',
                            ['get', 'point_count'],
                            20,
                            100,
                            30,
                            750,
                            40
                        ]
                    }
                });

                map.current.addLayer({
                    id: 'cluster-count',
                    type: 'symbol',
                    source: 'earthquakes',
                    filter: ['has', 'point_count'],
                    layout: {
                        'text-field': ['get', 'point_count_abbreviated'],
                        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                        'text-size': 12,
                    },
                    paint: {
                        'text-color': '#ffffff'
                    }
                });

                map.current.addLayer({
                    id: 'unclustered-point',
                    type: "symbol",
                    source: 'earthquakes',
                    filter: ['!', ['has', 'point_count']],
                    layout: {
                        "icon-image": "custom-pin",
                        "icon-size": 0.4,
                    },
                });

                // inspect a cluster on click
                map.current.on('click', 'clusters', (e) => {
                    const features = map.current.queryRenderedFeatures(e.point, {
                        layers: ['clusters']
                    });
                    const clusterId = features[0].properties.cluster_id;
                    map.current.getSource('earthquakes').getClusterExpansionZoom(
                        clusterId,
                        (err, zoom) => {
                            if (err) return;

                            map.current.easeTo({
                                center: features[0].geometry.coordinates,
                                zoom: zoom
                            });
                        }
                    );
                });

                // When a click event occurs on a feature in
                // the unclustered-point layer, open a popup at
                // the location of the feature, with
                // description HTML from its properties.
                map.current.on('click', 'unclustered-point', (e) => {
                    const coordinates = e.features[0].geometry.coordinates.slice();
                    const inventory = e.features[0].properties;

                    var popup = new mapboxgl.Popup({ closeOnClick: true })
                        .setLngLat(coordinates)
                        .setHTML(
                            `<div class='marker-wrapper'>
                                <div class='marker-header'>
                                    <div class="marker-header-icon"></div>
                                    <div class="marker-header-item">
                                        <div class="marker-header-item-value">${inventory.purchaseOrder}</div>
                                        <div class="marker-header-item-name">Order</div>
                                    </div>
                                    <div class="marker-header-item">
                                        <div class="marker-header-item-value">${inventory.pipeType}</div>
                                        <div class="marker-header-item-name">Type</div>
                                    </div>
                                </div>
                                <div class='marker-content'>
                                    <div class='marker-item'>
                                        <div class='marker-item-value'>${inventory.footage}</div>
                                        <div class='marker-item-name'>${'Footage'}</div>
                                    </div>
                                    <div class='marker-item'>
                                       <div class='marker-item-value'>${inventory.quantity}</div>
                                        <div class='marker-item-name'>${'Joints'}</div>
                                    </div>
                                    <div class='marker-item'>
                                        <div class="marker-item-box">
                                            <div class='marker-item-value'>${arrayInventoryStatus.find(obj => obj.id == inventory.status)?.value}</div>
                                            <div class='marker-item-value marker-item-circle' style="background-color: ${getInventoryStatusColor(inventory.status)};"></div>
                                        </div>
                                        <div class='marker-item-name'>${'Status'}</div>
                                    </div>
                                </div>
                                <div class="marker-footer">
                                    <div class="marker-footer-icon"></div>
                                    <div class="marker-footer-content">
                                        <div class="marker-footer-address">${inventory.address ? inventory.address : ""}</div>
                                        <div class="marker-footer-time">${moment(inventory.updatedAt).format("MM.DD.YYYY @ hh:mm a")}</div>
                                    </div>
                                    <button type="button" class="marker-footer-btn"></button>
                                </div>
                            </div>
                            `
                        )
                        .addTo(map.current);

                    popup.getElement().addEventListener('click', function () {
                        console.log('Pop-up clicked!');
                        navigate(`/inventory/InventoryDetail`, { state: { id: inventory._id } })
                    });

                });

                map.current.on('mouseenter', 'clusters', () => {
                    map.current.getCanvas().style.cursor = 'pointer';
                });
                map.current.on('mouseleave', 'clusters', () => {
                    map.current.getCanvas().style.cursor = '';
                });
            });
            map.current.addControl(new mapboxgl.NavigationControl());

            // for (const inventory of arrayInventory) {
            //     var latitude = inventory.latitude ? inventory.latitude : ""
            //     var longitude = inventory.longitude ? inventory.longitude : ""
            //     if (latitude != "" && longitude != "") {
            //         if (lat === 0) {
            //             setLat(latitude)
            //             setLng(longitude)
            //             if (map.current) {
            //                 map.current.setCenter([longitude, latitude])
            //             }
            //         }
            //         const el = document.createElement('div');
            //         el.className = 'marker';
            //         new mapboxgl.Popup({ closeOnClick: false })
            //             .setLngLat([longitude, latitude])
            //             .setHTML(
            //                 `<div class='marker-wrapper'>
            //                 <div class='marker-header'>
            //                     <div class="marker-header-icon"></div>
            //                     <div class="marker-header-item">
            //                         <div class="marker-header-item-value">${inventory.purchaseOrder}</div>
            //                         <div class="marker-header-item-name">Order</div>
            //                     </div>
            //                     <div class="marker-header-item">
            //                         <div class="marker-header-item-value">${inventory.pipeType}</div>
            //                         <div class="marker-header-item-name">Type</div>
            //                     </div>
            //                 </div>
            //                 <div class='marker-content'>
            //                     <div class='marker-item'>
            //                         <div class='marker-item-value'>${inventory.footage}</div>
            //                         <div class='marker-item-name'>${'Footage'}</div>
            //                     </div>
            //                     <div class='marker-item'>
            //                        <div class='marker-item-value'>${inventory.quantity}</div>
            //                         <div class='marker-item-name'>${'Joints'}</div>
            //                     </div>
            //                     <div class='marker-item'>
            //                         <div class="marker-item-box">
            //                             <div class='marker-item-value'>${arrayInventoryStatus.find(obj => obj.id == inventory.status)?.value}</div>
            //                             <div class='marker-item-value marker-item-circle' style="background-color: ${getInventoryStatusColor(inventory.status)};"></div>
            //                         </div>
            //                         <div class='marker-item-name'>${'Status'}</div>
            //                     </div>
            //                 </div>
            //                 <div class="marker-footer">
            //                     <div class="marker-footer-icon"></div>
            //                     <div class="marker-footer-content">
            //                         <div class="marker-footer-address">${inventory.address ? inventory.address : ""}</div>
            //                         <div class="marker-footer-time">${moment(inventory.updatedAt).format("MM.DD.YYYY @ hh:mm a")}</div>
            //                     </div>
            //                     <button type="button" class="marker-footer-btn"></button>
            //                 </div>
            //             </div>
            //             `
            //             )
            //             .addTo(map.current);

            //     }
            // }


        }
    };

    useEffect(() => {
        if (map.current) {
            map.current.remove();
            map.current = null
        }

        initMap()
    }, [arrayInventory]);

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                ...sx,
            }}
            ref={mapContainer}
        />
    );
};
