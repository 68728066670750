import { Cluster } from '../../assets/icons/Cluster';
import { InspectionsIcon } from '../../assets/icons/InspectionsIcon';
import { InventoryIcon } from '../../assets/icons/InventoryIcon';
import { Metrics } from '../../components/Metrics';
import { Details } from './Details';
import { tabs } from './data/tabs';
import { ColorStatus } from '../../ui/ColorStatus';
import { Tabs } from '../../ui/Tabs';
import { Backdrop, CircularProgress, Stack, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Attachments } from './Attachments.jsx';
import { Notes } from './Notes.jsx';
import { Map } from '../../components/Map/Map.jsx';
import api from '../../lib/axios'
import { arrayInventoryStatus } from './data/InventoryData';
import { useNavigate } from 'react-router-dom';
import { InventoryNew } from './InventoryNew';

export const InventoryDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const selectedDataId = location.state.id
    const [activeTab, setActiveTab] = useState(tabs[0].value);
    const [isLoading, setIsLoading] = useState(false);
    const [objInventory, setObjInventory] = useState({})
    const [isEditOpened, setIsEditOpened] = useState(selectedDataId == "" ? true : false);
    const [metrics1, setMetrics1] = useState([
        {
            title: 'Total Footage',
            value: '0',
        },
        {
            title: 'Total Joints',
            value: '0',
        },
        {
            title: 'Tonnage',
            value: '00.00',
        },
    ]);
    const metrics2 = [
        {
            title: 'Health Score',
            value: '82%',
            hasIndicator: true,
        },
        {
            title: 'Utilization',
            value: '60%',
            icon: <Cluster />,
        },
        {
            title: 'Last 30 Days',
            value: '+50%',
            isGreen: true,
        },
    ];
    const handleChangeTab = (tab) => {
        if (!isEditOpened) {
            setActiveTab(tab);
        }
    };

    const onEditClick = () => {
        setIsEditOpened(true);
    };

    useEffect(() => {
        if (selectedDataId != "") {
            getInventoryData()
        }
    }, []);

    function formatNumber(num) {
        const stringValue = String(Math.floor(num));
        const firstFourDigits = stringValue.slice(0, 4);
        const firstFourDigitsDouble = parseFloat(firstFourDigits);

        if (!isNaN(firstFourDigitsDouble)) {
            const result = firstFourDigitsDouble / 100;
            const formattedResult = result.toFixed(2).padStart(5, '0');
            return formattedResult; // Output: "44.52"
        }
        return "00.00";
    }


    function getInventoryData() {
        setIsLoading(true)
        api.get('/apis/Inventories/getDetailById/' + selectedDataId).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setObjInventory(resData)
                var weight = resData.pipeProfile.attributes.find(obj => obj.title == "Weight (lb/ft)")?.value
                var floatWeight = isNaN(parseFloat(weight)) ? 0.0 : parseFloat(weight)
                var floatFootage = isNaN(parseFloat(resData.footage.replace(/,/g, ''))) ? 0.0 : parseFloat(resData.footage.replace(/,/g, ''));
                var tonnage = floatFootage * floatWeight
                tonnage = formatNumber(tonnage)
                var matrix = [
                    {
                        title: 'Total Footage',
                        value: resData.footage,
                    },
                    {
                        title: 'Total Joints',
                        value: resData.quantity,
                    },
                    {
                        title: 'Tonnage',
                        value: tonnage,
                    },
                ]
                setMetrics1(matrix)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const isFirstTab = activeTab === tabs[0].value
    const isSecondTab = activeTab === tabs[1].value
    const isThirdTab = activeTab === tabs[2].value
    const isForthTab = activeTab === tabs[3].value


    return (
        <Stack py={2} px={2.5} width={'100%'}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mb={3}>
                <Stack direction={'row'} spacing={8}>
                    <Stack spacing={2}>
                        <Typography fontSize={20}>Purchase Order</Typography>
                        <Typography fontSize={25} fontWeight={700}>
                            {objInventory.purchaseOrder}
                        </Typography>
                    </Stack>
                    <Stack spacing={2}>
                        <Stack direction={'row'} spacing={3} alignItems={'center'}>
                            <Typography fontSize={20}>Status</Typography>
                            <ColorStatus code={parseInt(objInventory.status)} />
                        </Stack>
                        <Typography fontSize={25}>{arrayInventoryStatus.find(obj => obj.id == objInventory.status)?.value}</Typography>
                    </Stack>
                </Stack>
            </Stack>
            <Stack direction={'row'} gap={4} rowGap={2} flexWrap={'wrap'} mb={3}>
                <Metrics icon={<InventoryIcon />} data={metrics1} />
                <Metrics icon={<InspectionsIcon />} data={metrics2} />
            </Stack>
            <Tabs data={tabs} activeTab={activeTab} setActiveTab={handleChangeTab} />
            <Stack
                flex={1}
                border={'1px solid'}
                borderColor={'grey.light'}
                borderRadius={'5px'}
                overflow={'hidden'}
                bgcolor={'common.white'}
                position={'relative'}
            >
                {isFirstTab && <Details inventoryID={selectedDataId} disabled={isEditOpened} onEditClick={onEditClick} inventoryData={objInventory} />}
                {isSecondTab && <Attachments inventoryID={selectedDataId} disabled={isThirdTab} />}
                {isThirdTab && <Notes inventoryID={selectedDataId} disabled={isThirdTab} />}
                {isForthTab && <Map arrayInventory={[objInventory]} />}
                {isEditOpened &&
                    <InventoryNew
                        inventoryID={selectedDataId}
                        closeDetails={(e) => {
                            if (selectedDataId == "") {
                                navigate(-1)
                            } else {
                                setIsEditOpened(false)
                                if (e) {
                                    getInventoryData()
                                }
                            }

                        }}
                    />
                }
            </Stack>
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack>
    );
};
