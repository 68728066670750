import { DefaultDataGrid } from '../../ui/DefaultDataGrid';
import { Backdrop, Box, Button, CircularProgress, Grid, IconButton, Stack, Typography, } from '@mui/material';
import { useState, useEffect, createRef } from 'react';
import { Popup } from '../../components/Popup/index.js';
import { ColorStatus } from '../../ui/ColorStatus/index.js';
import { CircleButton } from '../../ui/CircleButton/index.js';
import { Close } from '@mui/icons-material';
import api from '../../lib/axios'
import { ArrowForwardIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import QrCode from '../../assets/icons/QrCode.svg';
import { Line } from 'react-chartjs-2';
import { arrayStatus, getHealth, getHealthColor } from './data/InventoryData';
import { CategoryScale, Chart as ChartJS, Filler, Legend, LinearScale, LineElement, PointElement, Title, Tooltip, } from 'chart.js';
import { Tune } from "@mui/icons-material"
import moment from 'moment';
import { CircleButtonGreen } from '../../ui/CircleButtonGreen';
import { enqueueSnackbar } from 'notistack';
import { SearchInput } from '../../components/SearchInput';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);



export const Details = ({ inventoryID, disabled, onEditClick, inventoryData }) => {
    const mainFileRef = createRef()
    const navigate = useNavigate();
    const [isOpened, setIsOpened] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [arrayAttribute, setArrayAttribute] = useState([]);
    const [arrayPipe, setArrayPipe] = useState([]);
    const [objInventory, setObjInventory] = useState({})
    const [objPipeProfile, setObjPipeProfile] = useState({})
    const [objLocation, setObjLocation] = useState({})
    const [selectedPipe, setSelectedPipe] = useState(null)
    const [label, setLabel] = useState([])
    const [wallThicknessData, setWallThicknessData] = useState([])
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const columns = [
        {
            field: 'empty',
            headerName: '',
            width: 0,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: 'joint',
            headerName: 'Joint',
            width: 70,
            renderCell: (params) => (
                <Typography fontWeight={700}>
                    {params.value ? params.value : '-'}
                </Typography>
            )
        },
        {
            field: 'length',
            headerName: 'Length(ft)',
            width: 100,
            renderCell: (params) => (
                <Typography>
                    {params.value ? params.value : "-"}
                </Typography>
            )
        },
        {
            field: 'serialId',
            headerName: 'Serial Number',
            width: 130,
        },
        {
            field: 'arrayIdentifier',
            headerName: 'Unique Identifier',
            width: 200,
            renderCell: (params) => (
                <Typography>
                    {params.row.arrayIdentifier.length > 0 ? params.row.arrayIdentifier[0] : "-"}
                </Typography>
            ),
        },
        {
            field: 'icon',
            headerName: '',
            width: 100,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Stack alignItems={'flex-start'} width={'100%'} pr={2}>
                    <img
                        src={QrCode}
                        alt="QR Code"
                        width="24"
                        height="24"
                    />
                </Stack>
            ),
        },
        {
            field: 'arrayWallThickness',
            headerName: 'Wall Thick.',
            width: 100,
            renderCell: (params) => (
                <Typography>
                    {params.row.arrayWallThickness.length > 0 ? params.row.arrayWallThickness[0].wallThickness : "-"}
                </Typography>
            )
        },
        {
            field: 'percentage',
            headerName: 'Health',
            width: 120,
            renderCell: ({ value }) => (
                <Stack direction={'row'} spacing={1} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                    <Typography>{getHealth(value)}</Typography>
                    <Box
                        sx={{
                            width: 10,
                            height: 10,
                            borderRadius: '50%',
                            backgroundColor: getHealthColor(value),
                        }}
                    />
                </Stack>
            ),
        },
        {
            field: 'updatedAt',
            headerName: 'Last Updated',
            flex: 1,
            valueFormatter: (params) => moment(params.value).format("MM-DD-YYYY hh:mm a"),
        },
        {
            field: 'arrowIcon',
            headerName: '',
            width: 30,
            renderCell: () => (
                <Stack alignItems={'flex-end'} width={'100%'} pr={2}>
                    <ArrowForwardIos
                        sx={{
                            width: 18,
                            height: 18,
                        }}
                    />
                </Stack>
            ),
            sortable: false,
            disableColumnMenu: true,
        },
    ];
    // if (filteredArrayData.length > 0 && filteredArrayData[0].tubeWallMin) {
    //     var objTubeWallMin = {
    //         field: 'tubeWallMin',
    //         headerName: 'Tube Wall Min',
    //         width: 125,
    //         renderCell: (params) => (
    //             <Typography>
    //                 {params.value ? params.value : "-"}
    //             </Typography>
    //         )
    //     }
    //     columns.splice(6, 0, objTubeWallMin);
    // }
    if (filteredArrayData.length > 0 && filteredArrayData[0].percentageNom) {
        var objPercentageNom = {
            field: 'percentageNom',
            headerName: '%Nom',
            width: 80,
            renderCell: (params) => (
                <Typography>
                    {params.value ? params.value + "%" : "-"}
                </Typography>
            )
        }
        columns.splice(7, 0, objPercentageNom);
    }

    const labels = label;
    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Dataset',
                data: wallThicknessData,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                tension: 0.3,
                fill: true,
            },
        ],
    };

    const config = {
        type: 'line',
        data: data,
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false,
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    useEffect(() => {
        // if (inventoryID != "") {
        //     getInventoryData()
        // }

        if (inventoryData._id != null) {
            setData(inventoryData)
        }

    }, [inventoryData]);

    function getInventoryData() {
        setIsLoading(true)
        api.get('/apis/Inventories/getDetailById/' + inventoryID).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setObjInventory(resData)
                setArrayAttribute(resData.pipeProfile.attributes)
                setObjPipeProfile(resData.pipeProfile)
                setObjLocation(resData.location)
                var pipeData = resData.pipes
                var indexCounter = 0;
                pipeData = pipeData.map(item => {
                    indexCounter++;
                    return { ...item, joint: indexCounter };
                });
                var filterArray = applyFilters(pipeData, "")
                setArrayPipe(pipeData)
                setFilteredArrayData(filterArray)
                setIsLoading(false)
            }

        }).catch(function (error) {
            console.log(error);
        });
    }

    function getPipeData(pipeId, joint) {
        setIsLoading(true)
        api.get('/apis/Pipes/getById/' + pipeId).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                resData.joint = joint

                setSelectedPipe(resData)
                setWallThicknessData(resData.arrayWallThickness.map(data => data.wallThickness))
                const formatDate = resData.arrayWallThickness.map(data => data.date)
                const labels = formatDate.map(date => moment(date).format("MM-DD-YYYY"));
                setLabel(labels)
                setIsOpened(true)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    function setData(resData) {
        setObjInventory(resData)
        setArrayAttribute(resData.pipeProfile.attributes)
        setObjPipeProfile(resData.pipeProfile)
        setObjLocation(resData.location)
        var pipeData = resData.pipes
        var indexCounter = 0;
        pipeData = pipeData.map(item => {
            indexCounter++;
            return { ...item, joint: indexCounter };
        });
        var filterArray = applyFilters(pipeData, "")
        setArrayPipe(pipeData)
        setFilteredArrayData(filterArray)
    }


    async function updatePipe() {
        setIsLoading(true)
        api.put('/apis/Pipes/updateExcel/' + inventoryID).then((res) => {
            setIsLoading(false)
            if (res.data.success) {
                enqueueSnackbar("Pipe Updated", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
            } else {
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });

            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }
    async function uploadFiles(file) {
        setIsLoading(true)
        const formData = new FormData();
        formData.append("file", file);
        formData.append("inventoryID", inventoryID);
        api.post('/apis/Pipes/addExcel', formData).then((res) => {
            setIsLoading(false)
            if (res.data.success) {
                enqueueSnackbar("Pipe Added", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                getInventoryData()
            } else {
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });

            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    function handleQueryChange(event) {
        var filterArray = applyFilters(arrayPipe, event.target.value)
        setFilteredArrayData(filterArray)
    }

    function applyFilters(arrayPipe, query) {
        var finalArray = []
        var filteredArrayPipeSearch = arrayPipe.filter((dataObj) => {
            let matches = true;

            const properties = ['joint', 'percentageNom', 'tubeWallMin', 'length', 'serialId'];
            let containsQuery = false;

            properties.forEach((property) => {
                var str = getProperty(dataObj, property) + "";
                if (str.toLowerCase().includes(query.toLowerCase())) {
                    containsQuery = true;
                }
            });

            if (dataObj.arrayIdentifier && dataObj.arrayIdentifier.length > 0) {
                var str = dataObj.arrayIdentifier[0] + "";
                if (str.toLowerCase().includes(query.toLowerCase())) {
                    containsQuery = true;
                }
            }
            if (!containsQuery) {
                matches = false;
            }
            return matches;
        });
        finalArray = filteredArrayPipeSearch.length > 0 ? [...filteredArrayPipeSearch] : [];
        if (query === "") {
            return arrayPipe;
        }
        finalArray = finalArray.map((item, index) => ({ ...item, joint: index + 1 }))
        return finalArray;
    }

    function getProperty(obj, prop) {
        const props = prop.split('.');
        return props.reduce((o, p) => o && o[p], obj);
    }

    return (
        <>
            <Stack sx={{
                pointerEvents: disabled && 'none',
            }}>
                <Stack direction={'row'} spacing={3} p={2} alignItems={'flex-end'}>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Pipe Profile Description</Typography>
                                    <Typography fontWeight={700}>{objPipeProfile.description}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Pipe Category</Typography>
                                    <Typography fontWeight={700}>{objInventory.pipeCategory}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Pipe Type</Typography>
                                    <Typography fontWeight={700}>{objInventory.pipeType}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Purchase Order</Typography>
                                    <Typography fontWeight={700}>{objInventory.purchaseOrder}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Site Location</Typography>
                                    <Typography fontWeight={700}>{objLocation.siteName}</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box bgcolor={'grey.light'} width={'1px'} height={160} flexShrink={0} />
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Pipe Name</Typography>
                                    <Typography fontWeight={700}>{objPipeProfile.name}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Grade</Typography>
                                    <Typography fontWeight={700}>{arrayAttribute.find(obj => obj.id == -5)?.value || "-"}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Connection</Typography>
                                    <Typography fontWeight={700}>{arrayAttribute.find(obj => obj.id == -4)?.value || "-"}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>O.D.</Typography>
                                    <Typography fontWeight={700}>{arrayAttribute.find(obj => obj.id == -3)?.value || "-"}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Weight (lb/ft)</Typography>
                                    <Typography fontWeight={700}>{arrayAttribute.find(obj => obj.id == -2)?.value || "-"}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Wall Thick.</Typography>
                                    <Typography fontWeight={700}>{arrayAttribute.find(obj => obj.id == -1)?.value || "-"}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Range</Typography>
                                    <Typography fontWeight={700}>{arrayAttribute.find(obj => obj.id == -8)?.value || "-"}</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box bgcolor={'grey.light'} width={'1px'} height={160} flexShrink={0} />
                    <Box>
                        <Grid container spacing={2} >
                            <Grid item xs={12} >
                                <Stack spacing={1} direction='row' alignItems='start' margin={0} padding={0} maxWidth={400}>
                                    <SearchInput handleQueryChange={handleQueryChange} />
                                    <Button
                                        sx={{
                                            minWidth: 100,
                                            textTransform: 'none',
                                            borderRadius: '5px',
                                            height: '45px',
                                            border: '0px solid black',
                                            backgroundColor: '#F5F7FB',
                                            fontSize: "13px",
                                            fontWeight: "normal",
                                            color: '#262B40',
                                            '&:hover': {
                                                bgcolor: '#F5F7FB',
                                            },
                                        }}
                                        startIcon={<Tune color='#262B40' />}
                                        onClick={(e) => {
                                            onEditClick()
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Pipe O.D.</Typography>
                                    <Typography fontWeight={700}>{arrayAttribute.find(obj => obj.id == 23)?.value || "-"}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Pin I.D.</Typography>
                                    <Typography fontWeight={700}>{arrayAttribute.find(obj => obj.id == 7)?.value || "-"}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Pin Tong</Typography>
                                    <Typography fontWeight={700}>{arrayAttribute.find(obj => obj.id == 8)?.value || "-"}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Box Tong</Typography>
                                    <Typography fontWeight={700}>{arrayAttribute.find(obj => obj.id == 14)?.value || "-"}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Hardband</Typography>
                                    <Typography fontWeight={700}>{arrayAttribute.find(obj => obj.id == 27)?.value || "-"}</Typography>

                                </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>IPC</Typography>
                                    <Typography fontWeight={700}>{arrayAttribute.find(obj => obj.id == 28)?.value || "-"}</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </Stack>.
                <Box flex={1} mt={2}>

                    <DefaultDataGrid columns={columns} rows={filteredArrayData} onRowClick={(selectedRow) => {
                        // setIsOpened(true)
                        getPipeData(selectedRow.row._id, selectedRow.row.joint)
                        // setSelectedPipe(selectedRow)
                        // setWallThicknessData(selectedRow.row.arrayWallThickness.map(data => data.wallThickness))
                        // const formatDate = selectedRow.row.arrayWallThickness.map(data => data.date)
                        // const labels = formatDate.map(date => moment(date).format("MM-DD-YYYY"));
                        // setLabel(labels)
                    }} />
                </Box>
                {/* <Stack
                    alignItems={"end"}
                    mb={2}
                    mt={2}
                    mr={1}>
                    {localStorage.getItem("isGuestLogin") != "1" && <CircleButtonGreen
                        onClick={(e) => {
                            if (mainFileRef.current) {
                                mainFileRef.current.value = null;
                                mainFileRef.current.click();
                            }
                            console.log("onClick");
                        }}
                    />}
                    <input
                        type="file"
                        name="files"
                        hidden
                        ref={mainFileRef}
                        accept=".xlsx"
                        onChange={(e) => {
                            const selectedFiles = e.target.files;
                            const isValidFileType = Array.from(selectedFiles).every((file) => {
                                return file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                            });
                            if (!isValidFileType) {
                                enqueueSnackbar('Upload the file in the correct format (xlsx)', {
                                    anchorOrigin: {
                                        horizontal: 'right',
                                        vertical: 'top',
                                    },
                                    variant: 'error',
                                });
                                return;
                            }
                            if (selectedFiles.length > 0) {
                                uploadFiles(selectedFiles[0]);
                            }
                        }}
                    />
                </Stack > */}
                <Popup right={0} isOpened={isOpened} bgcolor={'blue.light'} width={930} p={2}>
                    <CircleButton
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            zIndex: 2,
                        }}
                        icon={<Close />}
                        onClick={() => {
                            setSelectedPipe(null)
                            setIsOpened(false)
                        }}
                    />
                    {selectedPipe && (
                        <Box display={'flex'} flexDirection={'row'} gap={4}>
                            <Stack flexGrow={1} spacing={2.5} position={'relative'}>
                                <Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <Stack spacing={2}>
                                                <Typography>Joint</Typography>
                                                <Typography fontSize={25} fontWeight={700}>{selectedPipe.joint || "-"}</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Stack spacing={2}>
                                                <Typography>Length (ft)</Typography>
                                                <Typography fontSize={25} fontWeight={700}>{selectedPipe.length || "-"}</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Stack spacing={2}>
                                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                                    <Typography>Health Rating</Typography>
                                                    <Box
                                                        sx={{
                                                            width: 10,
                                                            height: 10,
                                                            borderRadius: '50%',
                                                            backgroundColor: getHealthColor(selectedPipe.percentage),
                                                        }}
                                                    />
                                                </Stack>
                                                <Typography fontSize={25} fontWeight={700}>
                                                    {getHealth(selectedPipe.percentage)}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box
                                    width={'100%'}
                                    sx={{
                                        height: '1px',
                                        backgroundImage: 'linear-gradient(to right, #d5d5d5, #d5d5d5 60%, transparent 50%)',
                                        backgroundSize: '13px 1px',
                                    }}
                                />
                                <Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <Stack spacing={2}>
                                                <Typography>Serial</Typography>
                                                <Typography fontWeight={700}>{selectedPipe.serialId || "-"}</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Stack spacing={2}>
                                                <Typography>Heat</Typography>
                                                <Typography fontWeight={700}>{selectedPipe.heat || "-"}</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Stack spacing={2}>
                                                <Typography>Lot</Typography>
                                                <Typography fontWeight={700}>{selectedPipe.lot || "-"}</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Stack spacing={2}>
                                                <Typography>Status</Typography>
                                                <Typography fontWeight={700}>{arrayStatus.find(obj => obj.id == selectedPipe.status)?.value || "-"}</Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box
                                    width={'100%'}
                                    sx={{
                                        height: '1px',
                                        backgroundImage: 'linear-gradient(to right, #d5d5d5, #d5d5d5 60%, transparent 50%)',
                                        backgroundSize: '13px 1px',
                                    }}
                                />
                                <Box>
                                    <Grid container spacing={2}>
                                        {selectedPipe.pipeProfile.attributes.map((data, index) => {
                                            return (< Grid item xs={4} key={index}> 
                                                <Stack spacing={2}>
                                                    <Typography>{data.title}</Typography>
                                                    <Typography fontWeight={700} color={data.id === -1 ? 'green.main' : '#262B40'}>{data.value !== null && data.value !== "" ? (data.id === 20 || data.id == -7  ? data.value + "%" : data.value) : "-"}</Typography>
                                                </Stack>
                                            </Grid>)
                                        })}
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={4}>
                                                    <Typography>Unique Identifier</Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography fontWeight={700}>{selectedPipe.arrayIdentifier.length > 0 ? selectedPipe.arrayIdentifier[0] : "-"}</Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <IconButton
                                                        sx={{
                                                            width: "40px",
                                                            height: "40px",
                                                            color: 'common.white',
                                                            backgroundColor: 'transparent',
                                                            '&:hover': {
                                                                backgroundColor: 'grey.blue',
                                                            },
                                                        }}
                                                    >
                                                        <img
                                                            src={QrCode}
                                                            alt="QR Code"
                                                        />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Stack>
                            <Stack spacing={1.5} width={400} flexShrink={0}>
                                <Stack spacing={1}>
                                    <Typography>Remaining Body Wall</Typography>
                                    <Box height={190}>
                                        <Line data={data} options={config} />
                                    </Box>
                                </Stack>
                                <Stack spacing={1}>
                                    <Typography fontWeight={700}>Inspection data</Typography>
                                    <Box px={3} py={2} bgcolor={'common.white'} borderRadius={'10px'}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Typography>Wall Thickness Value</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography>Date/Time</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {selectedPipe.arrayWallThickness.map((item, index) => (
                                                    <Typography key={index} fontWeight={700} marginTop={1}>{item.wallThickness}</Typography>
                                                ))}
                                            </Grid>
                                            <Grid item xs={6}>
                                                {selectedPipe.arrayWallThickness.map((item, index) => (
                                                    <Typography key={index} marginTop={1}>{moment(item.date).format("MM-DD-YYYY hh:mm a")}</Typography>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Box>
                    )}
                </Popup>
                <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Stack>
        </>
    );
};
