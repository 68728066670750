export const getStatusColor = (code) => {
    switch (code) {
        case 1:
            return 'red.light';
        case 2:
            return 'orange.main';
        case 3:
            return 'primary.main';
        case 4:
            return 'green.main';
        case 5:
            return 'blue.main';
        default:
            return 'green.main';
    }
};
