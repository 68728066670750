export const arrayStatus = [
    { id: "1", value: "Ready" },
    { id: "2", value: "Inspection" },
    { id: "3", value: "In Transit" },
    { id: "4", value: "In Service" },
    { id: "5", value: "In Storage" },
]

export const arrayCondition = [
    { id: "1", value: "New" },
    { id: "2", value: "Used" },
]

export const getInventoryStatusColor = (code) => {
    switch (code) {
        case '1':
            return '#FF3841';
        case '2':
            return '#FEBC2E';
        case '3':
            return '#262B40';
        case '4':
            return '#24D5AA';
        case '5':
            return '#0084FF';
        default:
            return '#0084FF';
    }
};

export const arrayInventoryStatus = [
    { id: "1", value: "Ready" },
    { id: "2", value: "Inspection" },
    { id: "3", value: "In Transit" },
    { id: "4", value: "In Service" },
    { id: "5", value: "In Storage" },
]

export const getHealth = (percentage) => {
    if (percentage > 99.9) {
        return 'AAA'
    } else if (percentage > 94.9 && percentage < 99.9) {
        return 'AA'
    } else if (percentage > 89.9 && percentage < 95) {
        return 'A'
    } else if (percentage > 79.9 && percentage < 90) {
        return 'EOL'
    } else {
        return 'Downgrade'
    }
}

export const getHealthColor = (percentage) => {
    if (percentage > 99.9) {
        return '#24D5AA'
    } else if (percentage > 94.9 && percentage < 99.9) {
        return '#0084FF'
    } else if (percentage > 89.9 && percentage < 95) {
        return '#FEBC2E'
    } else if (percentage > 79.9 && percentage < 90) {
        return '#FF3841'
    } else {
        return '#262B40'
    }
}
