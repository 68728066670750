import { Cluster } from '../../assets/icons/Cluster';
import { Metrics } from '../../components/Metrics';
import { SearchInput } from '../../components/SearchInput';
import { PipetallyTabs } from './data/pipeTallyTabs';
import { CircleButton } from '../../ui/CircleButton';
import { DefaultDataGrid } from '../../ui/DefaultDataGrid';
import { Filters } from '../../ui/Filters';
import { Tabs } from '../../ui/Tabs';
import { Box, Stack, Backdrop, CircularProgress, MenuItem, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import api from '../../lib/axios';
import { ColorStatus } from '../../ui/PipeTallyColorStatus/PipeTallyColorStatus.jsx';
import { arrayStatus, arrayType } from './data/PipeTallyData';
import { arrayTransferAction, arrayTransferStatus } from '../PipeTallyDetail/data/InventoryData';
import { Check, Close } from '@mui/icons-material';
import moment from 'moment';
import { ConfirmAlert } from '../../customControls/confirmAlert';
import { enqueueSnackbar } from 'notistack';
import { PlayCircle } from '@mui/icons-material';
export const PipeTally = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessScreen, setIsSuccessScreen] = useState(false);
    const [deleteType, setDeleteType] = useState("");
    const [deleteMessage, setDeleteMessage] = useState("");
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);
    const [selectedTransferId, setSelectedTransferId] = useState("");
    const [arrayData, setArrayData] = useState([]);
    const [arrayTransfer, setArrayTransfer] = useState([]);
    const [filteredArrayTransfer, setFilteredArrayTransfer] = useState([]);
    const [activeTab, setActiveTab] = useState(PipetallyTabs[0].value);
    const [query, setQuery] = useState("");
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const [arrayOD, setArrayOD] = useState([]);
    const [arrayWallThickness, setArrayWallThickness] = useState([]);
    const [arrayGrade, setArrayGrade] = useState([]);
    const [arrayConnection, setArrayConnection] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(-1)
    const [selectedOD, setSelectedOD] = useState(-1)
    const [selectedWallThick, setSelectedWallThick] = useState(-1)
    const [selectedGrade, setSelectedGrade] = useState(-1)
    const [selectedConnection, setSelectedConnection] = useState(-1)
    const [metrics1, setMetrics1] = useState([
        {
            title: 'Completed',
            value: 0,
            color: 'green.main',
        },
        {
            title: 'On Pause',
            value: 0,
            color: 'orange.main',
        },
        {
            title: 'In Progress',
            value: 0,
            color: 'primary.main',
        },
    ]);
    const [metrics2, setMetrics2] = useState([
        {
            title: 'Utilization',
            value: '0%',
        },
        {
            title: 'Last 30 Days',
            value: '+50%',
            isGreen: true,
        },
    ]);
    const columns = [
        {
            field: 'tallyNo',
            headerName: 'Tally ID',
            width: 100,
        },
        {
            field: 'title',
            headerName: 'Tally Title',
            flex: 1,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 120,
            renderCell: ({ value }) => (
                <Stack width={'100%'} direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={1} pr={2}>
                    <Typography fontSize={13}>{arrayStatus.find(status => status.id == value)?.value}</Typography>
                    <ColorStatus code={value} />
                </Stack>
            ),
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 100,
            renderCell: ({ value }) => (
                <Typography fontSize={13}>{arrayType.find(type => type.id == value).value}</Typography>
            ),
        },
        {
            field: 'footage',
            headerName: 'Footage',
            width: 100,
        },
        {
            field: 'quantity',
            headerName: 'Joints',
            width: 100,
        },
        {
            field: 'purchaseOrder',
            headerName: 'Order',
            width: 110,
        },
        {
            field: 'userName',
            headerName: 'End User',
            width: 130,
        },
        {
            field: 'pipeProfileName',
            headerName: 'Pipe Name',
            flex: 1,
        },
        {
            field: 'location', headerName: 'Location', flex: 1,
            valueGetter: (params) => params.row.location.siteName,
            renderCell: (params) => (
                <Typography
                    color="#262B40"
                    variant="primary"
                    fontFamily={"Roboto"}
                    fontSize={'13px'}
                    fontWeight='normal'
                >
                    {params.value}
                </Typography>
            ),
        },
    ];

    const columnsTransfers = [
        {
            field: 'tallyNo',
            headerName: 'Tally ID',
            width: 100,
            renderCell: ({ value }) => <Typography fontWeight={700}>{value}</Typography>,
        },
        {
            field: 'originalSiteName',
            headerName: 'Original Site',
            width: 150,
        },
        {
            field: 'newSiteName',
            headerName: 'New Site',
            width: 150,
        },
        {
            field: 'newEndUser',
            headerName: 'New End User',
            width: 150,
            renderCell: ({ value }) => <Typography >{value || "-"}</Typography>,
        },
        {
            field: 'fullName',
            headerName: 'Modified By',
            width: 150,
        },
        {
            field: 'transferDate',
            headerName: 'Transfer Date',
            width: 160,
            renderCell: ({ value }) => <Typography >{moment(value).format("MM-DD-YYYY hh:mma")}</Typography>,
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 150,
            renderCell: ({ value }) => <Typography >{arrayTransferAction.find(action => action.id == value)?.value}</Typography>,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 130,
            renderCell: ({ value }) => (
                <Stack direction={'row'} spacing={2} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                    <Typography sx={{ color: value == "3" && 'red.light', }}>
                        {arrayTransferStatus.find(status => status.id == value)?.value}
                    </Typography>
                </Stack>
            ),
        },
        {
            field: 'buttons',
            headerName: '',
            minWidth: 200,
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) =>
                params.row.status == "1" && (
                    <Stack direction={'row'} spacing={2}>
                        <CircleButton
                            sx={{
                                svg: {
                                    width: 20,
                                },
                            }}
                            onClick={() => {
                                setSelectedTransferId(params.row._id)
                                setDeleteType("1")
                                setDeleteMessage("Are you sure you want to remove tally from transfer queue?")
                                setDeleteConfirmationShouldOpen(true)
                            }}
                            icon={<Close />}
                            size={20}
                        />
                        <CircleButton
                            sx={{
                                bgcolor: 'green.main',
                                '&:hover': {
                                    bgcolor: 'green.dark',
                                },
                                svg: {
                                    width: 20,
                                },
                            }}
                            onClick={() => {
                                setSelectedTransferId(params.row._id)
                                setDeleteType("2")
                                setDeleteMessage("Are you sure you want to confirm transfer?")
                                setDeleteConfirmationShouldOpen(true)
                            }}
                            icon={<Check />}
                            size={20}
                        />
                    </Stack>
                ),
        },
    ]

    useEffect(() => {
        getFilterData()
    }, []);

    function getFilterData() {
        setIsLoading(true)
        api.get('/apis/Inventories/filterDetail').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayOD(resData.arrayOD)
                setArrayWallThickness(resData.arrayWallThick)
                setArrayGrade(resData.arrayGrade)
                setArrayConnection(resData.arrayConnection)
                //setIsLoading(false)
            }
            getSummary()

        }).catch(function (error) {
            console.log(error);
        });
    }

    function getData(selectedOD, selectedWallThick, selectedGrade, selectedConnection, selectedStatus) {
        setIsLoading(true)
        var data = {
            od: selectedOD != -1 ? selectedOD : "",
            wallThickness: selectedWallThick != -1 ? selectedWallThick : "",
            grade: selectedGrade != -1 ? selectedGrade : "",
            connection: selectedConnection != -1 ? selectedConnection : "",
            status: selectedStatus != -1 ? selectedStatus : ""
        }
        api.post('/apis/Tallies/getAll', data).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var filterArray = applyFilters(resData, query, selectedStatus)
                setFilteredArrayData(filterArray)
                setArrayData(resData)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    function getTransferData() {
        setIsLoading(true)
        api.get('/apis/TallyTransfers/getAll').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var filterArrayTransfer = applyFilters(resData, query, selectedStatus)
                setFilteredArrayTransfer(filterArrayTransfer)
                setArrayTransfer(resData)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    function getSummary() {
        // setIsLoading(true)
        api.get('/apis/Tallies/getSummary').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var metrics1 = [
                    {
                        title: 'Compeleted',
                        value: resData.completed,
                        color: 'green.main',
                    },
                    {
                        title: 'On Pause',
                        value: resData.onPause,
                        color: 'orange.main',
                    },
                    {
                        title: 'In Progress',
                        value: resData.inProgress,
                        color: 'blue.main',
                    },
                ];
                var metrics2 = [
                    {
                        title: 'Utilization',
                        value: resData.utilization != null ? resData.utilization + "%" : "0%",
                    },
                    {
                        title: 'Last 30 Days',
                        value: '+50%',
                        isGreen: true,
                    },
                ]
                setMetrics1(metrics1)
                setMetrics2(metrics2)
                console.log(resData)
            }
            setIsLoading(false)
            getData(selectedOD, selectedWallThick, selectedGrade, selectedConnection, selectedStatus)
        }).catch(function (error) {
            console.log(error);
        });
    }
    function applyFilters(arrayDataAll, query, selectedStatus) {
        var finalArray = []
        console.log("data", query)
        console.log("selectedStatus", selectedStatus)
        var filteredArraySearch = arrayDataAll.filter((dataObj) => {
            let matches = true;
            var properties = []
            if (isFirstTab) {
                properties = ['tallyNo', 'title', 'footage', 'quantity', 'purchaseOrder', 'userName', 'location.siteName', 'pipeProfileName'];
            } else {
                properties = ['tallyNo', 'originalSiteName', 'newSiteName', 'fullName'];
            }
            let containsQuery = false;

            properties.forEach((property) => {
                var str = getProperty(dataObj, property) + "";
                if (str.toLowerCase().includes(query.toLowerCase())) {
                    containsQuery = true;
                }
            });
            if (!containsQuery) {
                matches = false;
            }
            return matches;
        });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : [];
        if (selectedStatus !== null && selectedStatus !== -1) {
            var filteredArrayStats = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;
                    if (dataObj.status == selectedStatus) {
                        containsQuery = true;
                    }
                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                })
            finalArray = [...filteredArrayStats]
        }
        if (query === "" && selectedStatus === -1) {
            return arrayDataAll;
        }
        return finalArray;
    }

    function handleQueryChange(event) {
        setQuery(event.target.value)
        if (isFirstTab) {
            var filterArray = applyFilters(arrayData, event.target.value, selectedStatus)
            setFilteredArrayData(filterArray)
        } else {
            var filterArrayTransfer = applyFilters(arrayTransfer, event.target.value, selectedStatus)
            setFilteredArrayTransfer(filterArrayTransfer)
        }
    }

    function getProperty(obj, prop) {
        const props = prop.split('.');
        return props.reduce((o, p) => o && o[p], obj);
    }
    const handleChangeTab = (tab) => {
        setActiveTab(tab);
        setSelectedStatus(-1)
        if (tab === PipetallyTabs[0].value) {
            getData(selectedOD, selectedWallThick, selectedGrade, selectedConnection, -1)
        } else if (tab === PipetallyTabs[1].value) {
            getTransferData()
        }
    };

    function completedStatus() {
        setIsLoading(true)
        api.put('/apis/TallyTransfers/complete/' + selectedTransferId).then((res) => {
            if (res.data.success) {
                // enqueueSnackbar("Tallye transfered successfully", {
                //     anchorOrigin: {
                //         horizontal: 'right',
                //         vertical: 'top'
                //     },
                //     variant: 'success'
                // });
                getTransferData()
                setIsSuccessScreen(true)

            } else {
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    function undoStatus() {
        setIsLoading(true)
        api.put('/apis/TallyTransfers/undo/' + selectedTransferId).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("Tallye removed from transfer queue", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                getTransferData()
            } else {

                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }
    const updateConfirmAlert = (value) => {
        if (value) {
            if (deleteType == "1") {
                undoStatus()
            } else if (deleteType == "2") {
                completedStatus()
            }

        }
    }

    function handleFilterChange(selectedOD, selectedWallThick, selectedGrade, selectedConnection, selectedStatus) {
        getData(selectedOD, selectedWallThick, selectedGrade, selectedConnection, selectedStatus)
    }
    const isFirstTab = activeTab === PipetallyTabs[0].value
    const isSecondTab = activeTab === PipetallyTabs[1].value
    return (
        <Stack py={2} px={2.5} width={'100%'}>
            <Stack direction={'row'} gap={4} rowGap={2} flexWrap={'wrap'}>
                <Metrics icon={<Cluster />} data={metrics1} />
                <Metrics icon={<Cluster />} data={metrics2} />
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mt={2} mb={2}>
                <Stack direction={'row'} spacing={2} >
                    <SearchInput handleQueryChange={handleQueryChange} />
                    {isFirstTab && <Filters
                        handleFilerChange={(event) => {
                            var od = event.target.value
                            setSelectedOD(od)
                            handleFilterChange(od, selectedWallThick, selectedGrade, selectedConnection, selectedStatus)
                        }}
                        value={selectedOD}
                        name="selectedOD"
                        placeholder="O.D."
                        width="10vw"
                    >
                        {arrayOD.map((item, index) => (
                            <MenuItem key={index} value={item}> {item}</MenuItem>
                        ))}
                    </Filters>}

                    {isFirstTab && <Filters
                        handleFilerChange={(event) => {
                            var wallThick = event.target.value
                            setSelectedWallThick(wallThick)
                            handleFilterChange(selectedOD, wallThick, selectedGrade, selectedConnection, selectedStatus)
                        }}
                        value={selectedWallThick}
                        name="selectedWallThick"
                        placeholder="Wall. Thick."
                        width="10vw"
                    >
                        {arrayWallThickness.map((item, index) => (
                            <MenuItem key={index} value={item}> {item}</MenuItem>
                        ))}
                    </Filters>}

                    {isFirstTab && <Filters
                        handleFilerChange={(event) => {
                            var grade = event.target.value
                            setSelectedGrade(grade)
                            handleFilterChange(selectedOD, selectedWallThick, grade, selectedConnection, selectedStatus)
                        }}
                        value={selectedGrade}
                        name="selectedGrade"
                        placeholder="Grade"
                        width="10vw"
                    >
                        {arrayGrade.map((item, index) => (
                            <MenuItem key={index} value={item}> {item}</MenuItem>
                        ))}
                    </Filters>}

                    {isFirstTab && <Filters
                        handleFilerChange={(event) => {
                            var connection = event.target.value
                            setSelectedConnection(connection)
                            handleFilterChange(selectedOD, selectedWallThick, selectedGrade, connection, selectedStatus)
                        }}
                        value={selectedConnection}
                        name="selectedConnection"
                        placeholder="Connection"
                        width="10vw"
                    >
                        {arrayConnection.map((item, index) => (
                            <MenuItem key={index} value={item}> {item}</MenuItem>
                        ))}
                    </Filters>}

                    <Filters
                        handleFilerChange={(event) => {
                            setSelectedStatus(event.target.value)
                            if (isFirstTab) {
                                var status = event.target.value
                                setSelectedStatus(status)
                                handleFilterChange(selectedOD, selectedWallThick, selectedGrade, selectedConnection, status)
                            } else {
                                var filterArrayTransfer = applyFilters(arrayTransfer, query, event.target.value)
                                setFilteredArrayTransfer(filterArrayTransfer)
                            }

                        }}
                        value={selectedStatus}
                        name="selectedStatus"
                        placeholder="Status"
                        width="10vw"
                    >
                        {isFirstTab ?
                            arrayStatus.map((item, index) => (
                                <MenuItem key={index} value={item.id}> {item.value}</MenuItem>
                            ))
                            :
                            arrayTransferStatus.map((item, index) => (
                                <MenuItem key={index} value={item.id}> {item.value}</MenuItem>
                            ))
                        }
                    </Filters>
                </Stack>
                {isFirstTab && <CircleButton
                    onClick={() => {
                        navigate(`/pipe-tally/PipeTallyDetail`, { state: { id: "" } })
                    }}
                />}
            </Stack>
            <Tabs data={PipetallyTabs} activeTab={activeTab} setActiveTab={handleChangeTab} />
            <Box flex={1} border={'1px solid'} borderColor={'grey.light'} borderRadius={'5px'} overflow={'hidden'}>
                {isFirstTab && <DefaultDataGrid columns={columns} rows={filteredArrayData} onRowClick={({ id }) => navigate(`/pipe-tally/PipeTallyDetail`, { state: { id: id } })} />}
                {!isSuccessScreen && isSecondTab && (
                    <DefaultDataGrid
                        defaultSize={15}
                        columns={columnsTransfers}
                        rows={filteredArrayTransfer}
                        pageItems={6}
                        pageSizeOptions={[6, 10, 25]}
                    />
                )}
                {isSuccessScreen && isSecondTab && (
                    <Stack flex={1} alignItems={'center'} justifyContent={'center'} textAlign={'center'} p={4} spacing={3}>
                        <Typography fontSize={25} fontWeight={700} color={'green.main'}>
                            Pipe Tally Transferred Successfully
                        </Typography>
                        <Stack>
                            <Typography fontSize={20}>
                                Your pipe tally has been successfully processed and transferred.
                            </Typography>
                            <Typography fontSize={20}>A confirmation will be sent to all stakeholders.</Typography>
                        </Stack>
                        <Button
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                py: 1,
                                px: 2,
                                borderRadius: '5px',
                                '&:hover': {
                                    bgcolor: 'primary.dark',
                                },
                            }}
                            onClick={() => setIsSuccessScreen(false)}
                        >
                            <Typography
                                sx={{
                                    textTransform: 'capitalize',
                                }}
                                fontWeight={700}
                                fontSize={15}
                            >
                                View Transfers
                            </Typography>
                            <PlayCircle
                                sx={{
                                    width: 24,
                                    height: 24,
                                    color: 'green.main',
                                }}
                            />
                        </Button>
                    </Stack>
                )}
            </Box>
            {
                deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={deleteMessage}
                    updateConfirmAlert={(e, value) => {
                        console.log(e, value)
                        setDeleteConfirmationShouldOpen(false)
                        updateConfirmAlert(value)
                    }} />)
            }
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack>

    );
};