import React, { useEffect, useState } from "react"
import { Backdrop, Box, Button, CircularProgress, Grid, Stack, Typography, } from '@mui/material';
import { Tune } from "@mui/icons-material"
import api from '../../lib/axios'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DefaultToggle } from "../../ui/DefaultToggle/DefaultToggle";
import { linePipeId, tubingId } from "./Data/tabs";

export const Detail = ({ pipeProfileID, disabled, onEditClick, objPipeProfile, updateDetails }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [arrayAttribute, setArrayAttribute] = useState([]);
    let rowIndex = 0
    useEffect(() => {
        if (objPipeProfile._id != null) {
            setData(objPipeProfile)
        }
    }, [objPipeProfile]);

    function getData() {
        setIsLoading(true)
        api.get('/apis/PipeProfiles/getById/' + pipeProfileID).then((res) => {
            if (res.data.success) {
                var data = res.data.data
                setArrayAttribute(data.attributes)
                setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    function apiCalling(arrayAttribute) {
        setIsLoading(true)
        var data = {
            attributes: arrayAttribute
        }
        if (pipeProfileID != "") {
            api.put('/apis/PipeProfiles/updateById/' + pipeProfileID, data).then((res) => {
                if (res.data.success) {
                    var data = res.data.data
                    setArrayAttribute(data.attributes)
                    // updateDetails()
                }
                setIsLoading(false)
            }).catch(function (error) {
                console.log(error);
            });
        }
    }

    function setData(resData) {
        setArrayAttribute(resData.attributes)
    }
    return (
        <>
            <Stack sx={{
                pointerEvents: disabled && 'none',
            }}>
                <Stack spacing={2} flex={1}>
                    <Stack direction={'row'} justifyContent={'space-between'} spacing={3} pt={2} px={2}>
                        <Stack spacing={1}>
                            <Typography color={'#A8A8A7'}>Pipe Profile Name</Typography>
                            <Typography mt={1.5} fontWeight={700}>{objPipeProfile.name ? objPipeProfile.name : "-"}</Typography>
                        </Stack>
                        <Stack mr={2}>
                            <Button
                                sx={{
                                    minWidth: 100,
                                    textTransform: 'none',
                                    borderRadius: '5px',
                                    height: '40px',
                                    border: '0px solid black',
                                    backgroundColor: '#F5F7FB',
                                    fontSize: "13px",
                                    fontWeight: "normal",
                                    color: '#262B40',
                                    '&:hover': {
                                        bgcolor: '#F5F7FB',
                                    },
                                }}
                                startIcon={<Tune color='#262B40' />}
                                onClick={(e) => {
                                    onEditClick()
                                }}
                            >
                                Edit
                            </Button>
                        </Stack>
                    </Stack>
                    <Box px={2}>
                        <Grid container columnSpacing={4} rowSpacing={2}>
                            <Grid item xs={2}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Category</Typography>
                                    <Typography fontWeight={700}>{objPipeProfile.category ? objPipeProfile.category : "-"}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={2}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Type</Typography>
                                    <Typography fontWeight={700}>{objPipeProfile.type ? objPipeProfile.type : "-"}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={2}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>O.D.</Typography>
                                    <Typography fontWeight={700}>{objPipeProfile.od ? objPipeProfile.od : "-"}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={2}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Wall Thickness</Typography>
                                    <Typography fontWeight={700}>{objPipeProfile.wallThickness ? objPipeProfile.wallThickness : "-"}</Typography>
                                </Stack>
                            </Grid>

                            <Grid item xs={4}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Description</Typography>
                                    <Typography fontWeight={700}>{objPipeProfile.description ? objPipeProfile.description : "-"}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={2}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Weight (lb/ft)</Typography>
                                    <Typography fontWeight={700}>{objPipeProfile.weight ? objPipeProfile.weight : "-"}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={2}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Grade</Typography>
                                    <Typography fontWeight={700}>{objPipeProfile.grade ? objPipeProfile.grade : "-"}</Typography>
                                </Stack>
                            </Grid>
                            {objPipeProfile.categoryID && objPipeProfile.categoryID != linePipeId && <Grid item xs={2}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Connection</Typography>
                                    <Typography fontWeight={700}>{objPipeProfile.connectionName ? objPipeProfile.connectionName : "-"}</Typography>
                                </Stack>
                            </Grid>}
                            {objPipeProfile.categoryID && objPipeProfile.categoryID == tubingId && <Grid item xs={2}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Range</Typography>
                                    <Typography fontWeight={700}>{objPipeProfile.range ? objPipeProfile.range : "-"}</Typography>
                                </Stack>
                            </Grid>}
                            {objPipeProfile.categoryID && objPipeProfile.categoryID == linePipeId && <Grid item xs={2}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Coating</Typography>
                                    <Typography fontWeight={700}>{objPipeProfile.coating ? objPipeProfile.coating : "-"}</Typography>
                                </Stack>
                            </Grid>}
                            {objPipeProfile.categoryID && objPipeProfile.categoryID == linePipeId && <Grid item xs={2}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Surface</Typography>
                                    <Typography fontWeight={700}>{objPipeProfile.surface ? objPipeProfile.surface : "-"}</Typography>
                                </Stack>
                            </Grid>}
                            {objPipeProfile.categoryID && objPipeProfile.categoryID == linePipeId && <Grid item xs={2}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Gauge</Typography>
                                    <Typography fontWeight={700}>{objPipeProfile.gauge ? objPipeProfile.gauge : "-"}</Typography>
                                </Stack>
                            </Grid>}
                            {objPipeProfile.categoryID && objPipeProfile.categoryID == linePipeId && <Grid item xs={2}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>End Type</Typography>
                                    <Typography fontWeight={700}>{objPipeProfile.endType ? objPipeProfile.endType : "-"}</Typography>
                                </Stack>
                            </Grid>}
                            {objPipeProfile.categoryID && objPipeProfile.categoryID == linePipeId && <Grid item xs={2}>
                                <Stack spacing={1.5}>
                                    <Typography color={'#A8A8A7'}>Cut Off</Typography>
                                    <Typography fontWeight={700}>{objPipeProfile.cutOff ? objPipeProfile.cutOff : "-"}</Typography>
                                </Stack>
                            </Grid>}
                            {arrayAttribute.map((data, index) => {
                                if (data.isOn) {
                                    return (< Grid item xs={2} key={index}>
                                        <Stack spacing={1.5}>
                                            <Typography color={'#A8A8A7'}>{data.title}</Typography>
                                            <Typography fontWeight={700}>{data.value !== null && data.value !== "" ? (data.id === 20 ? data.value + "%" : data.value) : "-"}</Typography>
                                        </Stack>
                                    </Grid>)
                                }
                            })}
                        </Grid >
                    </Box>


                    <Grid container >
                        <Grid container sx={{ backgroundColor: '#F3F6F9' }} py={2}>
                            <Grid item xs={4}>
                                <Grid container alignItems="center" px={3}>
                                    <Typography sx={{ fontSize: 15, fontWeight: 700, width: '250px' }}>Attributes</Typography>
                                    <Typography sx={{ fontSize: 15, fontWeight: 700 }}>Mobile</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container alignItems="center" px={3}>
                                    <Typography sx={{ fontSize: 15, fontWeight: 700, width: '250px' }}>Attributes</Typography>
                                    <Typography sx={{ fontSize: 15, fontWeight: 700 }}>Mobile</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container alignItems="center" px={3}>
                                    <Typography sx={{ fontSize: 15, fontWeight: 700, width: '250px' }}>Attributes</Typography>
                                    <Typography sx={{ fontSize: 15, fontWeight: 700 }}>Mobile</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {arrayAttribute.map((data, index) => {
                            if ((index) % 3 == 0) {
                                rowIndex = rowIndex + 1
                            }
                            return (
                                <Grid item xs={4} key={index}>
                                    <Grid container alignItems="center" sx={{ backgroundColor: rowIndex % 2 === 0 ? '#F3F6F9' : 'inherit' }} py={1} pr={3}>
                                        <DefaultToggle
                                            defaultChecked={data.isOn}
                                            onChange={(event) => {
                                                const checked = event.target.checked;
                                                const updatedArrayAttribute = arrayAttribute.map((res) => {
                                                    if (res.id === data.id) {
                                                        var obj = { ...res }
                                                        obj.isOn = checked
                                                        obj.isInMobile = checked ? obj.isInMobile : false
                                                        console.log("obj", obj)
                                                        return obj
                                                    }
                                                    return res;
                                                });
                                                apiCalling(updatedArrayAttribute)
                                            }}
                                        />
                                        <Typography width={'190px'}>{data.title}</Typography>
                                        {data.isOn && <DefaultToggle
                                            defaultChecked={data.isInMobile}
                                            activeColor="#00A7FF"
                                            onChange={(event) => {
                                                const checked = event.target.checked;
                                                const updatedArrayAttribute = arrayAttribute.map((res) => {
                                                    if (res.id === data.id) {
                                                        var obj = { ...res }
                                                        obj.isInMobile = checked
                                                        return obj
                                                    }
                                                    return res;
                                                });
                                                apiCalling(updatedArrayAttribute)
                                            }}
                                        />}
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Stack>
            </Stack>
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}