import { Cluster } from '../../assets/icons/Cluster';
import { MapViewIcon } from '../../assets/icons/MapViewIcon';
import { Metrics } from '../../components/Metrics';
import { PageTitle } from '../../components/PageTitle';
import { SearchInput } from '../../components/SearchInput';
import { CircleButton } from '../../ui/CircleButton';
import { Filters } from '../../ui/Filters';
import { Box, Stack, Backdrop, CircularProgress, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import api from '../../lib/axios';
import { Map } from './Map';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { arrayStatus } from '../InventoryDetails/data/InventoryData';
export const MapView = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [arrayData, setArrayData] = useState([]);
    const [query, setQuery] = useState("");
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const [arrayOD, setArrayOD] = useState([]);
    const [arrayWallThickness, setArrayWallThickness] = useState([]);
    const [arrayGrade, setArrayGrade] = useState([]);
    const [arrayConnection, setArrayConnection] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(-1)
    const [selectedOD, setSelectedOD] = useState(-1)
    const [selectedWallThick, setSelectedWallThick] = useState(-1)
    const [selectedGrade, setSelectedGrade] = useState(-1)
    const [selectedConnection, setSelectedConnection] = useState(-1)
    const [metrics1, setMetrics1] = useState([
        {
            title: 'In Service',
            value: 0,
            color: 'green.main',
        },
        {
            title: 'Inspection',
            value: 0,
            color: 'orange.main',
        },
        {
            title: 'Storage',
            value: 0,
            color: 'primary.main',
        },
        {
            title: 'Ready',
            value: 0,
            color: 'red.light',
        },
        {
            title: 'Transit',
            value: 0,
            color: 'blue.main',
        },
    ]);
    const [metrics2, setMetrics2] = useState([
        {
            title: 'Utilization',
            value: '0%',
        },
        {
            title: 'Last 30 Days',
            value: '+50%',
            isGreen: true,
        },
    ]);

    useEffect(() => {
        getFilterData()
    }, []);

    function getFilterData() {
        setIsLoading(true)
        api.get('/apis/Inventories/filterDetail').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayOD(resData.arrayOD)
                setArrayWallThickness(resData.arrayWallThick)
                setArrayGrade(resData.arrayGrade)
                setArrayConnection(resData.arrayConnection)
                // setIsLoading(false)
            }
            getInventoriesSummary()
        }).catch(function (error) {
            console.log(error);
        });
    }

    function getData(selectedOD, selectedWallThick, selectedGrade, selectedConnection, selectedStatus) {
        setIsLoading(true)
        var data = {
            od: selectedOD != -1 ? selectedOD : "",
            wallThickness: selectedWallThick != -1 ? selectedWallThick : "",
            grade: selectedGrade != -1 ? selectedGrade : "",
            connection: selectedConnection != -1 ? selectedConnection : "",
            status: selectedStatus != -1 ? selectedStatus : ""
        }
        api.post('/apis/Inventories/getAllmap', data).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var filterArray = applyFilters(resData, query)
                setFilteredArrayData(filterArray)
                setArrayData(resData)

                console.log(resData)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    function getInventoriesSummary() {
        // setIsLoading(true)
        api.get('/apis/Inventories/getSummary').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var metrics1 = [
                    {
                        title: 'In Service',
                        value: resData.inService,
                        color: 'green.main',
                    },
                    {
                        title: 'Inspection',
                        value: resData.inspection,
                        color: 'orange.main',
                    },
                    {
                        title: 'Storage',
                        value: resData.storage,
                        color: 'blue.main',
                    },
                    {
                        title: 'Ready',
                        value: resData.ready,
                        color: 'red.light',
                    },
                    {
                        title: 'Transit',
                        value: resData.transit,
                        color: 'primary.main',
                    },
                ];
                var metrics2 = [
                    {
                        title: 'Utilization',
                        value: resData.utilization + "%",
                    },
                    {
                        title: 'Last 30 Days',
                        value: '+50%',
                        isGreen: true,
                    },
                ]
                setMetrics1(metrics1)
                setMetrics2(metrics2)
                console.log(resData)
            }
            setIsLoading(false)
            getData(selectedOD, selectedWallThick, selectedGrade, selectedConnection, selectedStatus)
        }).catch(function (error) {
            console.log(error);
        });
    }

    function applyFilters(arrayDataAll, query) {
        var finalArray = []
        var filteredArraySearch = arrayDataAll.filter((dataObj) => {
            let matches = true;

            const properties = ['purchaseOrder', 'pipeType', 'footage', 'quantity', 'address'];
            let containsQuery = false;

            properties.forEach((property) => {
                var str = getProperty(dataObj, property) + "";
                if (str.toLowerCase().includes(query.toLowerCase())) {
                    containsQuery = true;
                }
            });
            if (!containsQuery) {
                matches = false;
            }
            return matches;
        });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : [];
        if (query === "") {
            return arrayDataAll;
        }
        return finalArray;
    }

    function handleQueryChange(event) {
        setQuery(event.target.value)
        var filterArray = applyFilters(arrayData, event.target.value)
        setFilteredArrayData(filterArray)
    }

    function handleFilterChange(selectedOD, selectedWallThick, selectedGrade, selectedConnection, selectedStatus) {
        getData(selectedOD, selectedWallThick, selectedGrade, selectedConnection, selectedStatus)
    }

    function getProperty(obj, prop) {
        const props = prop.split('.');
        return props.reduce((o, p) => o && o[p], obj);
    }

    return (
        <Stack pb={!isFullScreen && 2} pt={2} px={2.5} width={'100%'}>
            {!isFullScreen && (
                <Stack>
                    <Stack direction={'row'} gap={4} rowGap={2} flexWrap={'wrap'}>
                        <Metrics icon={<Cluster />} data={metrics1} />
                        <Metrics icon={<Cluster />} data={metrics2} />
                    </Stack>
                </Stack>
            )}
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mt={isFullScreen ? 0 : 2}>
                <Stack direction={'row'} spacing={2} >
                    <SearchInput handleQueryChange={handleQueryChange} />
                    <Filters
                        handleFilerChange={(event) => {
                            var od = event.target.value
                            setSelectedOD(od)
                            handleFilterChange(od, selectedWallThick, selectedGrade, selectedConnection, selectedStatus)
                        }}
                        value={selectedOD}
                        name="selectedOD"
                        placeholder="O.D."
                        width="10vw"
                    >
                        {arrayOD.map((item, index) => (
                            <MenuItem key={index} value={item}> {item}</MenuItem>
                        ))}
                    </Filters>

                    <Filters
                        handleFilerChange={(event) => {
                            var wallThick = event.target.value
                            setSelectedWallThick(wallThick)
                            handleFilterChange(selectedOD, wallThick, selectedGrade, selectedConnection, selectedStatus)
                        }}
                        value={selectedWallThick}
                        name="selectedWallThick"
                        placeholder="Wall. Thick."
                        width="10vw"
                    >
                        {arrayWallThickness.map((item, index) => (
                            <MenuItem key={index} value={item}> {item}</MenuItem>
                        ))}
                    </Filters>

                    <Filters
                        handleFilerChange={(event) => {
                            var grade = event.target.value
                            setSelectedGrade(grade)
                            handleFilterChange(selectedOD, selectedWallThick, grade, selectedConnection, selectedStatus)
                        }}
                        value={selectedGrade}
                        name="selectedGrade"
                        placeholder="Grade"
                        width="10vw"
                    >
                        {arrayGrade.map((item, index) => (
                            <MenuItem key={index} value={item}> {item}</MenuItem>
                        ))}
                    </Filters>

                    <Filters
                        handleFilerChange={(event) => {
                            var connection = event.target.value
                            setSelectedConnection(connection)
                            handleFilterChange(selectedOD, selectedWallThick, selectedGrade, connection, selectedStatus)
                        }}
                        value={selectedConnection}
                        name="selectedConnection"
                        placeholder="Connection"
                        width="10vw"
                    >
                        {arrayConnection.map((item, index) => (
                            <MenuItem key={index} value={item}> {item}</MenuItem>
                        ))}
                    </Filters>

                    <Filters
                        handleFilerChange={(event) => {
                            var status = event.target.value
                            setSelectedStatus(status)
                            handleFilterChange(selectedOD, selectedWallThick, selectedGrade, selectedConnection, status)
                        }}
                        value={selectedStatus}
                        name="selectedStatus"
                        placeholder="Status"
                        width="10vw"
                    >
                        {arrayStatus.map((item, index) => (
                            <MenuItem key={index} value={item.id}> {item.value}</MenuItem>
                        ))}
                    </Filters>
                </Stack>
                <CircleButton
                    icon={isFullScreen ? <FullscreenExit /> : <Fullscreen />}
                    onClick={() => setIsFullScreen((prevState) => !prevState)}
                />
            </Stack>
            <Box
                mx={isFullScreen && '-20px'}
                mt={2}
                flex={1}
                border={'1px solid'}
                borderColor={'grey.light'}
                borderRadius={'5px'}
                overflow={'hidden'}
            >
                {!isFullScreen && <Map arrayInventory={filteredArrayData} />}
                {isFullScreen && <Map arrayInventory={filteredArrayData} />}
            </Box>
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack>

    );
};