import { Title } from '../../ui/Title';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import ShowPasswordIcon from '../../assets/icons/ShowPasswordIcon.svg';
import HidePasswordIcon from '../../assets/icons/HidePasswordIcon.svg';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { useSnackbar } from 'notistack'
import gtm from '../../lib/gtm';
import useAuth from '../../hooks/useAuth';
import * as Yup from 'yup';
import { Formik } from 'formik';
import logo from '../../assets/images/logo.svg';

export const Login = (props) => {

    const [showPassword, setShowPassword] = useState(false);
    const { isExpired } = useAuth();
    const { enqueueSnackbar } = useSnackbar()
    const isMountedRef = useIsMountedRef();
    const { login } = useAuth();

    useEffect(() => {
        console.log("Is Expired", isExpired)
        if (isExpired) {
            enqueueSnackbar("Your Login is expired, Please login again", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
        gtm.push({ event: 'page_view' })
    }, [])

    return (
        <Stack alignItems={'center'} justifyContent={'center'} bgcolor={'grey.300'} py={10} px={4} width={'100%'}>
            <Stack alignItems={'center'} justifyContent={'center'} maxWidth={350} mx={'auto'}>
                <Box width={310} height={63} objectFit='contain' src={logo} component={'img'} mb={1.5} />
                <Typography mb={6}>Steel Lifecycle Management</Typography>
                <Title mb={3}>Sign In</Title>
                <Typography mb={3}>Hi! Welcome back, lets get to work.</Typography>
                <Box width={'100%'}>
                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                            submit: null
                        }}
                        validationSchema={Yup
                            .object()
                            .shape({
                                email: Yup.string().required('Email is required').email('Must be a valid email'),
                                password: Yup.string().required('Password is required')
                            })}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            try {
                                console.log("LOGIN RES:")
                                var data = await login(values.email, values.password);
                                console.log("LOGIN RES:", data)
                                if (data !== undefined && data.success) {
                                    enqueueSnackbar(data.message, {
                                        anchorOrigin: {
                                            horizontal: 'right',
                                            vertical: 'top'
                                        },
                                        variant: 'success'
                                    });
                                    setStatus({ success: true });
                                    setSubmitting(false);
                                } else {
                                    enqueueSnackbar(data.message, {
                                        anchorOrigin: {
                                            horizontal: 'right',
                                            vertical: 'top'
                                        },
                                        variant: 'error'
                                    });
                                    setStatus({ success: false });
                                    setErrors({ submit: data.message });
                                    setSubmitting(false);
                                }

                            } catch (err) {
                                console.log("inCATCH", err);
                                console.error(err);
                                if (isMountedRef.current) {
                                    setStatus({ success: false });
                                    setErrors({ submit: err.message });
                                    setSubmitting(false);
                                }
                            }
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <form
                                noValidate
                                onSubmit={handleSubmit}
                                {...props}
                            >
                                <Stack spacing={1.5} width={'100%'}>
                                    <Stack direction={'column'} width={'100%'}>
                                        <Typography mb={1.5} color={'grey.blue'}>
                                            Email
                                        </Typography>
                                        <TextField
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    padding: '17px 25px',
                                                    borderRadius: '10px',
                                                    background: 'white',
                                                    border: '1px solid #E1E3E8',
                                                },
                                            }}
                                            type={'email'}
                                            placeholder='example@oilservices.com'
                                            name="email"
                                            value={values.email}
                                            error={Boolean(touched.email && errors.email)}
                                            helperText={touched.email && errors.email}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Stack>
                                    <Stack direction={'column'} width={'100%'}>
                                        <Typography mb={1.5} color={'grey.blue'}>
                                            Password
                                        </Typography>
                                        <Box position={'relative'}>
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    right: '30px',
                                                    transform: 'translateY(-50%)',
                                                    cursor: 'pointer',
                                                    zIndex: 1,
                                                    width: '24px',
                                                    height: '24px',
                                                    objectFit: 'contain',
                                                }}
                                                component={'img'}
                                                src={showPassword ? HidePasswordIcon : ShowPasswordIcon}
                                                onClick={() => setShowPassword((prevState) => !prevState)}
                                            />
                                            <TextField
                                                sx={{
                                                    '& .MuiInputBase-input': {
                                                        lineHeight: '5',
                                                        padding: '17px 60px 17px 25px',
                                                        borderRadius: '10px',
                                                        background: 'white',
                                                        border: '1px solid #E1E3E8',
                                                        '&::placeholder': {
                                                            position: 'absolute',
                                                            top: '50%',
                                                            transform: 'translateY(calc(-50% + 2px))',
                                                        },
                                                    },
                                                }}
                                                type={showPassword ? 'text' : 'password'}
                                                placeholder='*************************'
                                                name="password"
                                                value={values.password}
                                                error={Boolean(touched.password && errors.password)}
                                                helperText={touched.password && errors.password}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                        </Box>
                                    </Stack>
                                    <Link to={'/forgot-password'}>
                                        <Typography
                                            sx={{
                                                color: 'grey.blue',
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                                fontSize: 13,
                                                textAlign: 'right',
                                                '&:hover': {
                                                    textDecoration: 'none',
                                                },
                                            }}
                                        >
                                            Forgot Password?
                                        </Typography>
                                    </Link>
                                    <Button
                                        sx={{
                                            fontSize: 20,
                                            py: 1.2,
                                            borderRadius: '30px',
                                            maxWidth: 300,
                                            width: '100%',
                                            alignSelf: 'center',
                                        }}
                                        type={'submit'}
                                        disabled={isSubmitting}
                                    >
                                        Sign In
                                    </Button>
                                </Stack>
                            </form>
                        )}
                    </Formik>

                </Box>
                <Stack width='100%' justifyContent={'center'} alignItems={'center'} direction={'row'} spacing={1} my={4}>
                    <Box
                        sx={{
                            height: '1px',
                            width: '20%',
                            bgcolor: 'grey.light',
                        }}
                    />
                    <Typography
                        sx={{
                            whiteSpace: 'nowrap',
                        }}
                    >
                        Don’t have an account?
                    </Typography>
                    <Box
                        sx={{
                            height: '1px',
                            width: '20%',
                            bgcolor: 'grey.light',
                        }}
                    />
                </Stack>
                <Link to={'/authentication/guestAccess'}>
                    <Typography
                        sx={{
                            textDecoration: 'underline',
                            fontSize: 20,
                            fontWeight: 700,
                            textTransform: 'uppercase',
                            '&:hover': {
                                textDecoration: 'none',
                            },
                        }}
                    >
                        Guest Access
                    </Typography>
                </Link>
                <Stack spacing={1.5} mt={4}>
                    <Typography>Version 2.0.00</Typography>
                    <Typography>STEELDNA, LLC</Typography>
                </Stack>
            </Stack>
        </Stack>
    )
}