import mapboxgl from 'mapbox-gl';
import { Box, Backdrop, CircularProgress, Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { DefaultButton } from '../../ui/DefaultButton';
import api from '../../lib/axios';
import moment from 'moment';
import pin from '../../assets/images/pin.png';
mapboxgl.accessToken = 'pk.eyJ1Ijoic3RlZWxkbmEiLCJhIjoiY2x3dGNzNjRtMDI4aTJqb24wc2tqeGF4byJ9.XjKo_-RCP80Ytrn6K07MxA';

export const Map = ({ sx, salesOrderID, showEditButton, onDetail }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [arrayTruckLoads, setArrayTruckLoads] = useState([]);
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [zoom, setZoom] = useState(9);
    const [isShowAll, setIsShowAll] = useState(false);

    const initMap = (arrayData) => {
        setIsShowAll(false)
        if (map.current) {
            map.current.remove();
            map.current = null
        }
        var lat = 42.16
        var lng = -71.30
        if (arrayData.length > 0) {
            lng = arrayData[0].longitude
            lat = arrayData[0].latitude
        }
        if (!map.current && mapContainer.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/steeldna/clwunfa5n01ak01pnflmb60p4',
                center: [lng, lat],
                zoom: zoom,
            });
            map.current.addControl(new mapboxgl.NavigationControl());

            let arrayCoordinates = []
            arrayData.map((data) => {
                if (data.longitude != null && data.latitude != null) {
                    var longitude = data.longitude
                    var latitude = data.latitude
                    arrayCoordinates.push([longitude, latitude])
                }
            })

            const waypoints = arrayData.map((cluster) => ({
                type: "Feature",
                properties: cluster,
                geometry: {
                    type: "Point",
                    coordinates: [cluster.longitude, cluster.latitude, 0.0],
                },
            }));

            var objCluster = {
                "type": "FeatureCollection",
                "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
                "features": waypoints
            }

            map.current.loadImage(pin, function (error, image) {
                if (error) throw error;
                map.current.addImage('custom-pin', image);
            });

            map.current.on('load', () => {
                map.current.addSource('earthquakes', {
                    type: 'geojson',
                    data: objCluster,
                    cluster: true,
                    clusterMaxZoom: 14,
                    clusterRadius: 50
                });

                map.current.addLayer({
                    id: 'clusters',
                    type: 'circle',
                    source: 'earthquakes',
                    filter: ['has', 'point_count'],
                    paint: {
                        // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
                        // with three steps to implement three types of circles:
                        //   * Blue, 20px circles when point count is less than 100
                        //   * Yellow, 30px circles when point count is between 100 and 750
                        //   * Pink, 40px circles when point count is greater than or equal to 750
                        'circle-color': [
                            'step',
                            ['get', 'point_count'],
                            'black',
                            100,
                            'black',
                            750,
                            'black'
                        ],
                        'circle-radius': [
                            'step',
                            ['get', 'point_count'],
                            20,
                            100,
                            30,
                            750,
                            40
                        ]
                    }
                });

                map.current.addLayer({
                    id: 'cluster-count',
                    type: 'symbol',
                    source: 'earthquakes',
                    filter: ['has', 'point_count'],
                    layout: {
                        'text-field': ['get', 'point_count_abbreviated'],
                        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                        'text-size': 12,
                    },
                    paint: {
                        'text-color': '#ffffff'
                    }
                });

                map.current.addLayer({
                    id: 'unclustered-point',
                    type: "symbol",
                    source: 'earthquakes',
                    filter: ['!', ['has', 'point_count']],
                    layout: {
                        "icon-image": "custom-pin",
                        "icon-size": 0.4,
                    },
                });

                // inspect a cluster on click
                map.current.on('click', 'clusters', (e) => {
                    const features = map.current.queryRenderedFeatures(e.point, {
                        layers: ['clusters']
                    });
                    const clusterId = features[0].properties.cluster_id;
                    map.current.getSource('earthquakes').getClusterExpansionZoom(
                        clusterId,
                        (err, zoom) => {
                            if (err) return;

                            map.current.easeTo({
                                center: features[0].geometry.coordinates,
                                zoom: zoom
                            });
                        }
                    );
                });

                map.current.on('click', 'unclustered-point', (e) => {
                    const data = e.features[0].properties;
                    getDirection(data)
                });

                map.current.on('mouseenter', 'clusters', () => {
                    map.current.getCanvas().style.cursor = 'pointer';
                });
                map.current.on('mouseleave', 'clusters', () => {
                    map.current.getCanvas().style.cursor = '';
                });
            });
            //     if (arrayData.length > 0) {
            //         map.current.addSource("points", {
            //             type: "geojson",
            //             data: {
            //                 type: "FeatureCollection",
            //                 features: waypoints,
            //             },
            //         });

            //         map.current.addLayer({
            //             id: "points",
            //             type: "symbol",
            //             source: "points",
            //             layout: {
            //                 "icon-image": "custom-pin",
            //                 "icon-size": 0.4,
            //             },
            //         });

            //         map.current.on('click', 'points', (e) => {
            //             const data = e.features[0].properties;
            //             getDirection(data)
            //         });

            //     }
            // });
        }
    };

    const routeMap = (arrayData, truckData) => {

        if (map.current) {
            map.current.remove();
            map.current = null
        }
        var lat = 42.16
        var lng = -71.30
        if (arrayData.length > 0) {
            lng = arrayData[0].positionLongitude
            lat = arrayData[0].positionLatitude
        }
        if (!map.current && mapContainer.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/steeldna/clwunfa5n01ak01pnflmb60p4',
                center: [lng, lat],
                zoom: 9,
            });
            map.current.addControl(new mapboxgl.NavigationControl());

            let arrayCoordinates = []

            arrayData.map((data) => {

                if (data.positionLongitude != null && data.positionLatitude != null) {
                    var longitude = data.positionLongitude
                    var latitude = data.positionLatitude
                    arrayCoordinates.push([longitude, latitude])
                }

            })

            map.current.on('load', () => {
                if (arrayData.length > 0) {
                    fetchRouteFromDirectionsAPI(arrayCoordinates).then((route) => {
                        console.log("route", route)
                        if (!map.current.getSource('route')) {
                            map.current.addSource('route', {
                                'type': 'geojson',
                                'data': {
                                    'type': 'Feature',
                                    'properties': {},
                                    'geometry': route
                                }
                            });
                        }
                        if (!map.current.getLayer('route')) {
                            map.current.addLayer({
                                'id': 'route',
                                'type': 'line',
                                'source': 'route',
                                'layout': {
                                    'line-join': 'round',
                                    'line-cap': 'round'
                                },
                                'paint': {
                                    'line-color': '#0061FF',
                                    'line-width': 6
                                }
                            });
                        }

                        if (route.coordinates != null && route.coordinates.length > 0) {
                            var startMarkerEl = document.createElement('div');
                            startMarkerEl.className = 'custom-marker';
                            startMarkerEl.style.backgroundImage = `url(${pin})`;
                            startMarkerEl.style.backgroundSize = 'cover';
                            startMarkerEl.style.width = '25px';
                            startMarkerEl.style.height = '25px';

                            var startMarker = new mapboxgl.Marker({ element: startMarkerEl })
                                .setLngLat(route.coordinates[0])
                                .addTo(map.current);

                            startMarker.getElement().addEventListener('click', function () {
                                const coordinates = startMarker.getLngLat().toArray();
                                showPopUp(truckData, coordinates)
                            });

                            var endMarkerEl = document.createElement('div');
                            endMarkerEl.className = 'custom-marker';
                            endMarkerEl.style.backgroundImage = `url(${pin})`;
                            endMarkerEl.style.backgroundSize = 'cover';
                            endMarkerEl.style.width = '25px';
                            endMarkerEl.style.height = '25px';
                            var endMarker = new mapboxgl.Marker({ element: endMarkerEl })
                                .setLngLat(route.coordinates[route.coordinates.length - 1])
                                .addTo(map.current);

                            endMarker.getElement().addEventListener('click', function () {
                                const coordinates = endMarker.getLngLat().toArray();
                                showPopUp(truckData, coordinates)
                            });
                        }

                        map.current.on('click', 'route', (e) => {
                            const coordinates = e.lngLat.toArray();
                            showPopUp(truckData, coordinates)
                        });

                    });


                    // if (!map.current.getSource('route')) {
                    //     map.current.addSource('route', {
                    //         'type': 'geojson',
                    //         'data': {
                    //             'type': 'Feature',
                    //             'properties': {},
                    //             'geometry': {
                    //                 'type': 'LineString',
                    //                 'coordinates': arrayCoordinates
                    //             }
                    //         }
                    //     });
                    // }
                    // if (!map.current.getLayer('route')) {
                    //     map.current.addLayer({
                    //         'id': 'route',
                    //         'type': 'line',
                    //         'source': 'route',
                    //         'layout': {
                    //             'line-join': 'round',
                    //             'line-cap': 'round'
                    //         },
                    //         'paint': {
                    //             'line-color': '#0061FF',
                    //             'line-width': 6
                    //         }
                    //     });
                    // }

                }
                setIsShowAll(true)
            });
            const fetchRouteFromDirectionsAPI = async (coords) => {
                try {
                    const response = await fetch(
                        `https://api.mapbox.com/directions/v5/mapbox/driving/${coords.join(";")}?access_token=${mapboxgl.accessToken}&geometries=geojson`,
                        {
                            method: "GET",
                            headers: {
                                Authorization: `Bearer ${mapboxgl.accessToken}`,
                            },
                        }
                    );

                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const data = await response.json();
                    if (data.routes != null && data.routes.length > 0) {
                        console.log("data from map box", data.routes[0].geometry)
                        return data.routes[0].geometry;
                    } else {
                        return []
                    }
                } catch (error) {
                    console.error('Error fetching route:', error);
                    return []; // or handle error appropriately
                }
            };

        }
    };

    function showPopUp(truckData, coordinates) {
        new mapboxgl.Popup({ closeOnClick: true })
            .setLngLat(coordinates)
            .setHTML(
                `<div class="marker-wrapper-detail"  style='border: 2px solid #000000; display: flex; flex-direction: column; align-items: center; text-align: center;'>
            <h3 class="marker-title">${truckData.truckName || "-"}</h3>
            <div class="marker-bottom">
                <div class="marker-item-name">${truckData.serialNumber || "-"}</div>
            </div>
        </div>
        `
            )
            .addTo(map.current);
    }

    const getTruckLoads = () => {
        setIsLoading(true);
        api.get('/apis/SalesTruckLoads/bySalesOrder/' + salesOrderID).then((res) => {

            if (res.data.success) {
                var resData = res.data.data
                setArrayTruckLoads(resData)
                setIsLoading(false);
                initMap(resData)
            } else {
                setArrayTruckLoads([])
                setIsLoading(false);
                initMap([])
            }
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }

    const getAllTruckLoads = () => {
        setIsLoading(true);
        api.get('/apis/SalesTruckLoads/getAll').then((res) => {

            if (res.data.success) {
                var resData = res.data.data
                setArrayTruckLoads(resData)
                setIsLoading(false);
                initMap(resData)
            } else {
                setArrayTruckLoads([])
                setIsLoading(false);
                initMap([])
            }
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }

    const getDirection = (truckData) => {
        var startDate = moment(truckData.startDate).format("MM-DD-YYYY")
        var endDate = truckData.endDate ? moment(truckData.endDate).format("MM-DD-YYYY") : moment().format("MM-DD-YYYY")
        setIsLoading(true);
        var data = {
            trackerID: truckData.trackerID,
            startDate: startDate,
            endDate: endDate
        }
        api.post('/apis/Trackers/getDirection', data).then((res) => {

            if (res.data.success) {
                var resData = res.data.data
                if (resData.length > 0) {
                    truckData.serialNumber = resData[0].deviceSerialNumber
                }

                routeMap(resData, truckData)
                setIsLoading(false);
            } else {
                routeMap([], truckData)
                setIsLoading(false);
            }
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }

    useEffect(() => {
        if (salesOrderID != "") {
            if (salesOrderID == "0") {
                getAllTruckLoads()
            } else {
                getTruckLoads()
            }

        } else {
            if (map.current) {
                map.current.remove();
                map.current = null
            }
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/steeldna/clwunfa5n01ak01pnflmb60p4',
                center: [-71.30, 42.16],
                zoom: 9,
            });
            map.current.addControl(new mapboxgl.NavigationControl());
        }
    }, [salesOrderID, showEditButton]);

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    ...sx,
                }}
                ref={mapContainer}
            >
                <Stack sx={{
                    direction: "row", alignContent: "center", position: 'absolute',
                    gap: 1,
                    zIndex: 1,
                    left: 15,
                    top: 15,
                }}>
                    {showEditButton && <DefaultButton sx={{
                        backgroundColor: "#FCFCFE", color: "#151D26", borderColor: "#F1F1F5", width: "100px",
                        '&:hover': {
                            backgroundColor: "#FCFCFE",
                            color: "#151D26",
                            borderColor: "#F1F1F5",
                        },
                    }}
                        onClick={(e) => {
                            onDetail()
                        }}
                    >
                        Details
                    </DefaultButton>}
                    {isShowAll && <DefaultButton sx={{
                        backgroundColor: "#FCFCFE", color: "#151D26", borderColor: "#F1F1F5", width: "100px",
                        '&:hover': {
                            backgroundColor: "#FCFCFE",
                            color: "#151D26",
                            borderColor: "#F1F1F5",
                        },
                    }}
                        onClick={(e) => {

                            initMap(arrayTruckLoads)
                        }}
                    >
                        All
                    </DefaultButton>}
                </Stack>

            </Box>
            <Backdrop style={{ zIndex: 10 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};
