


import * as React from "react"

export const Cluster  = (props) => (
  <svg
    data-name="Group 37890"
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 13073"
          fill="none"
          stroke="#707070"
          strokeWidth={2}
          d="M0 0h40v40H0z"
        />
      </clipPath>
    </defs>
    <g
      data-name="Group 37887"
      clipPath="url(#a)"
      fill="none"
      stroke="#163567"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <path
        data-name="Path 17401"
        d="M17.5 5.833A16.668 16.668 0 1 0 34.166 22.5H17.5Z"
      />
      <path
        data-name="Path 17402"
        d="M22.5 17.5h16.667A16.666 16.666 0 0 0 22.5.833Z"
      />
    </g>
  </svg>
)




