import { TextField, Stack, Typography, Autocomplete } from '@mui/material';
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const DefaultDropDown = ({ label, placeholder, value, options, getOptionLabel, isOptionEqualToValue, ...otherProps }) => {
    return (
        <Stack direction={'row'} alignItems={'start'} justifyContent={'space-between'}>
            <Typography color={'#A8A8A7'}>{label}</Typography>
            <Autocomplete
                {...otherProps}
                popupIcon={<KeyboardArrowDownIcon style={{ color: '#262B40' }} />}
                autoSelect
                value={value}
                options={options}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                onChange={(event, newValue) => {
                    otherProps.onChange && otherProps.onChange(event, newValue);
                }}
                style={{ width: "70%", display: 'flex', justifyContent: 'center' }}
                renderInput={(params) => (
                    <TextField
                        variant='outlined'
                        placeholder={placeholder}
                        sx={{
                            width: "100%",
                            border: '1px solid',
                            borderColor: 'grey.light',
                            borderRadius: '10px',
                            '.MuiInputBase-root': {
                                pb: "22px",
                                height: otherProps.multiline ? "100%" : '40px',
                            },
                            '.MuiInputBase-input': {
                                height: '100% !important',
                                overflowY: 'auto !important',
                                '&::-webkit-scrollbar': {
                                    width: '4px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    width: '4px',
                                    bgcolor: 'grey.400',
                                },
                            },
                        }}
                        {...otherProps}
                        {...params}
                    />
                )}
            />

        </Stack>
    );
};
