export const PipetallyTabs = [
    {
        title: 'Tallies',
        value: 'tallies',
    },
    {
        title: 'Transfers',
        value: 'transfers',
    },
];
