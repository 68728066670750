export const tabs = [
    {
        title: 'Details',
        value: 'details',
    },
    {
        title: 'Attachments',
        value: 'attachments',
    },
];
export const linePipeId = "64bfb6007efa59381cf22fba"
export const tubingId = "64bfb5ee7efa59381cf22fb4"
