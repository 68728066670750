import { useEffect, useState } from "react";
import React from 'react';
import { Cluster } from '../../assets/icons/Cluster';
import { Metrics } from '../../components/Metrics';
import { useNavigate } from "react-router";
import { ArrowForwardIos } from '@mui/icons-material';
import { Box, MenuItem, Stack, Typography, Backdrop, CircularProgress } from '@mui/material';
import { DefaultDataGrid } from '../../ui/DefaultDataGrid/index.js';
import { Filters } from '../../ui/Filters/index.js';
import api from '../../lib/axios.js';
import { SearchInput } from '../../components/SearchInput/SearchInput.jsx';
import { CircleButton } from '../../ui/CircleButton/CircleButton.jsx';

export const PipeProfiles = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [arrayData, setArrayData] = useState([]);
    const [query, setQuery] = useState("");
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const [arrayOD, setArrayOD] = useState([]);
    const [arrayWallThickness, setArrayWallThickness] = useState([]);
    const [arrayGrade, setArrayGrade] = useState([]);
    const [arrayConnection, setArrayConnection] = useState([]);
    const [arrayStatus, setArrayStatus] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(-1)
    const [selectedOD, setSelectedOD] = useState(-1)
    const [selectedWallThick, setSelectedWallThick] = useState(-1)
    const [selectedGrade, setSelectedGrade] = useState(-1)
    const [selectedConnection, setSelectedConnection] = useState(-1)
    const [metrics1, setMetrics1] = useState([
        {
            title: 'In Service',
            value: 0,
            color: 'green.main',
        },
        {
            title: 'Inspection',
            value: 0,
            color: 'orange.main',
        },
        {
            title: 'Storage',
            value: 0,
            color: 'primary.main',
        },
        {
            title: 'Ready',
            value: 0,
            color: 'red.light',
        },
        {
            title: 'Transit',
            value: 0,
            color: 'blue.main',
        },
    ]);
    const [metrics2, setMetrics2] = useState([
        {
            title: 'Utilization',
            value: '0%',
        },
        {
            title: 'Last 30 Days',
            value: '+50%',
            isGreen: true,
        },
    ]);
    const columns = [
        {
            field: 'category',
            headerName: 'Category',
            width: 120,
        },
        { field: 'type', headerName: 'Type', width: 110 },
        { field: 'od', headerName: 'O.D.', width: 110 },
        { field: 'weight', headerName: 'Weight (lb/ft)', width: 110 },
        { field: 'wallThickness', headerName: 'Wall Thickness', width: 110 },
        { field: 'grade', headerName: 'Grade', width: 80 },
        { field: 'connectionName', headerName: 'Connection', width: 110 },
        { field: 'description', headerName: 'Description', width: 160 },
        {
            field: 'attributes', headerName: 'Attributes', width: 140,
            renderCell: ({ value }) => (

                <Typography fontSize={13}>
                    {value.filter(obj => obj.isOn).map(attribute => attribute.title).join(', ') == "" ? "-" : value.filter(obj => obj.isOn).map(attribute => attribute.title).join(', ')}
                </Typography>

            ),
        },
        {
            field: 'arrowIcon',
            headerName: '',
            flex: 1,
            renderCell: () => (
                <Stack alignItems={'flex-end'} width={'100%'} pr={5}>
                    <ArrowForwardIos
                        sx={{
                            width: 18,
                            height: 18,
                        }}
                    />
                </Stack>
            ),
            sortable: false,
            disableColumnMenu: true,
        },
    ];

    useEffect(() => {
        getFilterData()
    }, []);

    function getFilterData() {
        setIsLoading(true)
        api.get('/apis/Inventories/filterDetail').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayOD(resData.arrayOD)
                setArrayWallThickness(resData.arrayWallThick)
                setArrayGrade(resData.arrayGrade)
                setArrayConnection(resData.arrayConnection)
                // setIsLoading(false)
            }
            getData(selectedOD, selectedWallThick, selectedGrade, selectedConnection)
        }).catch(function (error) {
            console.log(error);
        });
    }

    function getData(selectedOD, selectedWallThick, selectedGrade, selectedConnection) {
        setIsLoading(true)
        var data = {
            od: selectedOD != -1 ? selectedOD : "",
            wallThickness: selectedWallThick != -1 ? selectedWallThick : "",
            grade: selectedGrade != -1 ? selectedGrade : "",
            connection: selectedConnection != -1 ? selectedConnection : "",
        }
        api.post('/apis/PipeProfiles/getAll', data).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var filterArray = applyFilters(resData, query)
                setArrayData(resData)
                setFilteredArrayData(filterArray)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    function handleQueryChange(event) {
        setQuery(event.target.value)
        var filterArray = applyFilters(arrayData, event.target.value)
        setFilteredArrayData(filterArray)
    }

    function handleFilterChange(selectedOD, selectedWallThick, selectedGrade, selectedConnection) {
        getData(selectedOD, selectedWallThick, selectedGrade, selectedConnection)
    }

    function applyFilters(arrayDataAll, query) {
        var finalArray = []
        var filteredArray = arrayDataAll
            .filter((dataObj) => {
                let matches = true;

                const properties = ['category', 'type', 'od', 'weight', 'wallThickness', 'grade', 'connectionName', 'description'];
                let containsQuery = false;

                properties.forEach((property) => {
                    var str = dataObj[property] + ""
                    if (str.toLowerCase().includes(query.toLowerCase())) {
                        containsQuery = true;
                    }
                });
                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
        finalArray = filteredArray.length > 0 ? [...filteredArray] : []

        if (query === "") {
            return arrayDataAll
        }
        return finalArray
    }

    return (
        <>
            <Stack py={2} px={2.5} width={'100%'}>
                <Stack direction={'row'} gap={4} rowGap={2} flexWrap={'wrap'}>
                    <Metrics icon={<Cluster />} data={metrics1} />
                    <Metrics icon={<Cluster />} data={metrics2} />
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mt={2}>
                    <Stack direction={'row'} spacing={2} >
                        <SearchInput handleQueryChange={handleQueryChange} />
                        <Filters
                            handleFilerChange={(event) => {
                                var od = event.target.value
                                setSelectedOD(od)
                                handleFilterChange(od, selectedWallThick, selectedGrade, selectedConnection)
                            }}
                            value={selectedOD}
                            name="selectedOD"
                            placeholder="O.D."
                            width="10vw"
                        >
                            {arrayOD.map((item, index) => (
                                <MenuItem key={index} value={item}> {item}</MenuItem>
                            ))}
                        </Filters>

                        <Filters
                            handleFilerChange={(event) => {
                                var wallThick = event.target.value
                                setSelectedWallThick(wallThick)
                                handleFilterChange(selectedOD, wallThick, selectedGrade, selectedConnection)
                            }}
                            value={selectedWallThick}
                            name="selectedWallThick"
                            placeholder="Wall. Thick."
                            width="10vw"
                        >
                            {arrayWallThickness.map((item, index) => (
                                <MenuItem key={index} value={item}> {item}</MenuItem>
                            ))}
                        </Filters>

                        <Filters
                            handleFilerChange={(event) => {
                                var grade = event.target.value
                                setSelectedGrade(grade)
                                handleFilterChange(selectedOD, selectedWallThick, grade, selectedConnection)
                            }}
                            value={selectedGrade}
                            name="selectedGrade"
                            placeholder="Grade"
                            width="10vw"
                        >
                            {arrayGrade.map((item, index) => (
                                <MenuItem key={index} value={item}> {item}</MenuItem>
                            ))}
                        </Filters>

                        <Filters
                            handleFilerChange={(event) => {
                                var connection = event.target.value
                                setSelectedConnection(connection)
                                handleFilterChange(selectedOD, selectedWallThick, selectedGrade, connection)
                            }}
                            value={selectedConnection}
                            name="selectedConnection"
                            placeholder="Connection"
                            width="10vw"
                        >
                            {arrayConnection.map((item, index) => (
                                <MenuItem key={index} value={item}> {item}</MenuItem>
                            ))}
                        </Filters>
                    </Stack>
                    <CircleButton
                        onClick={() => {
                            navigate(`/pipe-profiles/PipeProfileDetail`, { state: { id: "" } })
                        }}
                    />
                </Stack>
                <Box mt={2} flex={1} border={'1px solid'} borderColor={'grey.light'} borderRadius={'5px'} overflow={'hidden'}>
                    <DefaultDataGrid
                        columns={columns}
                        rows={filteredArrayData}
                        onRowClick={(e) => {
                            navigate(`/pipe-profiles/PipeProfileDetail`, { state: { id: e.id } })
                        }}
                    />
                </Box>
            </Stack >
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}