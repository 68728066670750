import axios from 'axios';
import { jwtDecode } from "jwt-decode";
const api = axios.create();
const handleTokenExpiration = () => {
  localStorage.removeItem('token');
  window.location.href = "/authentication/login";
};
const isTokenExpired = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    // console.log("token data",decodedToken)
    const expirationTime = decodedToken.exp * 1000;
    const currentTime = Date.now();
    return currentTime > expirationTime;
  } catch (error) {
    console.error('Error decoding token:', error);
    return false; 
  }
};
api.interceptors.request.use(
  (config) => {
    // Get the token from storage or Redux/Context state
    const token = localStorage.getItem('accessToken');

    if (token && !isTokenExpired(token)) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
       handleTokenExpiration();
       return Promise.reject()
    }

    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("In Meddleware response",error)
    if (error.response && error.response.status === 401) {
      handleTokenExpiration();
    }

    return Promise.reject(error);
  }
);


// axiosInstance.interceptors.request.use(config => {
//   let accessToken = localStorage.getItem('accessToken');
//   if (accessToken != null && accessToken != undefined && accessToken != "") {
//     config.headers["authorization"] = localStorage.getItem('accessToken');
//   }
//   return config
// }, error => {
//   // console.log("Error In Header",error)
//   return Promise.reject(error)
// })

// axiosInstance.interceptors.response.use(response => {
//   // console.log("response",response)
//   console.log("response status", response.status)
//   return response
// },
//   (error) => {
//     console.log("Token Error", error.response.status)
//     if (error.response.status == 401 || error.response.status == 402 || error.response.status == 403) {
//       console.log("In Side condition", "Yes")
//       // let myhistory = createBrowserHistory();
//       // localStorage.setItem('accessToken',null)
//       window.location.href = "/authentication/login"
//       return Promise.reject()
//     }
//     return Promise.reject(error)
//   })


// export const mock = new AxiosMockAdapter(axiosInstance, { delayResponse: 0 });

export default api;
