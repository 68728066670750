import { Cluster } from '../../assets/icons/Cluster';
import { Metrics } from '../../components/Metrics';
import { Detail } from './Detail.jsx';
import { tabs } from './Data/tabs.js';
import { Tabs } from '../../ui/Tabs';
import { Backdrop, CircularProgress, Stack } from '@mui/material';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import api from '../../lib/axios'
import { useNavigate } from 'react-router-dom';
import { PipeProfileNew } from './PipeProfileNew';
import { Attachments } from './Attachments';
import { PipeProfilesIcon } from '../../assets/icons/PipeProfilesIcon.jsx';
import { PageTitle } from '../../components/PageTitle/PageTitle.jsx';

export const PipeProfilesDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const selectedDataId = location.state.id
    const [activeTab, setActiveTab] = useState(tabs[0].value);
    const [isLoading, setIsLoading] = useState(false);
    const [objPipeProfile, setObjPipeProfile] = useState({})
    const [isEditOpened, setIsEditOpened] = useState(selectedDataId == "" ? true : false);
    const [metrics1, setMetrics1] = useState([
        {
            title: 'OCTG',
            value: 0,
            color: 'green.main',
        },
        {
            title: 'Drill Pipe',
            value: 0,
            color: 'orange.main',
        },
        {
            title: 'BHA’s',
            value: 0,
            color: 'blue.main',
        },
        {
            title: 'Line',
            value: 0,
            color: 'red.light',
        },
        {
            title: 'HWDP',
            value: 0,
            color: 'primary.main',
        },
    ]);
    const handleChangeTab = (tab) => {
        if (!isEditOpened) {
            setActiveTab(tab);
        }
    };

    const onEditClick = () => {
        setIsEditOpened(true);
    };

    useEffect(() => {
        if (selectedDataId != "") {
            getPipeProfileData()
        }
    }, []);

    function getPipeProfileData() {
        setIsLoading(true)
        api.get('/apis/PipeProfiles/getById/' + selectedDataId).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setObjPipeProfile(resData)

            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const isFirstTab = activeTab === tabs[0].value
    const isSecondTab = activeTab === tabs[1].value

    return (
        <Stack py={2} px={2.5} width={'100%'}>
            <Stack mb={3}>
                <PageTitle icon={<PipeProfilesIcon />}>Pipe Profiles</PageTitle>
            </Stack>
            <Stack direction={'row'} gap={4} rowGap={2} flexWrap={'wrap'} mb={3}>
                <Metrics icon={<Cluster />} data={metrics1} />
            </Stack>
            <Tabs data={tabs} activeTab={activeTab} setActiveTab={handleChangeTab} />
            <Stack
                flex={1}
                border={'1px solid'}
                borderColor={'grey.light'}
                borderRadius={'5px'}
                overflow={'hidden'}
                bgcolor={'common.white'}
                position={'relative'}
            >
                {isFirstTab && <Detail
                    pipeProfileID={selectedDataId}
                    disabled={isEditOpened}
                    onEditClick={onEditClick}
                    objPipeProfile={objPipeProfile}
                    updateDetails={(e) => {
                        getPipeProfileData()
                    }} />}
                {isSecondTab && <Attachments pipeProfileID={selectedDataId} disabled={isEditOpened} />}
                {isEditOpened &&
                    <PipeProfileNew
                        pipeProfileID={selectedDataId}
                        closeDetails={(e) => {
                            if (selectedDataId == "") {
                                navigate(-1)
                            } else {
                                setIsEditOpened(false)
                                if (e) {
                                    getPipeProfileData()
                                }
                            }

                        }}
                    />
                }
            </Stack>
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack>
    );
};
