export const arraySalesOrderStatus = [
    { id: "1", value: "Pending" },
    { id: "2", value: "Ready" },
    { id: "3", value: "Shipped" },
]

export const arrayTruckLoadStatus = [
    { id: "1", value: "Pending" },
    { id: "3", value: "Shipped" },
]

export const getSalesOrderStatusColor = (code) => {
    switch (code) {
        case '1':
            return '#20253B';
        case '2':
            return '#0084FF';
        case '3':
            return '#25D5AA';
        default:
            return '#20253B';
    }
};