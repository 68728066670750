import mapboxgl from 'mapbox-gl';
import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { arrayStatus, arrayType, getPipeTallyStatusColor } from '../../views/PipeTally/data/PipeTallyData.jsx'
import { CircleButton } from '../../ui/CircleButton';
import moment from 'moment';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
mapboxgl.accessToken = 'pk.eyJ1Ijoic3RlZWxkbmEiLCJhIjoiY2x3dGNzNjRtMDI4aTJqb24wc2tqeGF4byJ9.XjKo_-RCP80Ytrn6K07MxA';
export const Map = ({ sx, arrayInventory }) => {

    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(0);
    const [lat, setLat] = useState(0);
    const [zoom, setZoom] = useState(9);

    const initMap = () => {
        if (!map.current && mapContainer.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/steeldna/clwunfa5n01ak01pnflmb60p4',
                center: [lng, lat],
                zoom: zoom,
            });
            if (lat == 0 && lng == 0 && map.current) {
                map.current.setCenter([-71.30, 42.16])
            }
            map.current.addControl(new mapboxgl.NavigationControl());
            map.current.on('move', () => {
                if (lat != "" && lng != "") {
                    setLng(map.current.getCenter().lng.toFixed(4));
                    setLat(map.current.getCenter().lat.toFixed(4));
                }
                setZoom(map.current.getZoom().toFixed(2));
            });
            for (const inventory of arrayInventory) {
                var latitude = inventory.latitude ? inventory.latitude : ""
                var longitude = inventory.longitude ? inventory.longitude : ""

                console.log("latitude:", latitude, "|| longgitude", longitude)
                if (latitude != "" && longitude != "") {
                    if (lat === 0) {
                        setLat(latitude)
                        setLng(longitude)
                        if (map.current) {
                            map.current.setCenter([longitude, latitude])
                        }
                    }
                    const el = document.createElement('div');
                    el.className = 'marker';
                    new mapboxgl.Popup({ closeOnClick: false })
                        .setLngLat([longitude, latitude])
                        .setHTML(
                            `<div class='marker-wrapper'>
                            <div class='marker-header'>
                                <div class="marker-header-icon"></div>
                                <div class="marker-header-item">
                                    <div class="marker-header-item-value">${inventory.purchaseOrder}</div>
                                    <div class="marker-header-item-name">Order</div>
                                </div>
                                <div class="marker-header-item">
                                    <div class="marker-header-item-value">${arrayType.find(type => type.id == inventory.type).value}</div>
                                    <div class="marker-header-item-name">Type</div>
                                </div>
                            </div>
                            <div class='marker-content'>
                                <div class='marker-item'>
                                    <div class='marker-item-value'>${inventory.footage}</div>
                                    <div class='marker-item-name'>${'Footage'}</div>
                                </div>
                                <div class='marker-item'>
                                   <div class='marker-item-value'>${inventory.quantity}</div>
                                    <div class='marker-item-name'>${'Joints'}</div>
                                </div>
                                <div class='marker-item'>
                                    <div class="marker-item-box">
                                        <div class='marker-item-value'>${arrayStatus.find(obj => obj.id == inventory.status)?.value}</div>
                                        <div class='marker-item-value marker-item-circle' style="background-color: ${getPipeTallyStatusColor(inventory.status)};"></div>
                                    </div>
                                    <div class='marker-item-name'>${'Status'}</div>
                                </div>
                            </div>
                            <div class="marker-footer">
                                <div class="marker-footer-icon"></div>
                                <div class="marker-footer-content">
                                    <div class="marker-footer-address">${inventory.address ? inventory.address : ""}</div>
                                    <div class="marker-footer-time">${moment(inventory.updatedAt).format("MM.DD.YYYY @ hh:mm a")}</div>
                                </div>
                                <button type="button" class="marker-footer-btn"></button>
                            </div>
                        </div>
                        `
                        )
                        .addTo(map.current);

                }
            }
        }
    };

    useEffect(() => {
        if (map.current) {
            map.current = null
        }

        initMap()
    }, [arrayInventory]);

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                ...sx,
            }}
            ref={mapContainer}
        />
    );
};
