export const LiveTrackingIcon = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="23.558"
            viewBox="0 0 18 23.558"
            {...props}
        >
            <defs>
                <clipPath id="clip-path">
                    <path
                        fill="none"
                        d="M0 0H18V23.558H0z"
                        data-name="Rectangle 14392"
                    ></path>
                </clipPath>
            </defs>
            <g data-name="Group 40854">
                <g
                    fill="none"
                    stroke="#163567"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    clipPath="url(#clip-path)"
                    data-name="Group 40853"
                >
                    <path
                        d="M17.5 8.933c0 4.991-5.716 11.592-7.773 13.806a.993.993 0 01-1.456 0C6.211 20.534.49 13.952.5 8.933a8.5 8.5 0 0117 0z"
                        data-name="Path 18544"
                    ></path>
                    <circle
                        cx="4"
                        cy="4"
                        r="4"
                        data-name="Ellipse 693"
                        transform="translate(5 5.067)"
                    ></circle>
                </g>
            </g>
        </svg>
    );
};
