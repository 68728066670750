import { Backdrop, Box, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { Popup } from '../../components/Popup/index.js';
import { DefaultButton } from '../../ui/DefaultButton/index.js';
import { useNavigate } from 'react-router-dom';
import { DefaultSelect } from '../../ui/DefaultSelect/index.js';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { DefaultInput } from '../../ui/DefaultInput';
import { arrayStatus, arrayType } from '../PipeTally/data/PipeTallyData.jsx'
import api from '../../lib/axios'
import { enqueueSnackbar } from 'notistack';
import { ConfirmAlert } from '../../customControls/confirmAlert';

export const PipeTallyNew = ({ inventoryID, closeDetails }) => {
    const navigate = useNavigate();
    const [arrayPipeProfile, setArrayPipeProfile] = useState([]);
    const [arrayLocation, setArrayLocation] = useState([]);
    const [arrayUser, setArrayUser] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedType, setSelectedType] = useState(null);
    const [title, setTitle] = useState("");
    const [targetedLength, setTargetedLength] = useState("");
    const [selectedPipeProfileName, setSelectedPipeProfileName] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [purchaseOrder, setPurchaseOrder] = useState("");
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [deleteMessage, setDeleteMessage] = useState("");
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);
    const [tallyID, setTallyID] = useState("");

    useEffect(() => {
        getPipeTallyDetail();
    }, [inventoryID]);

    const onClose = () => {
        closeDetails(false)
    };

    function getPipeTallyDetail() {
        setIsLoading(true)
        api.get('/apis/Tallies/TallyDetail').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayPipeProfile(resData.arrayPipeProfile)
                setArrayLocation(resData.arraySite)
                setArrayUser(resData.arrayUser)
                if (inventoryID != "") {
                    getPipeTallyData(resData.arrayUser, resData.arraySite, resData.arrayPipeProfile)
                } else {
                    var selectedStatus = arrayStatus.filter(status => status.id == "3")
                    if (selectedStatus.length > 0) {
                        selectedStatus = selectedStatus[0]
                    } else {
                        selectedStatus = null
                    }
                    setSelectedStatus(selectedStatus)
                    setIsLoading(false)
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    function getPipeTallyData(arrayUser, arrayLocation, arrayPipeProfile) {
        // setIsLoading(true)
        api.get('/apis/Tallies/getById/' + inventoryID).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var selectedStatus = arrayStatus.filter(status => status.id == resData.status)
                if (selectedStatus.length > 0) {
                    selectedStatus = selectedStatus[0]
                } else {
                    selectedStatus = null
                }
                var selectedUser = arrayUser.filter(user => user._id == resData.endUserID)
                if (selectedUser.length > 0) {
                    selectedUser = selectedUser[0]
                } else {
                    selectedUser = null
                }
                var selectedLocation = arrayLocation.filter(location => location._id == resData.locationID)
                if (selectedLocation.length > 0) {
                    selectedLocation = selectedLocation[0]
                } else {
                    selectedLocation = null
                }
                var selectedPipeProfile = arrayPipeProfile.filter(pipeProfile => pipeProfile._id == resData.pipeProfileID)
                if (selectedPipeProfile.length > 0) {
                    selectedPipeProfile = selectedPipeProfile[0]
                } else {
                    selectedPipeProfile = null
                }
                var selectedType = arrayType.filter(profileName => profileName.id == resData.type)
                if (selectedType.length > 0) {
                    selectedType = selectedType[0]
                } else {
                    selectedType = null
                }
                setSelectedLocation(selectedLocation)
                setSelectedPipeProfileName(selectedPipeProfile)
                setSelectedStatus(selectedStatus)
                setSelectedUser(selectedUser)
                setSelectedType(selectedType)
                setTitle(resData.title)
                setPurchaseOrder(resData.purchaseOrder)
                setTargetedLength(resData.targetedLength)
                setTallyID(resData.tallyNo)
                setIsLoading(false)
            }

        }).catch(function (error) {
            console.log(error);
        });
    }

    async function apiCalling(title, selectedType, purchaseOrder, selectedStatus, selectedPipeProfileName, selectedLocation, selecteEndUser, targetedLength) {
        var data = {
            title: title,
            type: selectedType.id,
            purchaseOrder: purchaseOrder,
            status: selectedStatus.id,
            pipeProfileID: selectedPipeProfileName._id,
            locationID: selectedLocation._id,
            endUserID: selecteEndUser._id,
            targetedLength: targetedLength,
        }

        if (inventoryID != "") {
            return await api.put('/apis/Tallies/updateById/' + inventoryID, data)
        } else {
            return await api.post('/apis/Tallies/add', data)
        }
    }

    function deletePipeTally() {
        setIsLoading(true)
        api.delete('/apis/Tallies/deleteById/' + inventoryID).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("Pipe tally deleted", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                navigate(-1);
            } else {
                setDeleteConfirmationShouldOpen(false)
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }
    const updateConfirmAlert = (value) => {
        if (value) {
            deletePipeTally()
        } else {
            setDeleteConfirmationShouldOpen(false)
        }
    }
    const currentDate = new Date();

    const dateFormatOptions = {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    };

    const formattedDate = currentDate.toLocaleString('en-US', dateFormatOptions);
    return (
        <Popup isOpened={true} width={700} bgcolor={'common.white'}>
            <Formik
                enableReinitialize
                initialValues={{
                    submit: null,
                    title: title,
                    selectedType: selectedType,
                    purchaseOrder: purchaseOrder,
                    selectedStatus: selectedStatus,
                    selectedPipeProfileName: selectedPipeProfileName,
                    selectedLocation: selectedLocation,
                    selectedUser: selectedUser,
                    targetedLength: targetedLength,
                }}
                validationSchema={Yup
                    .object()
                    .shape({
                        title: Yup.string().required('Title is required'),
                        selectedType: Yup.object().required('Pipe type is required'),
                        purchaseOrder: Yup.string().required('Purchase order is required'),
                        targetedLength: Yup.string().required('Targeted length is required'),
                        selectedStatus: Yup.object().required('Status is required'),
                        selectedPipeProfileName: Yup.object().required('Pipe profile name is required'),
                        selectedLocation: Yup.object().required('Location is required'),
                        selectedUser: Yup.object().required('User is required'),
                    })}
                onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                    console.log("onsubmit")
                    try {
                        setIsLoading(true)
                        setTitle(values.title)
                        setSelectedType(values.selectedType)
                        setPurchaseOrder(values.purchaseOrder)
                        setSelectedStatus(values.selectedStatus)
                        setSelectedPipeProfileName(values.selectedPipeProfileName)
                        setSelectedLocation(values.selectedLocation)
                        setSelectedUser(values.selectedUser)
                        setTargetedLength(values.targetedLength)
                        var data = await apiCalling(values.title, values.selectedType, values.purchaseOrder, values.selectedStatus, values.selectedPipeProfileName, values.selectedLocation, values.selectedUser, values.targetedLength)
                        setIsLoading(false)
                        if (data !== undefined && data.data.success) {
                            console.log("IF")
                            resetForm();
                            setStatus({ success: true });
                            setSubmitting(false);

                            enqueueSnackbar('Pipe tally ' + (inventoryID == "" ? "added" : "updated"), {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'success'
                            });
                            closeDetails(true)
                        } else {
                            console.log("ELSE")
                            setStatus({ success: false });
                            setErrors({ submit: data.data.message });
                            setSubmitting(false);
                            enqueueSnackbar(data.data.message, {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'error'
                            });
                        }
                    } catch (err) {
                        console.log("error12", err);
                        setIsLoading(false)
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }

                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <form
                        onSubmit={handleSubmit}
                        style={{ height: "100%" }}
                    >
                        <Stack px={3} pt={3} pb={2} spacing={2} height={'100%'}>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <Stack spacing={1}>
                                    <Typography fontSize={20}>Tally Details</Typography>
                                    <Typography fontSize={25} fontWeight={700}>
                                        {tallyID != "" ? tallyID : "-- --"}
                                    </Typography>
                                </Stack>
                                <Typography fontSize={13}>{formattedDate}</Typography>
                            </Stack>

                            <Box flexGrow={1}>
                                <Grid container spacing={3}>
                                    <Grid item xs={8}>
                                        <DefaultInput
                                            label={'Title'}
                                            placeholder={'E.g. xyz'}
                                            value={values.title}
                                            name={"title"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.title && errors.title)}
                                            helperText={touched.title && errors.title}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultSelect
                                            onBlur={handleBlur}
                                            onChange={(event, newValue) => {
                                                values.selectedType = newValue;
                                                console.log("st", newValue);
                                            }}
                                            helperText={touched.selectedType && errors.selectedType && <p>Select type</p>}
                                            error={Boolean(touched.selectedType && errors.selectedType)}
                                            value={values.selectedType}
                                            options={arrayType}
                                            getOptionLabel={(option) => option.value}
                                            isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                            label='Type'
                                            name="selectedType"
                                            placeholder="Select Type" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Purchase Order'}
                                            //type="number"
                                            placeholder={'E.g. 123'}
                                            value={values.purchaseOrder}
                                            name={"purchaseOrder"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.purchaseOrder && errors.purchaseOrder)}
                                            helperText={touched.purchaseOrder && errors.purchaseOrder}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultSelect
                                            onBlur={handleBlur}
                                            onChange={(event, newValue) => {
                                                values.selectedUser = newValue
                                                console.log("st", newValue)
                                            }}
                                            helperText={touched.selectedUser && errors.selectedUser && <p>Select end user</p>}
                                            error={Boolean(touched.selectedUser && errors.selectedUser)}
                                            value={values.selectedUser}
                                            options={arrayUser}
                                            getOptionLabel={(option) => option.firstName + " " + option.lastName}
                                            isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                            label='End User'
                                            name="SelectedEndUser"
                                            placeholder="Selected End User" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultSelect
                                            onBlur={handleBlur}
                                            onChange={(event, newValue) => {
                                                values.selectedLocation = newValue
                                                console.log("st", newValue)
                                            }}
                                            helperText={touched.selectedLocation && errors.selectedLocation && <p>Select site location</p>}
                                            error={Boolean(touched.selectedLocation && errors.selectedLocation)}
                                            value={values.selectedLocation}
                                            options={arrayLocation}
                                            getOptionLabel={(option) => option.siteName}
                                            isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                            label='Site Location'
                                            name="SelectedSiteLocation"
                                            placeholder="Selected Site Location" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Targeted Length'}
                                            type="number"
                                            placeholder={'E.g. 123'}
                                            value={values.targetedLength}
                                            name={"targetedLength"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.targetedLength && errors.targetedLength)}
                                            helperText={touched.targetedLength && errors.targetedLength}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultSelect
                                            onBlur={handleBlur}
                                            onChange={(event, newValue) => {
                                                values.selectedPipeProfileName = newValue
                                                console.log("st", newValue)
                                            }}
                                            helperText={touched.selectedPipeProfileName && errors.selectedPipeProfileName && <p>Select pipe profile name</p>}
                                            error={Boolean(touched.selectedPipeProfileName && errors.selectedPipeProfileName)}
                                            value={values.selectedPipeProfileName}
                                            options={arrayPipeProfile}
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                            name="selectedPipeProfileName"
                                            label='Pipe Profile Name'
                                            placeholder="Select Pipe Profile Name" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultSelect
                                            readOnly={inventoryID == ""}
                                            onBlur={handleBlur}
                                            onChange={(event, newValue) => {
                                                values.selectedStatus = newValue
                                                console.log("st", newValue)
                                            }}
                                            helperText={touched.selectedStatus && errors.selectedStatus && <p>Select status</p>}
                                            error={Boolean(touched.selectedStatus && errors.selectedStatus)}
                                            value={values.selectedStatus}
                                            options={arrayStatus.filter(status => status.id != "3")}
                                            getOptionLabel={(option) => option.value}
                                            isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                            label='Status'
                                            name="selectedStatus"
                                            placeholder="Select Status" />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Stack direction={'row'} spacing={1}>
                                <Box flexGrow={1}>
                                    {inventoryID != "" && (
                                        <DefaultButton
                                            onClick={(e) => {
                                                setDeleteMessage("Are you sure you want to delete?")
                                                setDeleteConfirmationShouldOpen(true)
                                            }}
                                            color={'error'}
                                        >
                                            Delete
                                        </DefaultButton>
                                    )}
                                </Box>
                                <DefaultButton onClick={onClose}>Cancel</DefaultButton>
                                <DefaultButton
                                    color={'success'}
                                    disabled={isSubmitting}
                                    type="submit">
                                    Save
                                </DefaultButton>
                            </Stack>
                        </Stack>
                        {
                            deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={deleteMessage}
                                updateConfirmAlert={(e, value) => {
                                    console.log(e, value)
                                    setDeleteConfirmationShouldOpen(false)
                                    updateConfirmAlert(value)
                                }} />)
                        }
                        <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </form>
                )}
            </Formik>
        </Popup>
    );
};
