export const arrayStatus = [
    { id: "1", value: "In Service" },
    { id: "2", value: "Inspection" },
    { id: "3", value: "Storage" },
    { id: "4", value: "Repair" },
    { id: "5", value: "Transit" },
]

export const arrayInventoryStatus = [
    { id: "1", value: "Ready" },
    { id: "2", value: "Inspection" },
    { id: "3", value: "In Transit" },
    { id: "4", value: "In Service" },
    { id: "5", value: "In Storage" },
]

export const arrayTransferStatus = [
    { id: "1", value: "Pending" },
    { id: "2", value: "Completed" },
    { id: "3", value: "Decline" },
]

export const arrayTransferAction = [
    { id: "1", value: "Site Transfer" },
    { id: "2", value: "Site/End User" },
]
