import { Stack } from "@mui/material"
import { DefaultIconButton } from "../DefaultIconButton"
import { Tune } from "@mui/icons-material"
import { DropDown } from "../DefaultSelect/DropDown"
export const Filters = (props) => {
    const handleSubmit = (e) => {
        e.preventDefault()
    }

    return (
        <Stack direction={'row'} sx={{
            bgcolor: 'blue.light',
            borderRadius: '5px',
            maxWidth: '300px',
            width: props.width ? props.width : '100%',
            overflow: 'hidden',
            alignItems: 'center',
            height: 'auto',
        }}
            component={'form'}
            onSubmit={handleSubmit}
        >
            {/* <DefaultIconButton
                icon={<Tune />}
                type='submit'
                size={'100%'}
                sx={{
                    width: '45px',
                    borderRadius: 0,
                    position: 'relative',
                    color: 'primary.main',
                }}
            /> */}

            <DropDown
                // placeholder={'Search for tracker ID, asset name, contact or other details…'}
                sx={{
                    minWidth: 'unset',
                    width: '100%',
                    '.MuiInputBase-root::before': {
                        display: 'none'
                    },
                    '.MuiInputBase-root': {
                        height: '45px',
                    },
                    '.MuiInputBase-input': {
                        px: '20px',
                        '&::placeholder': {
                            fontWeight: 700
                        }
                    }
                }}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.handleFilerChange}>
                {props.children}
            </DropDown>
        </Stack>
    )
}
