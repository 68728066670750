import styles from './index.module.scss';
import { Box, Grid, Stack, Switch, Typography, Backdrop, CircularProgress } from '@mui/material';
import { Title } from '../../ui/Title/index.js';
import { DefaultInput } from '../../ui/DefaultInput/index.js';
import { ScrollContent } from '../../ui/ScrollContent/index.js';
import { DefaultButton } from '../../ui/DefaultButton/index.js';
import { DefaultSelect } from '../../ui/DefaultSelect/index.js';
import React from 'react';
import { enqueueSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Formik } from 'formik';
import api from '../../lib/axios';
import { ConfirmAlert } from '../../customControls/confirmAlert';
import { arrayProviderType } from './staticData/providersData';
class SettingsProviderDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedDataId: "",
            providerName: "",
            description: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zipCode: "",
            selectedProviderType: null,
            contactName: "",
            mobile: "",
            email: "",
            status: "1",
            deleteConfirmationShouldOpen: false
        }
    }
    componentDidMount() {
        const providerID = this.props.providerID
        this.setState({
            selectedDataId: providerID,
        }, () => {
            if (this.state.selectedDataId != "") {
                this.getData()
            }
        })
    }

    getData() {
        this.setState({
            isLoading: true,
        })
        api.get('/apis/Providers/' + this.state.selectedDataId).then((res) => {
            if (res.data.success) {
                var data = res.data.data
                var selectedType = arrayProviderType.filter((Type) => Type.id == data.providerType)
                if (selectedType && selectedType.length > 0) {
                    selectedType = selectedType[0]
                } else {
                    selectedType = null
                }
                console.log("got data", data)
                this.setState({
                    providerName: data.providerName,
                    description: data.description,
                    address1: data.address1,
                    address2: data.address2,
                    city: data.city,
                    state: data.state,
                    zipCode: data.zipCode,
                    selectedProviderType: selectedType,
                    contactName: data.contactName,
                    mobile: data.mobile,
                    email: data.email,
                    status: data.status,
                    isLoading: false,
                    deleteConfirmationShouldOpen: false
                })
                console.log("state data", this.state)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }


    apiCalling = async () => {
        var data = {
            providerName: this.state.providerName,
            description: this.state.description,
            address1: this.state.address1,
            address2: this.state.address2,
            city: this.state.city,
            state: this.state.state,
            zipCode: this.state.zipCode,
            providerType: this.state.selectedProviderType.id,
            contactName: this.state.contactName,
            mobile: this.state.mobile,
            email: this.state.email,
            status: this.state.status
        }
        if (this.state.selectedDataId != "") {
            return await api.put('/apis/Providers/' + this.state.selectedDataId, data)
        } else {
            return await api.post('/apis/Providers/', data)
        }

    }
    deleteRecord = () => {
        console.log("deleteRecord")
        api.delete('/apis/Providers/' + this.state.selectedDataId).then((res) => {
            if (res.data.success) {

                enqueueSnackbar('Provider deleted', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                this.props.closeDetails()

            } else {
                this.setState({ deleteConfirmationShouldOpen: false });
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });

            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    updateConfirmAlert = (value) => {
        if (value) {
            this.deleteRecord()
        } else {
            this.setState({ deleteConfirmationShouldOpen: false })
        }
    }
    formatPhoneNumber = (value) => {
        if (!value) return value;
        const numericValue = value.replace(/\D/g, '');

        if (numericValue.length <= 3) {
            return numericValue;
        } else if (numericValue.length <= 6) {
            return `${numericValue.slice(0, 3)}-${numericValue.slice(3)}`;
        } else {
            return `${numericValue.slice(0, 3)}-${numericValue.slice(3, 6)}-${numericValue.slice(6, 10)}`;
        }
    };

    render() {
        return (
            <>
                <Box
                    sx={{
                        borderLeft: '1px solid black',
                        borderColor: 'grey.light',
                        backgroundColor: 'common.white',
                    }}
                    className={styles.settingsDetails}
                >
                    <ScrollContent>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                submit: null,
                                providerName: this.state.providerName,
                                description: this.state.description,
                                address1: this.state.address1,
                                address2: this.state.address2,
                                city: this.state.city,
                                state: this.state.state,
                                zipCode: this.state.zipCode,
                                selectedProviderType: this.state.selectedProviderType,
                                contactName: this.state.contactName,
                                mobile: this.state.mobile,
                                email: this.state.email,

                            }}
                            validationSchema={Yup
                                .object()
                                .shape({
                                    providerName: Yup.string().required('Prover name is required'),
                                    description: Yup.string().required('Description is required'),
                                    address1: Yup.string().required('Address1 is required'),
                                    address2: Yup.string().required('Address2 is required'),
                                    city: Yup.string().required('City is required'),
                                    state: Yup.string().required('State is required'),
                                    zipCode: Yup.string().required('Zip code is required'),
                                    selectedProviderType: Yup.object().required('Provider type is required'),
                                    contactName: Yup.string().required('Contact name is required'),
                                    mobile: Yup.string().required('Cellular is required'),
                                    email: Yup.string().required('Email is required').email('Must be a valid email'),
                                })}
                            onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                                console.log("values::", values)
                                try {
                                    this.setState({
                                        providerName: values.providerName,
                                        description: values.description,
                                        address1: values.address1,
                                        address2: values.address2,
                                        city: values.city,
                                        state: values.state,
                                        zipCode: values.zipCode,
                                        selectedProviderType: values.selectedProviderType,
                                        contactName: values.contactName,
                                        mobile: values.mobile,
                                        email: values.email,
                                        isLoading: true,
                                    }, async () => {
                                        console.log(this.state)

                                        var data = await this.apiCalling()
                                        this.setState({ isLoading: false })
                                        if (data !== undefined && data.data.success) {
                                            resetForm();
                                            setStatus({ success: true });
                                            setSubmitting(false);

                                            enqueueSnackbar('Provider ' + (this.state.selectedDataId == "" ? "added" : "updated"), {
                                                anchorOrigin: {
                                                    horizontal: 'right',
                                                    vertical: 'top'
                                                },
                                                variant: 'success'
                                            });
                                            this.props.closeDetails()
                                        } else {
                                            setStatus({ success: false });
                                            setErrors({ submit: data.data.message });
                                            setSubmitting(false);
                                            enqueueSnackbar(data.data.message, {
                                                anchorOrigin: {
                                                    horizontal: 'right',
                                                    vertical: 'top'
                                                },
                                                variant: 'error'
                                            });
                                        }
                                    })
                                } catch (err) {
                                    console.error(err);
                                    setStatus({ success: false });
                                    setErrors({ submit: err.message });
                                    setSubmitting(false);
                                }
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                                <form
                                    onSubmit={handleSubmit}
                                    {...this.props}
                                >
                                    <Stack p={'35px 50px'} spacing={4}>
                                        <Title>Details</Title>
                                        <Box>
                                            <Grid container spacing={4}>
                                                <Grid item xs={6}>
                                                    <DefaultInput
                                                        label={'Provider Name'}
                                                        placeholder={'E.g. Storage Yard'}
                                                        value={values.providerName}
                                                        name={"providerName"}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={Boolean(touched.providerName && errors.providerName)}
                                                        helperText={touched.providerName && errors.providerName}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <DefaultInput
                                                        label={'Description'}
                                                        placeholder={'E.g. Tool rentals and services…'}
                                                        value={values.description}
                                                        name={"description"}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={Boolean(touched.description && errors.description)}
                                                        helperText={touched.description && errors.description}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <DefaultInput
                                                        label={'Address 1'}
                                                        placeholder={'E.g. 123 Main'}
                                                        value={values.address1}
                                                        name={"address1"}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={Boolean(touched.address1 && errors.address1)}
                                                        helperText={touched.address1 && errors.address1}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <DefaultInput
                                                        label={'Address 2'}
                                                        placeholder={'E.g. Suite 2500'}
                                                        value={values.address2}
                                                        name={"address2"}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={Boolean(touched.address2 && errors.address2)}
                                                        helperText={touched.address2 && errors.address2}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <DefaultInput
                                                        label={'City'}
                                                        placeholder={'E.g. Odessa'}
                                                        value={values.city}
                                                        name={"city"}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={Boolean(touched.city && errors.city)}
                                                        helperText={touched.city && errors.city}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <DefaultInput
                                                        label={'State'}
                                                        placeholder={'E.g. Texas'}
                                                        value={values.state}
                                                        name={"state"}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={Boolean(touched.state && errors.state)}
                                                        helperText={touched.state && errors.state}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <DefaultInput
                                                        label={'Zip Code'}
                                                        placeholder={'E.g. 77078'}
                                                        value={values.zipCode}
                                                        name={"zipCode"}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={Boolean(touched.zipCode && errors.zipCode)}
                                                        helperText={touched.zipCode && errors.zipCode}
                                                    />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <DefaultSelect
                                                        onBlur={handleBlur}
                                                        onChange={(event, newValue) => {
                                                            values.selectedProviderType = newValue
                                                            console.log("st", newValue)
                                                        }}
                                                        helperText={touched.selectedProviderType && errors.selectedProviderType && <p>Select provider type</p>}
                                                        error={Boolean(touched.selectedProviderType && errors.selectedProviderType)}
                                                        value={values.selectedProviderType}
                                                        options={arrayProviderType}
                                                        getOptionLabel={(option) => option.value}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        name="selectedProviderType"
                                                        label={"Provider Type"}
                                                        placeholder="Select Provider Type" />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <DefaultInput
                                                        label={'Contact Name'}
                                                        placeholder={'E.g. bob'}
                                                        value={values.contactName}
                                                        name={"contactName"}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={Boolean(touched.contactName && errors.contactName)}
                                                        helperText={touched.contactName && errors.contactName}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    {/* <DefaultInput
                                                        label={'Cellular'}
                                                        placeholder={'000-000-0000'}

                                                        value={values.mobile}
                                                        name={"mobile"}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={Boolean(touched.mobile && errors.mobile)}
                                                        helperText={touched.mobile && errors.mobile}
                                                    /> */}
                                                    <DefaultInput
                                                        label={'Cellular'}
                                                        placeholder={'000-000-0000'}
                                                        value={this.formatPhoneNumber(values.mobile)}
                                                        name={"mobile"}
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            const formattedValue = this.formatPhoneNumber(e.target.value);
                                                            setFieldValue('mobile', formattedValue);
                                                        }}
                                                        error={Boolean(touched.mobile && errors.mobile)}
                                                        helperText={touched.mobile && errors.mobile}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <DefaultInput
                                                        label={'Email'}
                                                        placeholder={'E.g. bob@email.com'}

                                                        value={values.email}
                                                        name={"email"}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={Boolean(touched.email && errors.email)}
                                                        helperText={touched.email && errors.email}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Stack
                                                        height={'100%'}
                                                        justifyContent={'space-between'}
                                                    >
                                                        <Stack
                                                            direction={'row'}
                                                            alignItems={'center'}
                                                        >
                                                            <Typography>Status</Typography>
                                                            <Switch
                                                                sx={{
                                                                    ml: 2,
                                                                    '.MuiSwitch-thumb': {
                                                                        backgroundColor:
                                                                            'green.main',
                                                                    },
                                                                    '& .Mui-checked+.MuiSwitch-track':
                                                                    {
                                                                        backgroundColor:
                                                                            'grey.blue',
                                                                    },
                                                                }}
                                                                checked={this.state.status === "1"}
                                                                onChange={(event) => {
                                                                    const checked = event.target.checked;

                                                                    this.setState({
                                                                        status: checked ? "1" : "2",
                                                                    })


                                                                }}
                                                            />
                                                        </Stack>
                                                        <Typography lineHeight={1}>
                                                            {this.state.status == "1" ? "Active" : "Inactive"}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Stack>
                                    <Stack
                                        p={'35px 50px'}
                                        direction={'row'}
                                        justifyContent={'space-between'}
                                        mt={'auto'}
                                    >
                                        {this.state.selectedDataId != "" ? <DefaultButton onClick={() => {
                                            this.setState({
                                                deleteConfirmationShouldOpen: true,

                                            })

                                        }}
                                        color={'error'}>Delete</DefaultButton> : <Box> </Box>}
                                        <Stack direction={'row'} spacing={2}>
                                            <DefaultButton
                                                onClick={this.props.closeDetails}
                                            >
                                                Cancel
                                            </DefaultButton>
                                            <DefaultButton
                                               color={'success'}
                                                disabled={isSubmitting}
                                                type="submit"
                                            >
                                                {this.state.selectedDataId == "" ? "Add" : "Update"}
                                            </DefaultButton>
                                        </Stack>
                                    </Stack>
                                </form>
                            )}
                        </Formik>
                    </ScrollContent>
                    {this.state.deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                        updateConfirmAlert={(e, value) => {
                            console.log(e, value)
                            this.updateConfirmAlert(value)
                        }} />)}
                </Box>
                <Backdrop style={{ zIndex: 1 }} open={this.state.isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        );
    }
}

export default SettingsProviderDetails;
