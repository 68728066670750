import React, { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';

import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';

import { useNavigate, useLocation } from 'react-router-dom';



function Loadable(Component) {
  const InjectedNavigate = function (props) {
    const navigate = useNavigate(props);
    const location = useLocation(props);
    return (<Suspense fallback={<LoadingScreen />}>
      <Component {...props} navigate={navigate} location={location} />
    </Suspense>)
  };
  return InjectedNavigate;
}

import { Login } from "./views/Login/Login";
// const Login = Loadable(lazy(() => import('./views/Login/Login')));
const GuestAccess = Loadable(lazy(() => import('./views/GuestAccess/GuestAccess')));
const ForgotPassword = Loadable(lazy(() => import('./views/Forgot/Forgot')));
const ResetPassword = Loadable(lazy(() => import('./views/ResetPassword/ResetPassword')));
const ChangePassword = Loadable(lazy(() => import('./views/ChangePassword/ChangePassword')));
const EditProfile = Loadable(lazy(() => import('./views/EditProfile/EditProfile')));
const UserList = Loadable(lazy(() => import("./views/Settings/SettingsUsers.jsx")));
const SettingsCompany = Loadable(lazy(() => import("./views/Settings/SettingsCompany")));
const SiteList = Loadable(lazy(() => import("./views/Settings/SettingsSites.jsx")));
const SettingsZones = Loadable(lazy(() => import("./views/Settings/SettingsZones.jsx")));
const SettingsConfiguration = Loadable(lazy(() => import("./views/Settings/SettingsConfiguration")));
const SettingsTrackers = Loadable(lazy(() => import("./views/Settings/SettingsTrackers")));

import { Inventory } from "./views/Inventory/Inventory";
import { InventoryDetails } from "./views/InventoryDetails/InventoryDetails";
import SettingsProviders from './views/Settings/SettingsProviders';
import { PipeTally } from './views/PipeTally';
import { PipeTallyDetail } from './views/PipeTallyDetail';
import { LiveTracking } from "./views/LiveTracking/LiveTracking";
import { MapView } from './views/MapView';
import { PipeProfilesDetails } from './views/PipeProfileDetail/PipeProfileDetail';
import { PipeProfiles } from './views/PipeProfile/PipeProfiles';

const Inspections = Loadable(lazy(() => import("./views/Inspections/Inspections")));
const InspectionsCreate = Loadable(lazy(() => import("./views/InspectionsCreate/InspectionsCreate")));
const InspectionsEdit = Loadable(lazy(() => import("./views/InspectionsEdit/InspectionsEdit")));

// const MapView = Loadable(lazy(() => import("./views/MapView/MapView")));


const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'guestAccess',
        element: (
          <GuestGuard>
            <GuestAccess />
          </GuestGuard>
        )
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />
      },
      {
        path: 'reset-password',
        element: <ResetPassword />
      },
      {
        path: 'login-unguarded',
        element: <Login />
      }
    ]
  },
  {
    path: 'settings',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/settings/users',
        element: <UserList />
      },
      {
        path: '/settings/company',
        element: <SettingsCompany />
      },
      {
        path: '/settings/sites',
        element: <SiteList />
      },
      {
        path: '/settings/zones',
        element: <SettingsZones />
      },
      {
        path: '/settings/providers',
        element: <SettingsProviders />
      },
      {
        path: '/settings/configuration',
        element: <SettingsConfiguration />
      },
      {
        path: '/settings/trackers',
        element: <SettingsTrackers />
      },
    ]
  },
  {
    path: 'profile',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/profile/editProfile',
        element: <EditProfile />
      },
      {
        path: '/profile/changePassword',
        element: <ChangePassword />
      },
    ]
  },
  {
    path: 'inventory',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/inventory',
        element: <Inventory />
      },
      {
        path: '/inventory/InventoryDetail',
        element: <InventoryDetails />
      },
    ]
  },
  {
    path: 'live-tracking',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/live-tracking',
        element: <LiveTracking />
      },
    ]
  },
  {
    path: 'mapView',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/mapView',
        element: <MapView />
      },
    ]
  },
  {
    path: 'pipe-tally',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/pipe-tally',
        element: <PipeTally />
      },
      {
        path: '/pipe-tally/PipeTallyDetail',
        element: <PipeTallyDetail />
      },
    ]
  },
  {
    path: 'inspections',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/inspections',
        element: <Inspections />
      },
      {
        path: '/inspections/create',
        element: <InspectionsCreate />
      },
      {
        path: '/inspections/edit',
        element: <InspectionsEdit />
      },
    ]
  },
  {
    path: 'pipe-profiles',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/pipe-profiles',
        element: <PipeProfiles />
      },
      {
        path: '/pipe-profiles/PipeProfileDetail',
        element: <PipeProfilesDetails />
      },
    ]
  },
  // {
  //   path: 'map-view',
  //   element: (
  //     <AuthGuard>
  //       <DashboardLayout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: '/map-view',
  //       element: <MapView />
  //     },
  //   ]
  // },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Inventory />
      },
    ]
  },
];

export default routes;
