export const MapViewIcon = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            data-name="Group 40856"
            viewBox="0 0 24 24"
            {...props}
        >
            <defs>
                <clipPath id="clip-path-map">
                    <path
                        fill="none"
                        d="M0 0H24V24H0z"
                        data-name="Rectangle 14393"
                    ></path>
                </clipPath>
            </defs>
            <g
                fill="none"
                stroke="#163567"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                clipPath="url(#clip-path-map)"
                data-name="Group 40855"
            >
                <path
                    d="M.5 1.943v16.939a1 1 0 00.553.894l6.968 3.485a1 1 0 00.944-.026l6.07-3.469a1 1 0 01.944-.027l6.074 3.037a1 1 0 001.447-.894V4.193a1 1 0 00-.649-.936L15.876.641a1 1 0 00-.745.017L8.869 3.342a1 1 0 01-.745.017L1.851 1.007A1 1 0 00.5 1.943z"
                    data-name="Path 18545"
                ></path>
                <path
                    d="M0 20L0 0"
                    data-name="Line 624"
                    transform="translate(8.5 3.5)"
                ></path>
                <path
                    d="M0 19L0 0"
                    data-name="Line 625"
                    transform="translate(15.5 .5)"
                ></path>
            </g>
        </svg>
    );
};
