export const arrayStatus = [
    { id: "3", value: "New" },
    { id: "0", value: "In Progress" },
    { id: "1", value: "On pause" },
    { id: "2", value: "Completed" },
]

export const getPipeTallyStatusColor = (code) => {
    switch (code) {
        case '0':
            return '#0084FF';
        case '1':
            return '#FEBC2E';
        case '2':
            return '#24D5AA';
        case '3':
            return '#262B40';
        default:
            return '#24D5AA';
    }
};

export const arrayType = [
    { id: "0", value: "Outbound Tally" },
    { id: "1", value: "Inbound Tally" },
]