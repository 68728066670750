import React from 'react';
import { Box } from '@mui/material';
import Logo from '../assets/images/logo.svg';

const SlashScreen = () => (
  <Box
    sx={{
      alignItems: 'center',
      backgroundColor: '#ffffff',//'#172b4d',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      left: 0,
      p: 3,
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 2000
    }}
  >
    {/* <Logo /> */}
    <img
      src={Logo}
      alt="PipeDNA Logo"
      height="70"
    />
  </Box>
);

export default SlashScreen;
