import { Header } from './components/Header';
import { Sidebar } from './components/Sidebar';
import useAuth from './hooks/useAuth';
import { useRoutes, useLocation } from 'react-router-dom';
import SplashScreen from './components/SplashScreen';
import routes from './routes';
import { SnackbarProvider } from 'notistack';
import { CompanyProvider } from './contexts/CompanyContext';

export const App = () => {
    const location = useLocation();
    const content = useRoutes(routes);
    const auth = useAuth();
    return (
        <div className={'app'}>
            {!location.pathname.includes('authentication') && <Sidebar />}
            <div className={'app-inner'}>
                <CompanyProvider>
                    {!location.pathname.includes('authentication') && <Header />}
                    <main className={'main'}>
                        <SnackbarProvider
                            dense
                            maxSnack={3}
                        >
                            {auth.isInitialized ? content : <SplashScreen />}
                        </SnackbarProvider>
                    </main>
                </CompanyProvider>
            </div>
        </div>
    );
};
