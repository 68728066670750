import { InventoryIcon } from '../../assets/icons/InventoryIcon';
import { Metrics } from '../../components/Metrics';
import { SearchInput } from '../../components/SearchInput';
import { CircleButton } from '../../ui/CircleButton';
import { Filters } from '../../ui/Filters';
import { Box, Stack, Backdrop, CircularProgress, MenuItem, Grid, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import api from '../../lib/axios';
import { arraySalesOrderStatus, getSalesOrderStatusColor } from './data/LiveTrakingData';
import { SidebarItem } from './SidebarItem';
import { Map } from './Map';
import { TrackingDetail } from './TrackingDetail';
import { TrackingNew } from './TrackingNew';
import { ConfirmAlert } from '../../customControls/confirmAlert';
import { enqueueSnackbar } from 'notistack';
import { DetailMap } from './DetailMap';
import { DefaultDataGrid } from '../../ui/DefaultDataGrid';
import moment from 'moment';

export const LiveTracking = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);
    const [isEditDetail, setIsEditDetail] = useState(false);
    const [isShowDetail, setIsShowDetail] = useState(false);
    const [selectedDataId, setSelectedDataId] = useState("0");
    const [arrayData, setArrayData] = useState([]);
    const [query, setQuery] = useState("");
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const [arrayOD, setArrayOD] = useState([]);
    const [arrayWallThickness, setArrayWallThickness] = useState([]);
    const [arrayGrade, setArrayGrade] = useState([]);
    const [arrayConnection, setArrayConnection] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(-1)
    const [selectedOD, setSelectedOD] = useState(-1)
    const [selectedWallThick, setSelectedWallThick] = useState(-1)
    const [selectedGrade, setSelectedGrade] = useState(-1)
    const [selectedConnection, setSelectedConnection] = useState(-1)
    const [objLoad, setObjLoad] = useState(null);
    const [metrics1, setMetrics1] = useState([
        {
            title: 'Shipped',
            value: 0,
            color: 'green.main',
        },
        {
            title: 'Ready',
            value: 0,
            color: 'blue.main',
        },
        {
            title: 'Pending',
            value: 0,
            color: 'primary.main',
        },
    ]);
    const [metrics2, setMetrics2] = useState([
        {
            title: 'Sale Orders',
            value: '0',
        },
    ]);
    const columns = [
        {
            field: 'order',
            headerName: 'Order',
            flex: 1,
            renderCell: ({ value }) => (
                <Typography fontSize={14} fontWeight={500} fontFamily={'Roboto'}>{value}</Typography>

            ),
        },
        {
            field: 'createdAt',
            headerName: 'Date',
            width: 90,
            renderCell: ({ value }) => (
                <Typography fontSize={13}>{moment(value).format("mm/DD/YY")}</Typography>

            ),
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 110,
            renderCell: (params) => (

                <Box px={1} py={0.2} flex={1} width={100} border={'1px solid'} borderColor={getSalesOrderStatusColor(params.row.status)} borderRadius={'5px'} overflow={'hidden'}>
                    <Typography fontSize={13} textAlign="center">{arraySalesOrderStatus.find(status => status.id == params.row.status)?.value}</Typography>
                </Box>

            ),
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: 'selectedBox',
            headerName: '',
            width: 30,
            renderCell: (params) => selectedDataId == params.row._id ? (

                <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                    <Box position={'absolute'} right={-7} top={0} bottom={0} bgcolor={'secondary.main'} width={6} />
                </div>

            ) : null,
            sortable: false,
            disableColumnMenu: true,
        }
    ];
    useEffect(() => {
        getFilterData()
    }, []);

    function getFilterData() {
        setIsLoading(true)
        api.get('/apis/Inventories/filterDetail').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayOD(resData.arrayOD)
                setArrayWallThickness(resData.arrayWallThick)
                setArrayGrade(resData.arrayGrade)
                setArrayConnection(resData.arrayConnection)
                // setIsLoading(false)
            }
            getData(selectedOD, selectedWallThick, selectedGrade, selectedConnection, selectedStatus)
        }).catch(function (error) {
            console.log(error);
        });
    }

    function getData(selectedOD, selectedWallThick, selectedGrade, selectedConnection, selectedStatus) {
        setIsLoading(true)
        var data = {
            od: selectedOD != -1 ? selectedOD : "",
            wallThickness: selectedWallThick != -1 ? selectedWallThick : "",
            grade: selectedGrade != -1 ? selectedGrade : "",
            connection: selectedConnection != -1 ? selectedConnection : "",
            status: selectedStatus != -1 ? selectedStatus : ""
        }
        api.post('/apis/SalesOrders/getAll', data).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var filterArray = applyFilters(resData, query)
                // if (selectedDataID == "") {
                //     setSelectedDataId(resData.length > 0 ? resData[0]._id : "")
                // }
                var totalShipped = resData.filter(order => order.status == "3").length
                var totalPending = resData.filter(order => order.status == "1").length
                var totalReady = resData.filter(order => order.status == "2").length
                var matrix1 = [
                    {
                        title: 'Shipped',
                        value: totalShipped,
                        color: 'green.main',
                    },
                    {
                        title: 'Ready',
                        value: totalReady,
                        color: 'blue.main',
                    },
                    {
                        title: 'Pending',
                        value: totalPending,
                        color: 'primary.main',
                    },
                ]
                setMetrics1(matrix1)
                var matrix2 = [
                    {
                        title: 'Sale Orders',
                        value: resData.length,
                    },
                ]
                setMetrics2(matrix2)
                setFilteredArrayData(filterArray)
                setArrayData(resData)
                setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    function applyFilters(arrayDataAll, query) {
        var finalArray = []
        var filteredArraySearch = arrayDataAll.filter((dataObj) => {
            let matches = true;

            const properties = ['order'];
            let containsQuery = false;

            properties.forEach((property) => {
                var str = getProperty(dataObj, property) + "";
                if (str.toLowerCase().includes(query.toLowerCase())) {
                    containsQuery = true;
                }
            });
            if (!containsQuery) {
                matches = false;
            }
            return matches;
        });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : [];
        if (query === "") {
            return arrayDataAll;
        }
        return finalArray;
    }

    function handleQueryChange(event) {
        setQuery(event.target.value)
        var filterArray = applyFilters(arrayData, event.target.value)
        setFilteredArrayData(filterArray)
    }

    function handleFilterChange(selectedOD, selectedWallThick, selectedGrade, selectedConnection, selectedStatus) {
        getData(selectedOD, selectedWallThick, selectedGrade, selectedConnection, selectedStatus)
    }

    function getProperty(obj, prop) {
        const props = prop.split('.');
        return props.reduce((o, p) => o && o[p], obj);
    }

    function deleteRecord() {
        setIsLoading(true)
        api.delete('/apis/SalesOrders/deleteById/' + selectedDataId).then((res) => {
            if (res.data.success) {
                setSelectedDataId("0")
                setIsShowDetail(false)
                setIsEditDetail(false)

                enqueueSnackbar("Sales order deleted", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                getData(selectedOD, selectedWallThick, selectedGrade, selectedConnection, selectedStatus)
            } else {
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateConfirmAlert = (value) => {
        if (value) {
            deleteRecord()
        }
    }

    return (
        <Stack py={2} px={2.5} width={'100%'} height='100%'>
            <Stack direction={'row'} gap={4} rowGap={2} flexWrap={'wrap'}>
                <Metrics icon={<InventoryIcon />} data={metrics2} />
                <Metrics data={metrics1} />
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mt={2}>
                <Stack direction={'row'} spacing={2}>
                    <SearchInput handleQueryChange={handleQueryChange} />
                    <Filters
                        handleFilerChange={(event) => {
                            var od = event.target.value
                            setSelectedOD(od)
                            handleFilterChange(od, selectedWallThick, selectedGrade, selectedConnection, selectedStatus)
                        }}
                        value={selectedOD}
                        name="selectedOD"
                        placeholder="O.D."
                        width="10vw"
                    >
                        {arrayOD.map((item, index) => (
                            <MenuItem key={index} value={item}> {item}</MenuItem>
                        ))}
                    </Filters>

                    <Filters
                        handleFilerChange={(event) => {
                            var wallThick = event.target.value
                            setSelectedWallThick(wallThick)
                            handleFilterChange(selectedOD, wallThick, selectedGrade, selectedConnection, selectedStatus)
                        }}
                        value={selectedWallThick}
                        name="selectedWallThick"
                        placeholder="Wall. Thick."
                        width="10vw"
                    >
                        {arrayWallThickness.map((item, index) => (
                            <MenuItem key={index} value={item}> {item}</MenuItem>
                        ))}
                    </Filters>

                    <Filters
                        handleFilerChange={(event) => {
                            var grade = event.target.value
                            setSelectedGrade(grade)
                            handleFilterChange(selectedOD, selectedWallThick, grade, selectedConnection, selectedStatus)
                        }}
                        value={selectedGrade}
                        name="selectedGrade"
                        placeholder="Grade"
                        width="10vw"
                    >
                        {arrayGrade.map((item, index) => (
                            <MenuItem key={index} value={item}> {item}</MenuItem>
                        ))}
                    </Filters>

                    <Filters
                        handleFilerChange={(event) => {
                            var connection = event.target.value
                            setSelectedConnection(connection)
                            handleFilterChange(selectedOD, selectedWallThick, selectedGrade, connection, selectedStatus)
                        }}
                        value={selectedConnection}
                        name="selectedConnection"
                        placeholder="Connection"
                        width="10vw"
                    >
                        {arrayConnection.map((item, index) => (
                            <MenuItem key={index} value={item}> {item}</MenuItem>
                        ))}
                    </Filters>

                    <Filters
                        handleFilerChange={(event) => {
                            var status = event.target.value
                            setSelectedStatus(status)
                            handleFilterChange(selectedOD, selectedWallThick, selectedGrade, selectedConnection, status)
                        }}
                        value={selectedStatus}
                        name="selectedStatus"
                        placeholder="Status"
                        width="10vw"
                    >
                        {arraySalesOrderStatus.map((item, index) => (
                            <MenuItem key={index} value={item.id}> {item.value}</MenuItem>
                        ))}
                    </Filters>
                </Stack>
                <CircleButton onClick={() => {
                    setSelectedDataId("")
                    setObjLoad(null)
                    setIsEditDetail(true)
                    setIsShowDetail(true)
                }} />
            </Stack>

            <Stack mt={2} direction={'row'} border={'1px solid'} borderColor={'grey.light'} overflow={'hidden'} height={'calc(100vh - 260px)'}>

                {isShowDetail ?
                    (
                        <Stack flex={1} direction={'row'} display="flex" height={"100%"}>
                            {isEditDetail ?
                                <TrackingNew
                                    trackingID={selectedDataId}
                                    onDelete={(e) => {
                                        setDeleteConfirmationShouldOpen(true)
                                    }}
                                    closeDetails={(e) => {
                                        setIsEditDetail(false)
                                        if (selectedDataId == "") {
                                            setSelectedDataId("0")
                                            setIsShowDetail(false)
                                        }
                                        if (e) {
                                            getData(selectedOD, selectedWallThick, selectedGrade, selectedConnection, selectedStatus)
                                        }
                                    }} /> :
                                <TrackingDetail
                                    trackingID={selectedDataId}
                                    EditDetails={(e) => {
                                        setIsEditDetail(true)
                                    }}
                                    closeDetails={(e) => {
                                        setIsShowDetail(false)
                                    }}
                                    onChangeLoad={(e) => {
                                        setObjLoad(e)
                                    }}
                                />}
                            <DetailMap
                                truckData={objLoad}
                                sx={{ flex: 1 }} />
                        </Stack>

                    ) :
                    <Stack flex={1} direction={'row'} display="flex" height={"100%"}>
                        {arrayData.length > 0 && selectedDataId != "" && <Stack
                            width={350}
                            flexShrink={1}
                            display={"auto"}
                            height={"100%"}
                            borderRight={'1px solid'}
                            borderColor={'grey.light'}
                            sx={{
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '4px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    width: '4px',
                                    bgcolor: 'grey.400',
                                },
                            }}
                        >
                            <DefaultDataGrid
                                columns={columns}
                                rows={filteredArrayData}
                                disablePagination={filteredArrayData.length < 99 ? true : false}
                                sx={{
                                    '.MuiDataGrid-virtualScroller': {
                                        overflowY: 'auto',

                                        '&::-webkit-scrollbar': {
                                            width: '4px',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            background: '#f1f1f1',
                                            width: '4px',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            width: '4px',
                                            bgcolor: 'grey.400',
                                        },
                                    },
                                }}
                                onRowClick={(e) => {
                                    if (e.id == selectedDataId) {
                                        setSelectedDataId("0")
                                        if (isShowDetail) {
                                            setIsShowDetail(false)
                                        }
                                    } else {
                                        setSelectedDataId(e.id)
                                        if (!isShowDetail) {
                                            setIsShowDetail(true)
                                        }
                                    }
                                    if (isEditDetail) {
                                        setIsEditDetail(false)
                                    }
                                }} />
                        </Stack>
                        }
                        <Map
                            salesOrderID={selectedDataId}
                            showEditButton={arrayData.length > 0 && selectedDataId != "" && selectedDataId != "0"}
                            onDetail={(e) => {
                                setIsEditDetail(false)
                                setIsShowDetail(true)
                            }}
                            sx={{ flex: 1 }} />
                    </Stack>
                }
            </Stack>
            {
                deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                    updateConfirmAlert={(e, value) => {
                        console.log(e, value)
                        setDeleteConfirmationShouldOpen(false)
                        updateConfirmAlert(value)
                    }} />)
            }
            <Backdrop style={{ zIndex: 10 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack >

    );
};