import { DefaultDataGrid } from '../../ui/DefaultDataGrid';
import { Filters } from '../../ui/Filters';
import { Backdrop, Box, CircularProgress, Grid, IconButton, Stack, Typography, MenuItem, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import { Popup } from '../../components/Popup/index.js';
import { ColorStatus } from '../../ui/ColorStatus/index.js';
import { CircleButton } from '../../ui/CircleButton/index.js';
import { Close } from '@mui/icons-material';
import api from '../../lib/axios'
import { ArrowForwardIos } from '@mui/icons-material';
import QrCode from '../../assets/icons/QrCode.svg';
import { Line } from 'react-chartjs-2';
import { arrayStatus } from './data/InventoryData';
import { CategoryScale, Chart as ChartJS, Filler, Legend, LinearScale, LineElement, PointElement, Title, Tooltip, } from 'chart.js';
import { Tune } from "@mui/icons-material"
import { SearchInput } from '../../components/SearchInput';
import moment from 'moment';
import { arrayType } from '../PipeTally/data/PipeTallyData';
import { getHealth, getHealthColor } from '../InventoryDetails/data/InventoryData';
import { enqueueSnackbar } from 'notistack';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

export const Details = ({ inventoryID, disabled, onEditClick, objTally }) => {
    const [isOpened, setIsOpened] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [arrayAttribute, setArrayAttribute] = useState([]);
    const [arrayPipe, setArrayPipe] = useState([]);
    const [objPipeTally, setObjPipeTally] = useState({})
    const [objPipeProfile, setObjPipeProfile] = useState({})
    const [objLocation, setObjLocation] = useState({})
    const [selectedPipe, setSelectedPipe] = useState(null)
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(-1)
    const [query, setQuery] = useState("");
    const [label, setLabel] = useState([])
    const [wallThicknessData, setWallThicknessData] = useState([])
    const columns = [
        {
            field: 'empty',
            headerName: '',
            width: 0,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: 'joint',
            headerName: 'Joint',
            width: 50,
            renderCell: (params) => (
                <Typography fontWeight={700}>
                    {params.value ? params.value : '-'}
                </Typography>
            )
        },
        {
            field: 'length',
            headerName: 'Length(ft)',
            width: 90,
            renderCell: (params) => (
                <Typography>
                    {params.value ? params.value : '-'}
                </Typography>
            )
        },
        {
            field: 'serialId',
            headerName: 'Serial Number',
            width: 130,
            renderCell: (params) => (
                <Typography>
                    {params.value ? params.value : '-'}
                </Typography>
            )
        },
        {
            field: 'arrayIdentifier',
            headerName: 'Unique Identifier',
            width: 200,
            renderCell: (params) => (
                <Typography>
                    {params.row.arrayIdentifier && params.row.arrayIdentifier.length > 0 ? params.row.arrayIdentifier[0] : "-"}
                </Typography>
            ),
        },
        {
            field: 'icon',
            headerName: '',
            width: 100,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Stack alignItems={'flex-start'} width={'100%'} pr={2}>
                    <img
                        src={QrCode}
                        alt="QR Code"
                        width="24"
                        height="24"
                    />
                </Stack>
            ),
        },
        {
            field: 'arrayWallThickness',
            headerName: 'Wall Thick.',
            width: 100,
            renderCell: (params) => (
                <Typography>
                    {params.row.arrayWallThickness && params.row.arrayWallThickness.length > 0 ? params.row.arrayWallThickness[0].wallThickness : "-"}
                </Typography>
            )
        },
        {
            field: 'percentage',
            headerName: 'Health',
            width: 120,
            renderCell: ({ value }) => value ? (
                <Stack direction={'row'} spacing={1} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                    <Typography>{getHealth(value)}</Typography>
                    <Box
                        sx={{
                            width: 10,
                            height: 10,
                            borderRadius: '50%',
                            backgroundColor: getHealthColor(value),
                        }}
                    />
                </Stack>
            ) : "-",
        },
        {
            field: 'fullName',
            headerName: 'Scanned By',
            flex: 1,
        },
        {
            field: 'updatedAt',
            headerName: 'Scanned Date',
            flex: 1,
            valueFormatter: (params) => moment(params.value).format("MM-DD-YYYY hh:mm a"),
        },
        {
            field: 'arrowIcon',
            headerName: '',
            flex: 1,
            renderCell: () => (
                <Stack alignItems={'flex-end'} width={'100%'} pr={5}>
                    <ArrowForwardIos
                        sx={{
                            width: 18,
                            height: 18,
                        }}
                    />
                </Stack>
            ),
            sortable: false,
            disableColumnMenu: true,
        },
    ];

    const labels = label;
    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Dataset',
                data: wallThicknessData,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                tension: 0.3,
                fill: true,
            },
        ],
    };

    const config = {
        type: 'line',
        data: data,
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false,
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    useEffect(() => {
        // if (inventoryID != "") {
        //     getPipeTallyData()
        // }

        if (objTally._id != null) {
            setData(objTally)
        }

    }, [objTally]);

    function getPipeTallyData() {
        setIsLoading(true)
        api.get('/apis/Tallies/getDetailById/' + inventoryID).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setObjPipeTally(resData)
                setArrayAttribute(resData.pipeProfile.attributes)
                setObjPipeProfile(resData.pipeProfile)
                setObjLocation(resData.location)
                var pipeData = resData.pipes
                var indexCounter = 0;
                pipeData = pipeData.map(item => {
                    indexCounter++;
                    return { ...item, joint: indexCounter };
                });
                var filterArray = applyFilters(pipeData, "", selectedStatus)
                setFilteredArrayData(filterArray)
                setArrayPipe(pipeData)
                setIsLoading(false)
            }

        }).catch(function (error) {
            console.log(error);
        });
    }

    function setData(resData) {
        setObjPipeTally(resData)
        setArrayAttribute(resData.pipeProfile.attributes)
        setObjPipeProfile(resData.pipeProfile)
        setObjLocation(resData.location)
        var pipeData = resData.pipes
        var indexCounter = 0;
        pipeData = pipeData.map(item => {
            indexCounter++;
            return { ...item, joint: indexCounter };
        });
        var filterArray = applyFilters(pipeData, "", selectedStatus)
        setFilteredArrayData(filterArray)
        setArrayPipe(pipeData)
    }


    function getPipeData(pipeId, joint) {
        setIsLoading(true)
        api.get('/apis/Pipes/getById/' + pipeId).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                resData.joint = joint

                setSelectedPipe(resData)
                setWallThicknessData(resData.arrayWallThickness.map(data => data.wallThickness))
                const formatDate = resData.arrayWallThickness.map(data => data.date)
                const labels = formatDate.map(date => moment(date).format("MM-DD-YYYY"));
                setLabel(labels)
                setIsOpened(true)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }


    function applyFilters(arrayDataAll, query, selectedStatus) {
        var finalArray = []
        var filteredArraySearch = arrayDataAll.filter((dataObj) => {
            let matches = true;
            const properties = ['joint', 'length', 'serialId', 'health', 'fullName'];
            let containsQuery = false;
            properties.forEach((property) => {
                var str = getProperty(dataObj, property) + "";
                if (str.toLowerCase().includes(query.toLowerCase())) {
                    containsQuery = true;
                }
            });
            if (dataObj.arrayIdentifier && dataObj.arrayIdentifier.length > 0) {
                var str = dataObj.arrayIdentifier[0] + "";
                if (str.toLowerCase().includes(query.toLowerCase())) {
                    containsQuery = true;
                }
            }
            if (!containsQuery) {
                matches = false;
            }
            return matches;
        });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : [];
        if (selectedStatus !== null && selectedStatus !== -1) {
            var filteredArrayStats = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;
                    if (dataObj.status == selectedStatus) {
                        containsQuery = true;
                    }
                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                })
            finalArray = [...filteredArrayStats]
        }
        if (query === "" && selectedStatus === -1) {
            return arrayDataAll;
        }
        finalArray = finalArray.map((item, index) => ({ ...item, joint: index + 1 }));
        return finalArray;
    }
    function handleQueryChange(event) {
        setQuery(event.target.value)
        var filterArray = applyFilters(arrayPipe, event.target.value, selectedStatus)
        setFilteredArrayData(filterArray)
        console.log("Query", event.target.value)
        console.log("filterd", filteredArrayData)
    }
    function getProperty(obj, prop) {
        const props = prop.split('.');
        return props.reduce((o, p) => o && o[p], obj);
    }
    return (
        <>
            <Stack
                sx={{
                    pointerEvents: disabled && 'none',
                }}
                height={'100%'}
            >
                <Box p={2}>
                    <Grid container spacing={4}>
                        <Grid item xs={2}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Pipe Profile Description</Typography>
                                <Typography fontWeight={700}>{objPipeProfile.description}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Type</Typography>
                                <Typography fontWeight={700}>{arrayType.find(obj => obj.id == objPipeTally.type)?.value}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Purchase Order</Typography>
                                <Typography fontWeight={700}>{objPipeTally.purchaseOrder}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Site Location</Typography>
                                <Typography fontWeight={700}>{objLocation.siteName}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Pipe Name</Typography>
                                <Typography fontWeight={700}>{objPipeProfile.name}</Typography>

                            </Stack>
                        </Grid>
                        <Grid item xs={2}>
                            <Stack alignItems={'flex-end'}>
                                <Box>
                                    <Button
                                        sx={{
                                            minWidth: 100,
                                            textTransform: 'none',
                                            borderRadius: '5px',
                                            height: '40px',
                                            border: '0px solid black',
                                            backgroundColor: '#F5F7FB',
                                            fontSize: "13px",
                                            fontWeight: "normal",
                                            color: '#262B40',
                                            '&:hover': {
                                                bgcolor: '#F5F7FB',
                                            },
                                        }}
                                        startIcon={<Tune color='#262B40' />}
                                        onClick={(e) => {
                                            onEditClick()
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={5}>
                            <Stack spacing={1} direction={'row'}>
                                <SearchInput handleQueryChange={handleQueryChange} sx={{
                                    width: '100%',
                                }} />
                                <Box>
                                    <Filters handleFilerChange={(event) => {
                                        setSelectedStatus(event.target.value)
                                        var filterArray = applyFilters(arrayPipe, query, event.target.value)
                                        setFilteredArrayData(filterArray)
                                    }}
                                        value={selectedStatus}
                                        name="selectedStatus"
                                        placeholder="Status"
                                    >
                                        {arrayStatus.map((item, index) => (
                                            <MenuItem key={index} value={item.id}> {item.value}</MenuItem>
                                        ))}
                                    </Filters>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography color={'#A8A8A7'}>Updated:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography >{moment(objPipeTally.updatedAt).format("MM-DD-YYYY hh:mm a")}</Typography>
                        </Grid>
                    </Grid>

                </Box>
                <Box flex={1}>
                    <DefaultDataGrid columns={columns} rows={filteredArrayData} onRowClick={(selectedRow) => {
                        // setIsOpened(true)
                        if (selectedRow.row.pipeID) {
                            getPipeData(selectedRow.row.pipeID, selectedRow.row.joint)
                        } else {
                            enqueueSnackbar('Detail not found', {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'error'
                            });
                        }

                        // setSelectedPipe(selectedRow)
                        // setWallThicknessData(selectedRow.row.arrayWallThickness.map(data => data.wallThickness))
                        // const formatDate = selectedRow.row.arrayWallThickness.map(data => data.date)
                        // const labels = formatDate.map(date => moment(date).format("MM-DD-YYYY"));
                        // setLabel(labels)
                    }} />
                </Box>
                <Popup right={0} isOpened={isOpened} bgcolor={'blue.light'} width={930} p={2}>
                    <CircleButton
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            zIndex: 2,
                        }}
                        icon={<Close />}
                        onClick={() => setIsOpened(false)}
                    />
                    {selectedPipe && (
                        <Box display={'flex'} flexDirection={'row'} gap={4}>
                            <Stack flexGrow={1} spacing={2.5} position={'relative'}>
                                <Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <Stack spacing={2}>
                                                <Typography>Joint</Typography>
                                                <Typography fontSize={25} fontWeight={700}>{selectedPipe.joint || "-"}</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Stack spacing={2}>
                                                <Typography>Length (ft)</Typography>
                                                <Typography fontSize={25} fontWeight={700}>{selectedPipe.length || "-"}</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Stack spacing={2}>
                                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                                    <Typography>Health Rating</Typography>
                                                    <Box
                                                        sx={{
                                                            width: 10,
                                                            height: 10,
                                                            borderRadius: '50%',
                                                            backgroundColor: getHealthColor(selectedPipe.percentage),
                                                        }}
                                                    />
                                                </Stack>
                                                <Typography fontSize={25} fontWeight={700}>
                                                    {getHealth(selectedPipe.percentage)}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box
                                    width={'100%'}
                                    sx={{
                                        height: '1px',
                                        backgroundImage: 'linear-gradient(to right, #d5d5d5, #d5d5d5 60%, transparent 50%)',
                                        backgroundSize: '13px 1px',
                                    }}
                                />
                                <Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <Stack spacing={2}>
                                                <Typography>Serial</Typography>
                                                <Typography fontWeight={700}>{selectedPipe.serialId || "-"}</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Stack spacing={2}>
                                                <Typography>Heat</Typography>
                                                <Typography fontWeight={700}>{selectedPipe.heat || "-"}</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Stack spacing={2}>
                                                <Typography>Lot</Typography>
                                                <Typography fontWeight={700}>{selectedPipe.lot || "-"}</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Stack spacing={2}>
                                                <Typography>Status</Typography>
                                                <Typography fontWeight={700}>{arrayStatus.find(obj => obj.id == selectedPipe.status)?.value || "-"}</Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box
                                    width={'100%'}
                                    sx={{
                                        height: '1px',
                                        backgroundImage: 'linear-gradient(to right, #d5d5d5, #d5d5d5 60%, transparent 50%)',
                                        backgroundSize: '13px 1px',
                                    }}
                                />
                                <Box>

                                    <Grid container spacing={2}>
                                        {selectedPipe.pipeProfile.attributes.map((data, index) => {
                                            return (< Grid item xs={4} key={index}>
                                                <Stack spacing={2}>
                                                    <Typography>{data.title}</Typography>
                                                    <Typography fontWeight={700} color={data.id === -1 ? 'green.main' : '#262B40'}>{data.value !== null && data.value !== "" ? (data.id === 20 || data.id == -7 ? data.value + "%" : data.value) : "-"}</Typography>
                                                </Stack>
                                            </Grid>)
                                        })}
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={4}>
                                                    <Typography>Unique Identifier</Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography fontWeight={700}>{selectedPipe.arrayIdentifier.length > 0 ? selectedPipe.arrayIdentifier[0] : "-"}</Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <IconButton
                                                        sx={{
                                                            width: "40px",
                                                            height: "40px",
                                                            color: 'common.white',
                                                            backgroundColor: 'transparent',
                                                            '&:hover': {
                                                                backgroundColor: 'grey.blue',
                                                            },
                                                        }}
                                                    >
                                                        <img
                                                            src={QrCode}
                                                            alt="QR Code"
                                                        />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Stack>
                            <Stack spacing={1.5} width={400} flexShrink={0}>
                                <Stack spacing={1}>
                                    <Typography>Remaining Body Wall</Typography>
                                    <Box height={190}>
                                        <Line data={data} options={config} />
                                    </Box>
                                </Stack>
                                <Stack spacing={1}>
                                    <Typography fontWeight={700}>Inspection data</Typography>
                                    <Box px={3} py={2} bgcolor={'common.white'} borderRadius={'10px'}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Typography>Wall Thickness Value</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography>Date/Time</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {selectedPipe.arrayWallThickness.map((item, index) => (
                                                    <Typography key={index} fontWeight={700} marginTop={1}>{item.wallThickness}</Typography>
                                                ))}
                                            </Grid>
                                            <Grid item xs={6}>
                                                {selectedPipe.arrayWallThickness.map((item, index) => (
                                                    <Typography key={index} marginTop={1}>{moment(item.date).format("MM-DD-YYYY hh:mm a")}</Typography>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Box>
                    )}
                </Popup>
                <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Stack>
        </>
    );
};
