export const PipeProfilesIcon = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={22.244}
            height={23.976}
            {...props}
        > 
            <defs>
                <clipPath id="b">
                    <path
                        fill="none"
                        d="M0 0h22.244v23.976H0z"
                        data-name="Rectangle 13086"
                    />
                </clipPath>
            </defs>
            <g data-name="Group 37930">
                <g
                    fill="none"
                    stroke="#163567"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    clipPath="url(#b)"
                    data-name="Group 37929"
                >
                    <path
                        d="M21.336 14.6a1 1 0 0 1 0 1.611l-9.622 7.07a1 1 0 0 1-1.184 0L.908 16.212a1 1 0 0 1 0-1.611"
                        data-name="Path 17407"
                    />
                    <path
                        d="M.908 11.183a1 1 0 0 0 0 1.611l.944.7 8.678 6.375a1 1 0 0 0 1.184 0l8.678-6.375.945-.7a1 1 0 0 0 0-1.611"
                        data-name="Path 17408"
                    />
                    <path
                        d="M21.336 7.471 11.714.686a1.035 1.035 0 0 0-1.184 0L.909 7.471a.938.938 0 0 0 0 1.547l.944.666 2.04 1.44 6.638 4.676a1.034 1.034 0 0 0 1.183 0l6.638-4.679 2.04-1.44.944-.666a.937.937 0 0 0 0-1.544Z"
                        data-name="Path 17409"
                    />
                </g>
            </g>
        </svg>
    );
};
