import { Arrows } from '../../assets/icons/Arrows';
import { UsersIcon } from '../../assets/icons/UsersIcon';
import { useState, useEffect } from 'react';
import { DefaultSelect } from '../../ui/DefaultSelect/index.js';
import { CheckCircle, PlayCircle } from '@mui/icons-material';
import { DefaultDataGrid } from '../../ui/DefaultDataGrid';
import { Box, Button, Stack, Typography, Backdrop, CircularProgress } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import api from '../../lib/axios';
import { DefaultButton } from '../../ui/DefaultButton';
import { arrayTransferAction, arrayTransferStatus } from './data/InventoryData';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { ConfirmAlert } from '../../customControls/confirmAlert';

export const Transfer = ({ disabled, tallyID, currentSiteID }) => {
    const [isSuccessScreen, setIsSuccessScreen] = useState(false);
    const [deleteType, setDeleteType] = useState("");
    const [deleteMessage, setDeleteMessage] = useState("");
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);
    const [selectedTransferId, setSelectedTransferId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [arrayTransfer, setArrayTransfer] = useState([]);
    const [arrayAllLocation, setArrayAllLocation] = useState([]);
    const [arrayLocation, setArrayLocation] = useState([]);
    const [arrayUser, setArrayUser] = useState([]);
    const [selectedCurrentLocation, setSelectedCurrentLocation] = useState(null);
    const [selectedNewLocation, setSelectedNewLocation] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const columns = [
        {
            field: 'tallyNo',
            headerName: 'Tally ID',
            width: 100,
            renderCell: ({ value }) => <Typography fontWeight={700}>{value}</Typography>,
        },
        {
            field: 'originalSiteName',
            headerName: 'Original Site',
            width: 150,
        },
        {
            field: 'newSiteName',
            headerName: 'New Site',
            width: 150,
        },
        {
            field: 'newEndUser',
            headerName: 'New End User',
            width: 130,
            renderCell: ({ value }) => <Typography >{value || "-"}</Typography>,
        },
        {
            field: 'fullName',
            headerName: 'Modified By',
            width: 150,
        },
        {
            field: 'transferDate',
            headerName: 'Transfer Date',
            flex: 1,
            renderCell: ({ value }) => <Typography >{moment(value).format("MM-DD-YYYY hh:mma")}</Typography>,
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 140,
            renderCell: ({ value }) => <Typography >{arrayTransferAction.find(action => action.id == value)?.value}</Typography>,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 190,
            renderCell: (params) => (
                <Stack direction={'row'} spacing={1} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                    <Typography>{arrayTransferStatus.find(status => status.id == params.row.status)?.value}</Typography>
                    {params.row.status == "1" && (
                        <DefaultButton
                            sx={{
                                minWidth: 'unset',
                                bgcolor: '#E4E3E3',
                                color: 'primary.main',
                                boxShadow: 'none',
                                '&:hover': {
                                    color: '#E4E3E3',
                                },
                            }}
                            variant={'contained'}
                            onClick={() => {
                                setSelectedTransferId(params.row._id)
                                setDeleteType("1")
                                setDeleteMessage("Are you sure you want to remove tally from transfer queue?")
                                setDeleteConfirmationShouldOpen(true)
                            }}
                        >
                            Undo
                        </DefaultButton>
                    )}
                    {/* {params.row.status == "1" && (
                        <DefaultButton
                            sx={{
                                minWidth: 'unset',
                                bgcolor: '#E4E3E3',
                                color: 'primary.main',
                                boxShadow: 'none',
                                '&:hover': {
                                    color: '#E4E3E3',
                                },
                            }}
                            variant={'contained'}
                            onClick={() => {
                                setSelectedTransferId(params.row._id)
                                setDeleteType("2")
                                setDeleteMessage("Are you sure you want to confirm transfer?")
                                setDeleteConfirmationShouldOpen(true)
                            }}
                        >
                            Complete
                        </DefaultButton>
                    )} */}
                </Stack>
            ),
        },
    ]

    useEffect(() => {
        getPipeTallyDetail();
    }, [tallyID]);

    function getPipeTallyDetail() {
        setIsLoading(true)
        api.get('/apis/Tallies/TallyDetail').then((res) => {
            if (res.data.success) {
                var resData = res.data.data

                setArrayUser(resData.arrayUser)
                var selectedLocation = resData.arraySite.filter(location => location._id == currentSiteID)
                if (selectedLocation.length > 0) {
                    selectedLocation = selectedLocation[0]
                } else {
                    selectedLocation = null
                }
                setSelectedCurrentLocation(selectedLocation)
                setArrayAllLocation(resData.arraySite)
                setArrayLocation(resData.arraySite.filter(site => site._id != currentSiteID))
                getData()
            }
            // setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }


    function getData() {
        // setIsLoading(true)
        api.get('/apis/TallyTransfers/byTally/' + tallyID).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayTransfer(resData)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    function completedStatus() {
        setIsLoading(true)
        api.put('/apis/TallyTransfers/complete/' + selectedTransferId).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var selectedLocation = arrayAllLocation.filter(location => location._id == resData.locationID)
                if (selectedLocation.length > 0) {
                    selectedLocation = selectedLocation[0]
                } else {
                    selectedLocation = null
                }
                setSelectedCurrentLocation(selectedLocation)
                setArrayLocation(arrayAllLocation.filter(site => site._id != resData.locationID))
                setIsSuccessScreen(true)
                getData()

            } else {
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    function undoStatus() {
        setIsLoading(true)
        api.put('/apis/TallyTransfers/undo/' + selectedTransferId).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("Tally removed from transfer queue", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                getData()
            } else {

                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }
    const updateConfirmAlert = (value) => {
        if (value) {
            if (deleteType == "1") {
                undoStatus()
            } else if (deleteType == "2") {
                completedStatus()
            }

        }
    }

    async function apiCalling(selectedLocation, selecteEndUser) {
        setIsLoading(true)
        var data = {
            tallyID: tallyID,
            originalSiteID: currentSiteID,
            newSiteID: selectedLocation,
            endUserID: selecteEndUser != null ? selecteEndUser._id : "",
            action: selecteEndUser == null ? "1" : "2"
        }

        return await api.post('/apis/TallyTransfers/add', data)

    }
    return (
        <>
            <Stack
                flex={1}
                sx={{
                    pointerEvents: disabled && 'none',
                }}
            >
                {!isSuccessScreen && (
                    <>
                        <Box p={2}>
                            <Typography fontSize={20} mb={2}>
                                Tally Transfer
                            </Typography>
                            {(arrayTransfer.find(transfer => transfer.status == "1") == null) && <Formik
                                enableReinitialize
                                initialValues={{
                                    submit: null,
                                    selectedCurrentLocation: selectedCurrentLocation,
                                    selectedNewLocation: selectedNewLocation,
                                    selectedUser: selectedUser,
                                }}
                                validationSchema={Yup
                                    .object()
                                    .shape({
                                        selectedCurrentLocation: Yup.object().required('Current site is required'),
                                        selectedNewLocation: Yup.object().required('New site is required'),
                                        // selectedUser: Yup.object().required('User is required'),
                                    })}
                                onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                                    console.log("onsubmit")
                                    try {
                                        // setIsLoading(true)
                                        // setSelectedNewLocation(values.selectedNewLocation)
                                        // setSelectedUser(values.selectedUser)
                                        var data = await apiCalling(values.selectedNewLocation, values.selectedUser)
                                        setIsLoading(false)
                                        if (data !== undefined && data.data.success) {
                                            console.log("IF")
                                            resetForm();
                                            setStatus({ success: true });
                                            setSubmitting(false);

                                            enqueueSnackbar('Tally added in transfer queue', {
                                                anchorOrigin: {
                                                    horizontal: 'right',
                                                    vertical: 'top'
                                                },
                                                variant: 'success'
                                            });
                                            getData()
                                        } else {
                                            console.log("ELSE")
                                            setStatus({ success: false });
                                            setErrors({ submit: data.data.message });
                                            setSubmitting(false);
                                            enqueueSnackbar(data.data.message, {
                                                anchorOrigin: {
                                                    horizontal: 'right',
                                                    vertical: 'top'
                                                },
                                                variant: 'error'
                                            });
                                        }
                                    } catch (err) {
                                        console.log("error12", err);
                                        setIsLoading(false)
                                        setStatus({ success: false });
                                        setErrors({ submit: err.message });
                                        setSubmitting(false);
                                    }

                                }}
                            >
                                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                                    <form
                                        onSubmit={handleSubmit}
                                        style={{ height: "100%" }}
                                    >
                                        <Stack direction={'row'} gap={4} alignItems={'flex-end'}>
                                            <Box width={200}>
                                                <DefaultSelect
                                                    readOnly={values.selectedCurrentLocation != null}
                                                    onBlur={handleBlur}
                                                    onChange={(event, newValue) => {
                                                        values.selectedCurrentLocation = newValue
                                                        console.log("st", newValue)
                                                    }}
                                                    helperText={(touched.selectedCurrentLocation && errors.selectedCurrentLocation) ? 'Select Current site' : 'Current Site'}
                                                    helperTextColor={(touched.selectedCurrentLocation && errors.selectedCurrentLocation) ? 'red.main' : 'green.main'}
                                                    error={Boolean(touched.selectedCurrentLocation && errors.selectedCurrentLocation)}
                                                    value={values.selectedCurrentLocation}
                                                    options={arrayLocation}
                                                    getOptionLabel={(option) => option.siteName}
                                                    isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                                    name="selectedCurrentLocation"
                                                    placeholder="" />
                                            </Box>
                                            <Stack
                                                sx={{
                                                    width: 40,
                                                    height: 40,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    borderRadius: '50%',
                                                    bgcolor: '#EFEFEF',
                                                    svg: {
                                                        width: 24,
                                                        height: 24,
                                                    },
                                                }}
                                            >
                                                <Arrows />
                                            </Stack>
                                            <Box width={200}>
                                                <DefaultSelect
                                                    onBlur={handleBlur}
                                                    onChange={(event, newValue) => {
                                                        values.selectedNewLocation = newValue
                                                        console.log("st", newValue)
                                                    }}
                                                    helperText={(touched.selectedNewLocation && errors.selectedNewLocation) ? 'Select new site' : 'New Site'}
                                                    helperTextColor={(touched.selectedNewLocation && errors.selectedNewLocation) ? 'red.main' : 'green.main'}
                                                    error={Boolean(touched.selectedNewLocation && errors.selectedNewLocation)}
                                                    value={values.selectedNewLocation}
                                                    options={arrayLocation}
                                                    getOptionLabel={(option) => option.siteName}
                                                    isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                                    name="SelectedSiteLocation"
                                                    placeholder="" />
                                            </Box>
                                            {/* <Stack
                                                sx={{
                                                    width: 40,
                                                    height: 40,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    borderRadius: '50%',
                                                    bgcolor: '#EFEFEF',
                                                    svg: {
                                                        width: 24,
                                                        height: 24,
                                                    },
                                                }}
                                            >
                                                <UsersIcon />
                                            </Stack>
                                            <Box width={200}>
                                                <DefaultSelect
                                                    onBlur={handleBlur}
                                                    onChange={(event, newValue) => {
                                                        values.selectedUser = newValue
                                                        console.log("st", newValue)
                                                    }}
                                                    helperText={(touched.selectedUser && errors.selectedUser) ? 'Select new end user' : 'New End User'}
                                                    helperTextColor={(touched.selectedUser && errors.selectedUser) ? 'red.main' : 'green.main'}
                                                    error={Boolean(touched.selectedUser && errors.selectedUser)}
                                                    value={values.selectedUser}
                                                    options={arrayUser}
                                                    getOptionLabel={(option) => option.firstName + " " + option.lastName}
                                                    isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                                    label=''
                                                    name="SelectedEndUser"
                                                    placeholder="" />
                                            </Box> */}
                                            <Box ml={'auto'}>
                                                <Button
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '8px',
                                                        py: 1,
                                                        px: 2,
                                                        borderRadius: '5px',
                                                        '&:hover': {
                                                            bgcolor: 'primary.dark',
                                                        },
                                                    }}
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                // onClick={() => setIsSuccessScreen(true)}
                                                >
                                                    <Typography
                                                        sx={{
                                                            textTransform: 'capitalize',
                                                        }}
                                                        fontWeight={700}
                                                        fontSize={15}
                                                    >
                                                        Transfer
                                                    </Typography>
                                                    <CheckCircle
                                                        sx={{
                                                            width: 24,
                                                            height: 24,
                                                            color: 'green.main',
                                                        }}
                                                    />
                                                </Button>
                                            </Box>
                                        </Stack>
                                    </form>
                                )}
                            </Formik>}

                        </Box>
                        <Box flex={1}>
                            <DefaultDataGrid columns={columns} rows={arrayTransfer} defaultSize={15} />
                        </Box>
                    </>
                )}

                {isSuccessScreen && (
                    <Stack flex={1} alignItems={'center'} justifyContent={'center'} textAlign={'center'} p={4} spacing={3}>
                        <Typography fontSize={25} fontWeight={700} color={'green.main'}>
                            Pipe Tally Transferred Successfully
                        </Typography>
                        <Stack>
                            <Typography fontSize={20}>
                                Your pipe tally has been successfully processed and transferred.
                            </Typography>
                            <Typography fontSize={20}>A confirmation will be sent to all stakeholders.</Typography>
                        </Stack>
                        <Button
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                py: 1,
                                px: 2,
                                borderRadius: '5px',
                                '&:hover': {
                                    bgcolor: 'primary.dark',
                                },
                            }}
                            onClick={() => setIsSuccessScreen(false)}
                        >
                            <Typography
                                sx={{
                                    textTransform: 'capitalize',
                                }}
                                fontWeight={700}
                                fontSize={15}
                            >
                                View Transfers
                            </Typography>
                            <PlayCircle
                                sx={{
                                    width: 24,
                                    height: 24,
                                    color: 'green.main',
                                }}
                            />
                        </Button>
                    </Stack>
                )}
                {
                    deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={deleteMessage}
                        updateConfirmAlert={(e, value) => {
                            console.log(e, value)
                            setDeleteConfirmationShouldOpen(false)
                            updateConfirmAlert(value)
                        }} />)
                }
                <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Stack>
        </>
    );
};
