import { Box, Stack, Grid, Typography, Backdrop, CircularProgress, Tab, Tabs, Divider } from '@mui/material';
import { DefaultCircularProgress } from './DefaultCircularProgress';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { DefaultButton } from '../../ui/DefaultButton';
import { useState, useEffect } from 'react';
import truck from '../../assets/images/truck.png';
import { DefaultIconButton } from '../../ui/DefaultIconButton';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { CircleButton } from '../../ui/CircleButton';
import CloseIcon from '@mui/icons-material/Close';
import api from '../../lib/axios'
import moment from 'moment';
import { arraySalesOrderStatus } from './data/LiveTrakingData';

export const TrackingDetail = ({ trackingID, EditDetails, closeDetails, onChangeLoad }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [objSalesOrder, setobjSalesOrder] = useState({})
    const [selectedObj, setSelectedObj] = useState({})
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [activeTab, setActiveTab] = useState('loads');
    const tabs = [
        { label: 'Loads', value: 'loads' },
        { label: 'Notes', value: 'notes' },
    ]
    useEffect(() => {
        if (trackingID != "") {
            getData()
        }
    }, [trackingID]);

    function calculateDuration(startTime, endTime) {
        const start = new Date(startTime);
        const end = new Date(endTime);

        const durationMs = end - start;

        const hours = Math.floor(durationMs / (1000 * 60 * 60));
        const minutes = Math.floor((durationMs / (1000 * 60)) % 60);

        let durationString = "";
        if (hours > 0) {
            durationString += hours + (hours === 1 ? " hr " : " hrs ");
        } else {
            durationString += "0 hr "
        }
        if (minutes > 0) {
            durationString += minutes + (minutes === 1 ? " min " : " mins ");
        } else {
            durationString += "0 min "
        }
        if (durationString.trim() === "") {
            durationString = "0 hrs 0 mins";
        }
        return durationString.trim();
    }


    function getData() {
        setIsLoading(true)
        api.get('/apis/SalesOrders/getById/' + trackingID).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setobjSalesOrder(resData)
                if (resData.trucks.length > 0) {
                    setSelectedIndex(0)
                    setSelectedObj(resData.trucks[0])
                    onChangeLoad(resData.trucks[0])
                }
                setIsLoading(false)
            } else {
                setobjSalesOrder({})
                setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const handleTabsChange = (event, value) => {
        setActiveTab(value)
    };

    return (
        <Stack
            width={700}
            zIndex={2}
            height={"100%"}
            borderRight={'1px solid'}
            borderColor={'grey.light'}
            sx={{
                bgcolor: "white",
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                    width: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                    width: '4px',
                    bgcolor: 'grey.400',
                },
            }}
        >
            < Stack p={3} gap={1}>

                <Stack direction={'row'}>
                    <Typography fontSize={14} fontWeight={500} fontFamily={'Roboto'}>Tracking Details</Typography>
                    <Box flexGrow={1} />
                    <CircleButton icon={<CloseIcon />} onClick={() => { closeDetails() }} />
                </Stack>
                <Stack direction={'row'} gap={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'} fontSize={13}>Sale Order</Typography>
                                <Typography fontWeight={500} fontSize={14}>{objSalesOrder.order || "-"}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={4}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'} fontSize={13}>End User</Typography>
                                <Typography fontSize={14}>{objSalesOrder.endUser || "-"}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={4}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'} fontSize={13}>Date</Typography>
                                <Typography fontSize={14}>{objSalesOrder.createdAt ? moment(objSalesOrder.createdAt).format("MM/DD/YY") : "-"}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={4}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'} fontSize={13}>Status</Typography>
                                <Typography fontSize={14}>{arraySalesOrderStatus.find(status => status.id == objSalesOrder.status)?.value || "-"}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={4}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'} fontSize={13}>Truck Loads</Typography>
                                <Typography fontSize={14}>{objSalesOrder.truckLoads || "-"}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                    <DefaultCircularProgress
                        title={"Progress"}
                        value={objSalesOrder.shipped || 0}
                    />
                </Stack>
                <Tabs
                    indicatorColor="secondary"
                    onChange={handleTabsChange}
                    scrollButtons="auto"
                    textColor="secondary"
                    fontSize={13}
                    value={activeTab}
                    variant="scrollable"
                    sx={{ borderBottom: '1px solid #D5D5D5' }}
                >
                    {tabs.map((tab) => (<Tab
                        key={tab.value}
                        sx={{ fontWeight: 'normal', textTransform: "none", color: '#A0A3A6', fontSize: 13,padding:1 }}
                        label={tab.label}
                        value={tab.value}
                    />))}
                </Tabs>
                {activeTab == "loads" && <Stack>
                    <Stack direction={'row'} gap={3} pt={2}>
                        <Grid container spacing={1} width="60%">
                            <Grid item xs={12}>
                                <Stack spacing={0.5}>
                                    <Typography fontWeight={500} fontSize={14}>{objSalesOrder.pipeProfileName || "-"}</Typography>
                                    <Typography color={'#A8A8A7'} fontSize={13}>Pipe Profile</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack spacing={0.5}>
                                    <Typography fontWeight={500} fontSize={14}>{selectedIndex + 1}/ {objSalesOrder.truckLoads}</Typography>
                                    <Typography color={'#A8A8A7'} fontSize={13}>Loads</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack spacing={0.5}>
                                    <Typography fontWeight={500} fontSize={14}>{selectedObj.joints || "0"}</Typography>
                                    <Typography color={'#A8A8A7'} fontSize={13}>Joints</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack spacing={0.5}>
                                    <Typography fontWeight={500} fontSize={14}>{selectedObj.footage || "0"}</Typography>
                                    <Typography color={'#A8A8A7'} fontSize={13}>Footage</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Box px={2} py={2}>
                            <img
                                src={truck}
                                alt='truck'
                                style={{ width: '100%', height: 'auto' }}
                            />
                        </Box>
                    </Stack>
                    <Grid container spacing={2} pt={3.5} pb={2}>
                        <Grid item xs={9}>
                            <Stack spacing={1.3}>
                                <Typography fontWeight={500} fontFamily={'Roboto'} fontSize={14}>Location Details</Typography>
                                <Typography fontWeight={500} fontSize={13}>{selectedObj.tracker && selectedObj.tracker.address ? selectedObj.tracker.address : "-"}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={3} sx={{ alignContent: 'end' }}>
                            <Typography textAlign="right" fontSize={13} color={'#A8A8A7'}>{selectedObj.tracker ? (moment(selectedObj.tracker.timestamp).format("MM/DD/YYYY hh:mma")) : "-"}</Typography>
                        </Grid>
                    </Grid>

                    <Stack
                        borderRadius={'10px'}
                        padding={1}
                        sx={{
                            bgcolor: '#F6F8FA',
                        }}>
                        <Grid container spacing={2}>
                            <Grid item xs={3.5}>
                                <Stack spacing={1}>
                                    <Stack direction={'row'} gap={3}>
                                        <Typography fontWeight={500} fontFamily={'Roboto'} fontSize={15}>{selectedObj.startDate ? calculateDuration(selectedObj.startDate, (selectedObj.endDate ? selectedObj.endDate : moment().format("MM/DD/YY hh:mm a"))) : "-"}</Typography>
                                        <AccessTimeFilledIcon />
                                    </Stack>

                                    <Typography color={'#A8A8A7'} fontSize={13}>Trip Duration</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={2}>
                                <Stack spacing={1}>
                                    <Box px={1} py={0.2} flex={1} border={'1px solid'} borderColor={'#25D5AA'} borderRadius={'5px'} overflow={'hidden'} width={72}>
                                        <Typography fontSize={13}>{selectedObj.movement || "-"}</Typography>
                                    </Box>
                                    <Typography color={'#A8A8A7'} fontSize={13}>Movement</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={2}>
                                <Stack spacing={1}>
                                    <Box px={1} py={0.2} flex={1} border={'1px solid'} borderColor={'#25D5AA'} borderRadius={'5px'} overflow={'hidden'}>
                                        <Typography fontSize={13}>{selectedObj.tracker ? selectedObj.tracker.deviceSerialNumber : "-"}</Typography>
                                    </Box>
                                    <Typography color={'#A8A8A7'} fontSize={13}>Tracker ID</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={1}>
                                <Stack spacing={1}>
                                    <Typography fontWeight={500} fontSize={13} fontFamily={'Roboto'}>{selectedObj.tracker ? selectedObj.tracker.positionSpeed : "-"}</Typography>
                                    <Typography color={'#A8A8A7'} fontSize={13}>Speed</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={1}>
                                <Stack spacing={1}>
                                    <Typography fontWeight={500} fontSize={13} fontFamily={'Roboto'}>{selectedObj.tracker ? selectedObj.tracker.temperature : "-"}</Typography>
                                    <Typography color={'#A8A8A7'} fontSize={13}>Temp</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={1}>
                                <Stack spacing={1}>
                                    <Typography fontWeight={500} fontSize={13} fontFamily={'Roboto'}>{selectedObj.tracker ? selectedObj.tracker.positionAltitude : "-"}</Typography>
                                    <Typography color={'#A8A8A7'} fontSize={13}>Altitude</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                    <Stack direction={'row'} gap={2} pt={2}>

                        <DefaultButton sx={{
                            backgroundColor: "#EFEFEF", color: "#151D26", borderColor: "#F1F1F5", width: "100px", '&:hover': {
                                backgroundColor: "#EFEFEF",
                                color: "#151D26",
                                borderColor: "#F1F1F5",
                            },
                        }}
                            onClick={(e) => {
                                EditDetails()
                            }}
                        >
                            Edit
                        </DefaultButton>
                        <Box flexGrow={1} />
                        <DefaultIconButton
                            disabled={selectedObj._id == null || selectedIndex == 0}
                            sx={{
                                bgcolor: '#F6F7FA',
                                color: '#262B40',
                                '&:hover': {
                                    bgcolor: '#F6F7FA',
                                },
                            }}
                            icon={<KeyboardArrowLeftRoundedIcon style={{ fontSize: '25px' }} />}
                            onClick={(e) => {
                                var index = selectedIndex - 1
                                console.log("previousindex", index)
                                setSelectedIndex(index)
                                setSelectedObj(objSalesOrder.trucks[index])
                                onChangeLoad(objSalesOrder.trucks[index])
                            }}
                        />
                        <DefaultIconButton
                            disabled={selectedObj._id == null || selectedIndex == (objSalesOrder.trucks.length - 1)}
                            sx={{
                                bgcolor: '#F6F7FA',
                                color: '#262B40',
                                '&:hover': {
                                    bgcolor: '#F6F7FA',
                                },
                            }}
                            icon={<KeyboardArrowRightRoundedIcon style={{ fontSize: '25px' }} />}
                            onClick={(e) => {
                                var index = selectedIndex + 1
                                console.log("nextindex", index)
                                setSelectedIndex(index)
                                setSelectedObj(objSalesOrder.trucks[index])
                                onChangeLoad(objSalesOrder.trucks[index])
                            }}
                        />
                    </Stack>

                </Stack>}
                {activeTab == "notes" &&
                    <Stack spacing={1}>
                        <Typography>{objSalesOrder.notes || "-"}</Typography>
                    </Stack>}
            </Stack>
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack >
    );
}