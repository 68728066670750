import React from 'react';
import { Title } from '../../ui/Title/index.js';
import { Box, Stack, Typography, Backdrop, CircularProgress } from '@mui/material';
import { DefaultDataGrid } from '../../ui/DefaultDataGrid/index.js';
import SettingsProviderDetails from './SettingsProviderDetails.jsx';
import { ColorStatus } from './ConfigurationTypeColorStatus/ConfigurationTypeColorStatus.jsx';
import { CircleButton } from '../../ui/CircleButton';
import { ArrowForwardIos } from '@mui/icons-material';
import api from '../../lib/axios';
import { arrayProviderType } from './staticData/providersData.jsx';
class SettingsProviders extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isActiveDetails: false,
            selectedPoviderID: "",
            arrayData: []
        }

        this.providersColumns = [
            {
                field: 'providerName',
                headerName: 'Provider Name',
                width: 175,
                renderCell: ({ value }) => (
                    <Typography color={'black'} fontSize={13} fontWeight={700}>
                        {value}
                    </Typography>
                ),
            },
            {
                field: 'providerType', headerName: 'Type', width: 160,
                renderCell: ({ value }) => (
                    <Typography fontSize={13}>{arrayProviderType.find((data) => data.id == value).value}</Typography>
                ),
            },
            {
                field: 'status',
                headerName: 'Status',
                width: 170,
                renderCell: ({ value }) => (
                    <Stack
                        width={'100%'}
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        spacing={1}
                        pr={2}
                    >
                        <Typography fontSize={13}>{value == "1" ? "Active" : "Inactive"}</Typography>
                        <ColorStatus code={value == "1" ? "1" : "2"} />
                    </Stack>
                ),
            },
            {
                field: 'description',
                headerName: 'Description',
                width: 190,
            },
            {
                field: 'contactName',
                headerName: 'Contact Name',
                width: 190,
            },
            { field: 'mobile', headerName: 'Cellular', width: 210 },
            {
                field: 'arrowIcon',
                headerName: '',
                flex: 1,
                renderCell: () => (
                    <Stack alignItems={'flex-end'} width={'100%'} pr={5}>
                        <ArrowForwardIos
                            sx={{
                                width: 18,
                                height: 18,
                            }}
                        />
                    </Stack>
                ),
                sortable: false,
                disableColumnMenu: true,
            },
        ];
    }
    componentDidMount() {
        this.getData()
    }


    getData() {
        this.setState({ isLoading: true })
        api.get('/apis/Providers/').then((res) => {

            if (res.data.success) {
                var resData = res.data.data
                this.setState({
                    arrayData: resData,
                    isLoading: false
                })
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    render() {
        return (
            <>
                <Stack py={2} px={2.5} width={'100%'}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Stack direction={'row'} spacing={2} alignItems={'center'}>
                            <Title>Service Providers</Title>
                            <Typography color={'grey.blue'} fontSize={20}>
                                ({this.state.arrayData.length})
                            </Typography>
                        </Stack>
                        <CircleButton
                            onClick={() => {
                                this.setState({
                                    selectedPoviderID: "",
                                    isActiveDetails: true,
                                })
                            }}
                        />
                    </Stack>
                    <Box mt={2} flex={1} border={'1px solid'} borderColor={'grey.light'} borderRadius={'5px'} overflow={'hidden'}>
                        <DefaultDataGrid
                            columns={this.providersColumns}
                            rows={this.state.arrayData}
                            onRowClick={(e) => {
                                this.setState({
                                    selectedPoviderID: e.id,
                                    isActiveDetails: true,
                                })
                            }}
                        />
                    </Box>
                    {this.state.isActiveDetails && (
                        <SettingsProviderDetails
                            providerID={this.state.selectedPoviderID}
                            closeDetails={() => {
                                this.setState({
                                    isActiveDetails: false,
                                })
                                this.getData()
                            }}
                        />
                    )}
                </Stack>
                <Backdrop style={{ zIndex: 1 }} open={this.state.isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        );
    }
}

export default SettingsProviders;
