import { Stack, Typography } from '@mui/material';
import React from 'react';
import { ColorStatus } from '../../../ui/ColorStatus/index.js';
import { ArrowForwardIos } from '@mui/icons-material';

export const arrayProviderType = [
    { "id": "0", "value": "Logistics" },
    { "id": "1", "value": "Tubular Services" },
    { "id": "2", "value": "Oilfield Services" },
    { "id": "3", "value": "Inspection Company" },
    { "id": "4", "value": "Rental Company" }
]

export const providersColumns = [
    {
        field: 'providerName',
        headerName: 'Provider Name',
        width: 175,
        renderCell: ({ value }) => (
            <Typography color={'secondary'} fontSize={13} fontWeight={700}>
                {value}
            </Typography>
        ),
    },
    { field: 'type', headerName: 'Type', width: 160 },
    {
        field: 'status',
        headerName: 'Status',
        width: 170,
        renderCell: ({ value: { name, code } }) => (
            <Stack
                width={'100%'}
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                spacing={1}
                pr={2}
            >
                <Typography fontSize={13}>{name}</Typography>
                <ColorStatus code={code} />
            </Stack>
        ),
    },
    {
        field: 'description',
        headerName: 'Description',
        width: 190,
    },
    {
        field: 'contactName',
        headerName: 'Contact Name',
        width: 190,
    },
    { field: 'cellular', headerName: 'Cellular', width: 210 },
    {
        field: 'arrowIcon',
        headerName: '',
        flex: 1,
        renderCell: () => (
            <Stack alignItems={'flex-end'} width={'100%'} pr={5}>
                <ArrowForwardIos
                    sx={{
                        width: 18,
                        height: 18,
                    }}
                />
            </Stack>
        ),
        sortable: false,
        disableColumnMenu: true,
    },
];

export const providersRows = [
    {
        id: 1,
        providerName: 'FLS USA',
        type: 'Logistics',
        status: {
            name: 'Active',
            code: 1,
        },
        description: 'Project Cargo',
        contactName: 'Anton Bersh',
        cellular: '713-587-9986',
    },
    {
        id: 2,
        providerName: 'FLS USA',
        type: 'Logistics',
        status: {
            name: 'Active',
            code: 1,
        },
        description: 'Project Cargo',
        contactName: 'Anton Bersh',
        cellular: '713-587-9986',
    },
    {
        id: 3,
        providerName: 'FLS USA',
        type: 'Logistics',
        status: {
            name: 'Active',
            code: 1,
        },
        description: 'Project Cargo',
        contactName: 'Anton Bersh',
        cellular: '713-587-9986',
    },
    {
        id: 4,
        providerName: 'FLS USA',
        type: 'Logistics',
        status: {
            name: 'Active',
            code: 1,
        },
        description: 'Project Cargo',
        contactName: 'Anton Bersh',
        cellular: '713-587-9986',
    },
    {
        id: 5,
        providerName: 'FLS USA',
        type: 'Logistics',
        status: {
            name: 'Active',
            code: 1,
        },
        description: 'Project Cargo',
        contactName: 'Anton Bersh',
        cellular: '713-587-9986',
    },
    {
        id: 6,
        providerName: 'FLS USA',
        type: 'Logistics',
        status: {
            name: 'Active',
            code: 1,
        },
        description: 'Project Cargo',
        contactName: 'Anton Bersh',
        cellular: '713-587-9986',
    },
];
