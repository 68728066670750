import { Backdrop, Box, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { Popup } from '../../components/Popup/index.js';
import { DefaultButton } from '../../ui/DefaultButton/index.js';
import { useNavigate } from 'react-router-dom';
import { DefaultSelect } from '../../ui/DefaultSelect/index.js';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { DefaultInput } from '../../ui/DefaultInput';
import { arrayStatus, arrayCondition } from './data/InventoryData';
import api from '../../lib/axios'
import { enqueueSnackbar } from 'notistack';
import { ConfirmAlert } from '../../customControls/confirmAlert';

export const InventoryNew = ({ inventoryID, closeDetails }) => {
    const navigate = useNavigate();
    const [arrayPipeCategory, setArrayPipeCategory] = useState([]);
    const [arrayPipeType, setArrayPipeType] = useState([]);
    const [arrayPipeProfile, setArrayPipeProfile] = useState([]);
    const [arrayLocation, setArrayLocation] = useState([]);
    const [arrayZone, setArrayZone] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedPipeCategory, setSelectedPipeCategory] = useState(null);
    const [selectedPipeType, setSelectedPipeType] = useState(null);
    const [selectedPipeProfileName, setSelectedPipeProfileName] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedZone, setSelectedZone] = useState(null);
    const [purchaseOrder, setPurchaseOrder] = useState(null);
    const [accessCode, setAccessCode] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedCondition, setSelectedCondition] = useState(null);
    const [deleteMessage, setDeleteMessage] = useState("");
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);


    useEffect(() => {
        getInventoryDetail();
    }, [inventoryID]);


    const onClose = () => {
        closeDetails(false)
    };

    function getInventoryDetail() {
        setIsLoading(true)
        api.get('/apis/Inventories/inventoryDetail').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayPipeCategory(resData.arrayCategory)
                setArrayPipeType(resData.arrayType)
                setArrayPipeProfile(resData.arrayPipeProfile)
                setArrayLocation(resData.arraySite)
                setArrayZone(resData.arrayZone)
                if (inventoryID != "") {
                    console.log("IF getInventoryDetail")
                    getInventoryData(resData.arrayCategory, resData.arrayType, resData.arrayPipeProfile, resData.arraySite, resData.arrayZone)
                } else {
                    setIsLoading(false)
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    function getInventoryData(arrayPipeCategory, arrayPipeType, arrayPipeProfile, arrayLocation, arrayZone) {
        // setIsLoading(true)

        api.get('/apis/Inventories/getDetailById/' + inventoryID).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var selectedStatus = arrayStatus.find(status => status.id == resData.status)
                var selectedCondition = arrayCondition.find(condition => condition.id == resData.condition)
                var selectedPipeCategory = arrayPipeCategory.find(category => category._id == resData.pipeCategoryID)
                var selectedPipeType = arrayPipeType.find(type => type._id == resData.pipeTypeID)
                var selectedPipeProfileName = arrayPipeProfile.find(profileName => profileName._id == resData.pipeProfileID)
                var selectedLocation = arrayLocation.find(location => location._id == resData.location._id)
                var selectedZone = arrayZone.find(zone => zone._id == resData.zoneID)
                console.log("selectedZone: ", selectedZone)
                setSelectedPipeCategory(selectedPipeCategory)
                setSelectedPipeType(selectedPipeType)
                setSelectedPipeProfileName(selectedPipeProfileName)
                setSelectedLocation(selectedLocation)
                setSelectedZone(selectedZone)
                setPurchaseOrder(resData.purchaseOrder)
                setAccessCode(resData.accessCode)
                setSelectedStatus(selectedStatus)
                setSelectedCondition(selectedCondition)
                setIsLoading(false)
            }

        }).catch(function (error) {
            console.log(error);
        });
    }
    async function apiCalling(autoGenerated, purchaseOrder, selectedPipeCategory, selectedPipeType, selectedStatus, selectedPipeProfileName, selectedLocation, selectedZone, selectedCondition, accessCode) {
        var data = {
            autoGenerated: autoGenerated,
            purchaseOrder: purchaseOrder,
            pipeCategoryID: selectedPipeCategory._id,
            pipeTypeID: selectedPipeType._id,
            condition: selectedCondition.id,
            status: selectedStatus.id,
            pipeProfileID: selectedPipeProfileName._id,
            locationID: selectedLocation._id,
            zoneID: selectedZone._id,
            accessCode: accessCode,
        }
        if (inventoryID != "") {
            return await api.put('/apis/Inventories/updateById/' + inventoryID, data)
        } else {
            return await api.post('/apis/Inventories/add', data)
        }
    }
    function deleteInventory() {
        setIsLoading(true)
        api.delete('/apis/Inventories/deleteById/' + inventoryID).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("Inventory deleted", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                navigate(-1);
            } else {
                setDeleteConfirmationShouldOpen(false)
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }
    const updateConfirmAlert = (value) => {
        if (value) {
            deleteInventory()
        } else {
            setDeleteConfirmationShouldOpen(false)
        }
    }
    return (
        <Popup isOpened={true} width={700} bgcolor={'common.white'}>
            <Formik

                enableReinitialize
                initialValues={{
                    submit: null,
                    selectedPipeCategory: selectedPipeCategory,
                    selectedPipeType: selectedPipeType,
                    selectedPipeProfileName: selectedPipeProfileName,
                    selectedLocation: selectedLocation,
                    selectedZone: selectedZone,
                    purchaseOrder: purchaseOrder,
                    accessCode: accessCode,
                    selectedStatus: selectedStatus,
                    selecteCondition: selectedCondition,
                }}
                validationSchema={Yup
                    .object()
                    .shape({
                        selectedPipeCategory: Yup.object().required('Pipe category is required'),
                        selectedPipeType: Yup.object().required('Pipe type is required'),
                        selectedPipeProfileName: Yup.object().required('Pipe profile name is required'),
                        selectedLocation: Yup.object().required('Location is required'),
                        selectedZone: Yup.object().required('Zone is required'),
                        purchaseOrder: Yup.string().required('Purchase order is required'),
                        accessCode: Yup.string().nullable().matches(/^\d{4}$/, 'Access code must be a 4-digit number only'),
                        selectedStatus: Yup.object().required('Status is required'),
                        selecteCondition: Yup.object().required('Condition is required'),
                    })}
                onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                    console.log("onsubmit", values)
                    try {
                        setIsLoading(true)
                        setPurchaseOrder(values.purchaseOrder)
                        setSelectedPipeCategory(values.selectedPipeCategory)
                        setSelectedPipeType(values.selectedPipeType)
                        setSelectedStatus(values.selectedStatus)
                        setSelectedPipeProfileName(values.selectedPipeProfileName)
                        setSelectedLocation(values.selectedLocation)
                        setSelectedZone(values.selectedZone)
                        setSelectedCondition(values.selecteCondition)
                        setAccessCode(values.accessCode)
                        var data = await apiCalling("1", values.purchaseOrder, values.selectedPipeCategory, values.selectedPipeType, values.selectedStatus, values.selectedPipeProfileName, values.selectedLocation, values.selectedZone, values.selecteCondition, values.accessCode)
                        setIsLoading(false)
                        if (data !== undefined && data.data.success) {
                            resetForm();
                            setStatus({ success: true });
                            setSubmitting(false);

                            enqueueSnackbar('Inventory ' + (inventoryID == "" ? "added" : "updated"), {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'success'
                            });
                            closeDetails(true)
                        } else {
                            setStatus({ success: false });
                            setErrors({ submit: data.data.message });
                            setSubmitting(false);
                            enqueueSnackbar(data.data.message, {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'error'
                            });
                        }
                    } catch (err) {
                        console.error("error12", err);
                        setIsLoading(false)
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }

                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <form
                        onSubmit={handleSubmit}
                        style={{ height: "100%" }}
                    >
                        <Stack px={3} pt={3} pb={2} spacing={2} flexGrow={1} height={'100%'}>
                            <Typography fontSize={20}>Inventory Details</Typography>
                            <Box flexGrow={1}>
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <DefaultSelect
                                            onBlur={handleBlur}
                                            onChange={(event, newValue) => {
                                                values.selectedPipeCategory = newValue
                                                values.selectedPipeType = null
                                                values.selectedPipeProfileName = null
                                                console.log("st", newValue)
                                            }}
                                            helperText={touched.selectedPipeCategory && errors.selectedPipeCategory && <p>Select category</p>}
                                            error={Boolean(touched.selectedPipeCategory && errors.selectedPipeCategory)}
                                            value={values.selectedPipeCategory}
                                            options={arrayPipeCategory}
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                            label={"Pipe Category"}
                                            name="selectedCategory"
                                            placeholder="Select Category" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultSelect
                                            onBlur={handleBlur}
                                            onChange={(event, newValue) => {
                                                values.selectedPipeType = newValue;
                                                values.selectedPipeProfileName = null
                                                console.log("st", newValue);
                                            }}
                                            helperText={touched.selectedPipeType && errors.selectedPipeType && <p>Select pipe type</p>}
                                            error={Boolean(touched.selectedPipeType && errors.selectedPipeType)}
                                            value={values.selectedPipeType}
                                            options={values.selectedPipeCategory == null ? [] : arrayPipeType.filter(obj => obj.categoryID === values.selectedPipeCategory._id)}
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                            label='Pipe Type'
                                            name="selectedPipeType"
                                            placeholder="Select Pipe Type" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultSelect
                                            onBlur={handleBlur}
                                            onChange={(event, newValue) => {
                                                values.selecteCondition = newValue
                                                console.log("st", newValue)
                                            }}
                                            helperText={touched.selecteCondition && errors.selecteCondition && <p>Select condition</p>}
                                            error={Boolean(touched.selecteCondition && errors.selecteCondition)}
                                            value={values.selecteCondition}
                                            options={arrayCondition}
                                            getOptionLabel={(option) => option.value}
                                            isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                            label='Condition'
                                            name="selectedCondition"
                                            placeholder="Select Condition" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultSelect
                                            onBlur={handleBlur}
                                            onChange={(event, newValue) => {
                                                values.selectedPipeProfileName = newValue
                                                console.log("st", newValue)
                                            }}
                                            helperText={touched.selectedPipeProfileName && errors.selectedPipeProfileName && <p>Select pipe profile name</p>}
                                            error={Boolean(touched.selectedPipeProfileName && errors.selectedPipeProfileName)}
                                            value={values.selectedPipeProfileName}
                                            options={values.selectedPipeCategory == null || values.selectedPipeType == null ? [] : arrayPipeProfile.filter(obj => obj.categoryID === values.selectedPipeCategory._id && obj.typeID === values.selectedPipeType._id)}
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                            name="selectedPipeProfileName"
                                            label={'Pipe Profile Name'}
                                            placeholder="Select Pipe Profile Name" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultSelect
                                            onBlur={handleBlur}
                                            onChange={(event, newValue) => {
                                                setFieldValue("selectedLocation", newValue)
                                                setFieldValue("selectedZone", null)
                                            }}
                                            helperText={touched.selectedLocation && errors.selectedLocation && <p>Selected site location</p>}
                                            error={Boolean(touched.selectedLocation && errors.selectedLocation)}
                                            value={values.selectedLocation}
                                            options={arrayLocation}
                                            getOptionLabel={(option) => option.siteName}
                                            isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                            label='Site Location'
                                            name="SelectedSiteLocation"
                                            placeholder="Selected Site Location" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultSelect
                                            onBlur={handleBlur}
                                            onChange={(event, newValue) => {
                                                setFieldValue("selectedZone", newValue)
                                            }}
                                            helperText={touched.selectedZone && errors.selectedZone && <p>Select zone</p>}
                                            error={Boolean(touched.selectedZone && errors.selectedZone)}
                                            value={values.selectedZone}
                                            options={values.selectedLocation == null ? [] : arrayZone.filter(obj => obj.siteID === values.selectedLocation._id)}
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                            label='Zone'
                                            name="selectedZone"
                                            placeholder="Select Zone" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Purchase Order'}
                                            // type="number"
                                            placeholder={'E.g. 123'}
                                            value={values.purchaseOrder}
                                            name={"purchaseOrder"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.purchaseOrder && errors.purchaseOrder)}
                                            helperText={touched.purchaseOrder && errors.purchaseOrder}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultSelect
                                            onBlur={handleBlur}
                                            onChange={(event, newValue) => {
                                                values.selectedStatus = newValue
                                                console.log("st", newValue)
                                            }}
                                            helperText={touched.selectedStatus && errors.selectedStatus && <p>Select status</p>}
                                            error={Boolean(touched.selectedStatus && errors.selectedStatus)}
                                            value={values.selectedStatus}
                                            options={arrayStatus}
                                            getOptionLabel={(option) => option.value}
                                            isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                            label='Status'
                                            name="selectedStatus"
                                            placeholder="Select Status" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Access Code'}
                                            // type='number'
                                            placeholder={'E.g. 123'}
                                            value={values.accessCode}
                                            name={"accessCode"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            max={4}
                                            error={Boolean(touched.accessCode && errors.accessCode)}
                                            helperText={touched.accessCode && errors.accessCode}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Stack direction={'row'} spacing={1}>
                                <Box flexGrow={1}>
                                    {inventoryID && (
                                        <DefaultButton
                                            onClick={(e) => {
                                                setDeleteMessage("Are you sure you want to delete?")
                                                setDeleteConfirmationShouldOpen(true)
                                            }}
                                            color={'error'}
                                        >
                                            Delete
                                        </DefaultButton>
                                    )}
                                </Box>
                                <DefaultButton onClick={onClose}>Cancel</DefaultButton>
                                <DefaultButton
                                    color={'success'}
                                    disabled={isSubmitting}
                                    type="submit">
                                    Save
                                </DefaultButton>
                            </Stack>
                        </Stack>
                    </form>
                )}
            </Formik>
            {
                deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={deleteMessage}
                    updateConfirmAlert={(e, value) => {
                        console.log(e, value)
                        setDeleteConfirmationShouldOpen(false)
                        updateConfirmAlert(value)
                    }} />)
            }
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Popup>
    );
};
