import { Backdrop, Box, CircularProgress, FormHelperText, Grid, Stack, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { Popup } from '../../components/Popup/index.js';
import { DefaultButton } from '../../ui/DefaultButton/index.js';
import { useNavigate } from 'react-router-dom';
import { DefaultSelect } from '../../ui/DefaultSelect/index.js';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { DefaultInput } from '../../ui/DefaultInput';
import api from '../../lib/axios'
import { enqueueSnackbar } from 'notistack';
import { ConfirmAlert } from '../../customControls/confirmAlert';
import { linePipeId, tubingId } from './Data/tabs.js';

export const PipeProfileNew = ({ pipeProfileID, closeDetails }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [arrayCategory, setArrayCategory] = useState([]);
    const [arrayType, setArrayType] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [name, setName] = useState("");
    const [outsideDiameter, setOutsideDiameter] = useState("");
    const [weight, setWeight] = useState("");
    const [wallThickness, setWallThickness] = useState("");
    const [length, setLength] = useState("");
    const [grade, setGrade] = useState("");
    const [connectionName, setConnectionName] = useState("");
    const [description, setDescription] = useState("");
    const [coating, setCoating] = useState("");
    const [surface, setSurface] = useState("");
    const [gauge, setGauge] = useState("");
    const [endType, setEndType] = useState("");
    const [cutOff, setCutOff] = useState("");
    const [range, setRange] = useState("");
    const [deleteMessage, setDeleteMessage] = useState("");
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);
    // const [initialValue, setInitialValue] = useState({});
    // const [objFormikError, setObjFormikError] = useState({});
    const [arrayAttribute, setArrayAttribute] = useState([
        {
            id: 6,
            title: 'Pin Od',
            value: '0.000',
            type: 'number',
            formate: '0.000',
            isOn: false,
            isInMobile: false,
        },
        {
            id: 7,
            title: 'Pin Id',
            value: '0.000',
            type: 'number',
            formate: '0.000',
            isOn: false,
            isInMobile: false,
        },
        {
            id: 8,
            title: 'Pin Tong',
            value: '00.000',
            type: 'number',
            formate: '00.000',
            isOn: false,
            isInMobile: false,
        },
        {
            id: 9,
            title: 'Pin Length',
            value: '0.000',
            type: 'number',
            formate: '0.000',
            isOn: false,
            isInMobile: false,
        },
        {
            id: 10,
            title: 'Pin Tong Space',
            value: '00.00',
            type: 'number',
            formate: '00.00',
            isOn: false,
            isInMobile: false,
        },
        {
            id: 11,
            title: 'Pin Bevel Diameter',
            value: '00.000',
            type: 'number',
            formate: '0.000',
            isOn: false,
            isInMobile: false,
        },
        {
            id: 12,
            title: 'Box Od',
            value: '0.000',
            type: 'number',
            formate: '0.000',
            isOn: false,
            isInMobile: false,
        },
        {
            id: 13,
            title: 'Box Id',
            value: '0.000',
            type: 'number',
            formate: '0.000',
            isOn: false,
            isInMobile: false,
        },
        {
            id: 14,
            title: 'Box Tong',
            value: '00.000',
            type: 'number',
            formate: '00.000',
            isOn: false,
            isInMobile: false,
        },
        {
            id: 15,
            title: 'Box Length',
            value: '0.000',
            type: 'number',
            formate: '0.000',
            isOn: false,
            isInMobile: false,
        },
        {
            id: 16,
            title: 'Box Tong Space',
            value: '00.00',
            type: 'number',
            formate: '00.00',
            isOn: false,
            isInMobile: false,
        },
        {
            id: 17,
            title: 'Box Bevel Diameter',
            value: '0.000',
            type: 'number',
            formate: '0.000',
            isOn: false,
            isInMobile: false,
        },
        {
            id: 18,
            title: 'Tube Wall Min',
            value: '0.000',
            type: 'number',
            formate: '0.000',
            isOn: false,
            isInMobile: false,
        },
        {
            id: 19,
            title: 'Tube Wall Max',
            value: '0.000',
            type: 'number',
            formate: '0.000',
            isOn: false,
            isInMobile: false,
        },
        {
            id: 20,
            title: 'Tube % Nom',
            value: '000',
            type: 'number',
            formate: '000',
            isOn: false,
            isInMobile: false,
        },
        {
            id: 21,
            title: 'Tube Class',
            value: '',
            type: 'text',
            formate: 'S-123',
            isOn: false,
            isInMobile: false,
        },
        {
            id: 22,
            title: 'Tube Id',
            value: '00.00',
            type: 'number',
            formate: '00.00',
            isOn: false,
            isInMobile: false,
        },
        {
            id: 23,
            title: 'Pipe Od',
            value: '00.00',
            type: 'number',
            formate: '00.00',
            isOn: false,
            isInMobile: false,
        },
        {
            id: 24,
            title: 'Pipe Body Od',
            value: '00.00',
            type: 'number',
            formate: '00.00',
            isOn: false,
            isInMobile: false,
        },
        {
            id: 25,
            title: 'Coupling Od',
            value: '00.00',
            type: 'number',
            formate: '00.00',
            isOn: false,
            isInMobile: false,
        },
        {
            id: 26,
            title: 'Sc Coupling Od',
            value: '00.00',
            type: 'number',
            formate: '00.00',
            isOn: false,
            isInMobile: false,
        },
        {
            id: 27,
            title: 'Hardband',
            value: '',
            type: 'text',
            formate: 'S-123',
            isOn: false,
            isInMobile: false,
        },
        {
            id: 28,
            title: 'Ipc',
            value: '',
            type: 'text',
            formate: 'S-123',
            isOn: false,
            isInMobile: false,
        },
        {
            id: 29,
            title: 'Actual Length',
            value: '00.00',
            type: 'number',
            formate: '00.00',
            isOn: false,
            isInMobile: false,
        },
        {
            id: 30,
            title: 'Order Length',
            value: '00.00',
            type: 'number',
            formate: '00.00',
            isOn: false,
            isInMobile: false,
        },
    ]);

    useEffect(() => {
        getPipeProfileDetail();
    }, [pipeProfileID]);

    const onClose = () => {
        closeDetails(false)
    };

    function getPipeProfileDetail() {
        setIsLoading(true)
        api.get('/apis/PipeProfiles/pipeProfileDetail').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayCategory(resData.arrayCategory)
                setArrayType(resData.arrayType)

                if (pipeProfileID != "") {
                    getPipeProfileData(resData.arrayCategory, resData.arrayType)
                } else {
                    setIsLoading(false)
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    function getPipeProfileData(arrayCategory, arrayType) {
        // setIsLoading(true)
        api.get('/apis/PipeProfiles/getById/' + pipeProfileID).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var selectedCategory = arrayCategory.filter(category => category._id == resData.categoryID)
                if (selectedCategory.length > 0) {
                    selectedCategory = selectedCategory[0]
                } else {
                    selectedCategory = null
                }
                var selectedType = arrayType.filter(type => type._id == resData.typeID)
                if (selectedType.length > 0) {
                    selectedType = selectedType[0]
                } else {
                    selectedType = null
                }
                // var objError = objFormikError
                // var objValue = {}
                // resData.attributes.map((data) => {
                //     if (data.isOn) {
                //         objError[data.id + ""] = Yup.string().required(`${data.title.} is required`);
                //         objValue[data.id + ""] = data.value
                //     }
                // })
                // setObjFormikError(objError);
                // setInitialValue(objValue)
                setSelectedCategory(selectedCategory)
                setSelectedType(selectedType)
                setName(resData.name)
                setOutsideDiameter(resData.od)
                setWeight(resData.weight)
                setLength(resData.length)
                setWallThickness(resData.wallThickness)
                setGrade(resData.grade)
                setConnectionName(resData.connectionName)
                setDescription(resData.description)
                setCoating(resData.coating)
                setSurface(resData.surface)
                setGauge(resData.gauge)
                setEndType(resData.endType)
                setCutOff(resData.cutOff)
                setRange(resData.range)
                setArrayAttribute(resData.attributes)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function apiCalling(name, selectedCategory, selectedType, outsideDiameter, weight, wallThickness, length, grade, connectionName, description, coating, surface, gauge, endType, cutOff, range, arrayAttribute) {
        var data = {
            name: name,
            categoryID: selectedCategory._id,
            typeID: selectedType._id,
            od: outsideDiameter,
            weight: weight,
            wallThickness: wallThickness,
            length: length,
            grade: grade,
            description: description,
            attributes: arrayAttribute
        }
        if (selectedCategory._id != linePipeId) {
            data.connectionName = connectionName
            data.range = ""
            data.coating = ""
            data.surface = ""
            data.gauge = "00.000"
            data.endType = ""
            data.cutOff = ""
        } else {
            data.coating = coating
            data.surface = surface
            data.gauge = gauge
            data.endType = endType
            data.cutOff = cutOff
            data.connectionName = "00.000"
            data.range = ""
        }
        if (selectedCategory._id == tubingId) {
            data.range = range
        }

        if (pipeProfileID != "") {
            return await api.put('/apis/PipeProfiles/updateById/' + pipeProfileID, data)
        } else {
            return await api.post('/apis/PipeProfiles/add', data)
        }
    }

    function deletePipeProfile() {
        setIsLoading(true)
        api.delete('/apis/PipeProfiles/deleteById/' + pipeProfileID).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("Pipe profile deleted", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                navigate(-1);
            } else {
                setDeleteConfirmationShouldOpen(false)
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }
    const updateConfirmAlert = (value) => {
        if (value) {
            deletePipeProfile()
        } else {
            setDeleteConfirmationShouldOpen(false)
        }
    }

    return (

        <Popup isOpened={true} width={700} bgcolor={'common.white'} >
            <Formik
                enableReinitialize
                initialValues={{
                    // ...initialValue,
                    submit: null,
                    name: name,
                    selectedCategory: selectedCategory,
                    selectedType: selectedType,
                    outsideDiameter: outsideDiameter,
                    weight: weight,
                    wallThickness: wallThickness,
                    length: length,
                    grade: grade,
                    connectionName: connectionName,
                    description: description,
                    coating: coating,
                    surface: surface,
                    gauge: gauge,
                    endType: endType,
                    cutOff: cutOff,
                    range: range,
                    arrayAttribute: arrayAttribute,
                }}
                validationSchema={Yup
                    .object()
                    .shape({
                        // ...objFormikError,
                        name: Yup.string().required('Pipe profile name is required'),
                        selectedCategory: Yup.object().required('Category is required'),
                        selectedType: Yup.object().required('Type is required'),
                        outsideDiameter: Yup.string().required('Pipe od is required'),
                        weight: Yup.string().required('Weight is required'),
                        wallThickness: Yup.string().required('Wall thickness is required'),
                        grade: Yup.string().required('Grade is required'),
                        description: Yup.string().required('Description is required'),
                        connectionName: Yup.string().test('is-error', 'Connection name is required', function (value) {
                            const { selectedCategory } = this.parent;
                            const isCategoryValid = selectedCategory != null && selectedCategory._id != linePipeId;
                            const isValueEmpty = !value || !value.trim();
                            return !(isCategoryValid && isValueEmpty);
                        }),
                        coating: Yup.string().test('is-error', 'Coating is required', function (value) {
                            const { selectedCategory } = this.parent;
                            const isCategoryValid = selectedCategory != null && selectedCategory._id == linePipeId;
                            const isValueEmpty = !value || !value.trim();
                            return !(isCategoryValid && isValueEmpty);
                        }),
                        surface: Yup.string().test('is-error', 'Surface is required', function (value) {
                            const { selectedCategory } = this.parent;
                            const isCategoryValid = selectedCategory != null && selectedCategory._id == linePipeId;
                            const isValueEmpty = !value || !value.trim();
                            return !(isCategoryValid && isValueEmpty);
                        }),
                        gauge: Yup.string().test('is-error', 'Guage is required', function (value) {
                            const { selectedCategory } = this.parent;
                            const isCategoryValid = selectedCategory != null && selectedCategory._id == linePipeId;
                            const isValueEmpty = !value || !value.trim();
                            return !(isCategoryValid && isValueEmpty);
                        }),
                        endType: Yup.string().test('is-error', 'End type is required', function (value) {
                            const { selectedCategory } = this.parent;
                            const isCategoryValid = selectedCategory != null && selectedCategory._id == linePipeId;
                            const isValueEmpty = !value || !value.trim();
                            return !(isCategoryValid && isValueEmpty);
                        }),
                        cutOff: Yup.string().test('is-error', 'Cut off is required', function (value) {
                            const { selectedCategory } = this.parent;
                            const isCategoryValid = selectedCategory != null && selectedCategory._id == linePipeId;
                            const isValueEmpty = !value || !value.trim();
                            return !(isCategoryValid && isValueEmpty);
                        }),
                        range: Yup.string().test('is-error', 'Range is required', function (value) {
                            const { selectedCategory } = this.parent;
                            const isCategoryValid = selectedCategory != null && selectedCategory._id == tubingId;
                            const isValueEmpty = !value || !value.trim();
                            return !(isCategoryValid && isValueEmpty);
                        }),
                    })}
                onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                    console.log("onsubmit")
                    try {
                        setIsLoading(true)
                        setSelectedType(values.selectedType)
                        setSelectedCategory(values.selectedCategory)
                        setName(values.name)
                        setOutsideDiameter(values.outsideDiameter)
                        setWeight(values.weight)
                        setWallThickness(values.wallThickness)
                        setLength(values.length)
                        setGrade(values.grade)
                        setConnectionName(values.connectionName)
                        setDescription(values.description)
                        setCoating(values.coating)
                        setSurface(values.surface)
                        setGauge(values.gauge)
                        setEndType(values.endType)
                        setCutOff(values.cutOff)
                        setRange(values.range)
                        var data = await apiCalling(values.name, values.selectedCategory, values.selectedType, values.outsideDiameter, values.weight, values.wallThickness, values.length, values.grade, values.connectionName, values.description, values.coating, values.surface, values.gauge, values.endType, values.cutOff, values.range, values.arrayAttribute)
                        setIsLoading(false)
                        if (data !== undefined && data.data.success) {
                            resetForm();
                            setStatus({ success: true });
                            setSubmitting(false);

                            enqueueSnackbar('Pipe profile ' + (pipeProfileID == "" ? "added" : "updated"), {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'success'
                            });
                            closeDetails(true)
                        } else {
                            setStatus({ success: false });
                            setErrors({ submit: data.data.message });
                            setSubmitting(false);
                            enqueueSnackbar(data.data.message, {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'error'
                            });
                        }
                    } catch (err) {
                        setIsLoading(false)
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }

                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <form
                        onSubmit={handleSubmit}
                        style={{ height: "100%" }}>
                        <Stack px={3} pt={3} pb={2} spacing={2} height={'100%'}>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <Typography fontSize={20} fontWeight={700}>
                                    Pipe Profile
                                </Typography>
                            </Stack>
                            <Box flexGrow={1}>
                                <Grid container spacing={3}>
                                    <Grid item xs={8}>
                                        <DefaultInput
                                            label={'Pipe Profile Name'}
                                            placeholder={'E.g. xyz'}
                                            value={values.name}
                                            name={"name"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.name && errors.name)}
                                            helperText={touched.name && errors.name}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Length'}
                                            placeholder={'E.g. 4.50'}
                                            type='number'
                                            value={values.length}
                                            name={"length"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.length && errors.length)}
                                            helperText={touched.length && errors.length}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultSelect
                                            onBlur={handleBlur}
                                            onChange={(event, newValue) => {
                                                values.selectedCategory = newValue;
                                                values.selectedType = null
                                                setFieldValue("selectedCategory", values.selectedCategory)
                                                console.log("st", newValue);
                                            }}
                                            helperText={touched.selectedCategory && errors.selectedCategory && <p>Select Category</p>}
                                            error={Boolean(touched.selectedCategory && errors.selectedCategory)}
                                            value={values.selectedCategory}
                                            options={arrayCategory}
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                            label='Category'
                                            name="selectedCategory"
                                            placeholder="Select Category" />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <DefaultSelect
                                            onBlur={handleBlur}
                                            onChange={(event, newValue) => {
                                                values.selectedType = newValue;
                                                console.log("st", newValue);
                                            }}
                                            helperText={touched.selectedType && errors.selectedType && <p>Select Type</p>}
                                            error={Boolean(touched.selectedType && errors.selectedType)}
                                            value={values.selectedType}
                                            options={values.selectedCategory == null ? [] : arrayType.filter(obj => obj.categoryID === values.selectedCategory._id)}
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                            label='Type'
                                            name="selectedType"
                                            placeholder="Select Type" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'O.D.'}
                                            placeholder={'E.g. 4.50'}
                                            type='number'
                                            value={values.outsideDiameter}
                                            name={"outsideDiameter"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.outsideDiameter && errors.outsideDiameter)}
                                            helperText={touched.outsideDiameter && errors.outsideDiameter}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Weight (lb/ft)'}
                                            placeholder={'E.g. 4.50'}
                                            type='number'
                                            value={values.weight}
                                            name={"weight"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.weight && errors.weight)}
                                            helperText={touched.weight && errors.weight}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Wall Thickness'}
                                            placeholder={'E.g. 4.50'}
                                            type='number'
                                            value={values.wallThickness}
                                            name={"wallThickness"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.wallThickness && errors.wallThickness)}
                                            helperText={touched.wallThickness && errors.wallThickness}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Grade'}
                                            placeholder={'E.g. S-135'}
                                            value={values.grade}
                                            name={"grade"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.grade && errors.grade)}
                                            helperText={touched.grade && errors.grade}
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <DefaultInput
                                            label={'Description'}
                                            placeholder={'E.g. IPC, Hard Band'}
                                            value={values.description}
                                            name={"description"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.description && errors.description)}
                                            helperText={touched.description && errors.description}
                                        />
                                    </Grid>

                                    {values.selectedCategory != null && values.selectedCategory._id != linePipeId && <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Connection'}
                                            placeholder={'E.g. S-135'}
                                            // type='number'
                                            value={values.connectionName}
                                            name={"connectionName"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.connectionName && errors.connectionName)}
                                            helperText={touched.connectionName && errors.connectionName}
                                        />
                                    </Grid>}

                                    {values.selectedCategory != null && values.selectedCategory._id == tubingId && <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Range'}
                                            placeholder={'E.g. S-135'}
                                            value={values.range}
                                            name={"range"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.range && errors.range)}
                                            helperText={touched.range && errors.range}
                                        />
                                    </Grid>}

                                    {values.selectedCategory != null && values.selectedCategory._id == linePipeId && <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Coating'}
                                            placeholder={'E.g. S-135'}
                                            value={values.coating}
                                            name={"coating"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.coating && errors.coating)}
                                            helperText={touched.coating && errors.coating}
                                        />
                                    </Grid>}

                                    {values.selectedCategory != null && values.selectedCategory._id == linePipeId && <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Surface'}
                                            placeholder={'E.g. S-135'}
                                            value={values.surface}
                                            name={"surface"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.surface && errors.surface)}
                                            helperText={touched.surface && errors.surface}
                                        />
                                    </Grid>}

                                    {values.selectedCategory != null && values.selectedCategory._id == linePipeId && <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Gauge'}
                                            placeholder={'E.g. 0.000'}
                                            value={values.gauge}
                                            type='number'
                                            name={"gauge"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.gauge && errors.gauge)}
                                            helperText={touched.gauge && errors.gauge}
                                        />
                                    </Grid>}

                                    {values.selectedCategory != null && values.selectedCategory._id == linePipeId && <Grid item xs={4}>
                                        <DefaultInput
                                            label={'End Type'}
                                            placeholder={'E.g. S-135'}
                                            value={values.endType}
                                            name={"endType"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.endType && errors.endType)}
                                            helperText={touched.endType && errors.endType}
                                        />
                                    </Grid>}

                                    {values.selectedCategory != null && values.selectedCategory._id == linePipeId && <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Cut Off'}
                                            placeholder={'E.g. S-135'}
                                            value={values.cutOff}
                                            name={"cutOff"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.cutOff && errors.cutOff)}
                                            helperText={touched.cutOff && errors.cutOff}
                                        />
                                    </Grid>}

                                    {values.arrayAttribute.map((data, index) => {
                                        if (data.isOn) {
                                            return (<Grid item xs={4} key={data.id}>
                                                <DefaultInput
                                                    label={data.title}
                                                    placeholder={`E.g.${data.formate}`}
                                                    type={data.type}
                                                    value={data.value}
                                                    // value={values[data.id + ""]}
                                                    name={data.id + ""}
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        var newValue = e.target.value
                                                        // values[data.id + ""] = newValue
                                                        const updatedArrayAttribute = values.arrayAttribute.map((res) => {
                                                            if (res.id === data.id) {
                                                                var obj = { ...res }
                                                                obj.value = newValue
                                                                return obj
                                                            }
                                                            return res;
                                                        });
                                                        setFieldValue("arrayAttribute", updatedArrayAttribute)
                                                    }}
                                                // error={Boolean(touched[data.id + ""] && errors[data.id + ""])}
                                                // helperText={(touched[data.id + ""] && errors[data.id + ""])}
                                                />
                                            </Grid>)
                                        }
                                    })}
                                </Grid>
                            </Box>
                            <Stack direction={'row'} spacing={1}>
                                <Box flexGrow={1}>
                                    {pipeProfileID != "" && (
                                        <DefaultButton
                                            onClick={(e) => {
                                                setDeleteMessage("Are you sure you want to delete?")
                                                setDeleteConfirmationShouldOpen(true)
                                            }}
                                            color={'error'}
                                        >
                                            Delete
                                        </DefaultButton>
                                    )}
                                </Box>
                                <DefaultButton onClick={onClose}>Cancel</DefaultButton>
                                <DefaultButton
                                    color={'success'}
                                    disabled={isSubmitting}
                                    type="submit">
                                    Save
                                </DefaultButton>
                            </Stack>
                        </Stack>
                        {
                            deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={deleteMessage}
                                updateConfirmAlert={(e, value) => {
                                    console.log(e, value)
                                    setDeleteConfirmationShouldOpen(false)
                                    updateConfirmAlert(value)
                                }} />)
                        }
                        <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </form>
                )}
            </Formik>
        </Popup>
    );
};
