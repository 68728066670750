import { FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';
import { ArrowIcon } from '../../assets/icons/ArrowIcon';
import { theme } from '../../core/theme/theme.js';
export const DropDown = (props) => {
    const handleSelectChange = (event) => {
        props.onChange && props.onChange(event);
    };
    return (
        <FormControl variant='standard' sx={{ minWidth: '100%', ...props.sx }}>
            <Select
                IconComponent={ArrowIcon}
                {...props}
                sx={{
                    width: '100%',
                    '&::before': {
                        borderBottom: '2px dotted',
                        borderColor: 'primary.main',
                    },
                    '&:hover:not(.Mui-disabled, .Mui-error)': {
                        '&::before, &::after': {
                            borderBottom: '2px dotted',
                        },
                    },
                    '.MuiSelect-select': {
                        py: '10px',
                        fontSize: 13,
                        // fontWeight: 700,
                    },
                    '& .MuiSelect-icon': {
                        top: 'calc(50% - 4px)',
                        right: '12px',
                        stroke: theme.palette.primary.main,
                    },
                }}

                value={props.value}
                onChange={handleSelectChange}

            >
                <MenuItem key={-1} value={-1}> {props.placeholder ? `All (${props.placeholder})` : "All"}</MenuItem>
                {props.children}
            </Select>
        </FormControl>
    );
};
