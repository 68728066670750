import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import React from 'react';

export const DefaultCircularProgress = ({ title, value }) => {
    return (
        <Stack spacing={1}>
            <Typography textAlign={'center'} fontSize={13} color={'#A8A8A7'}>{title}</Typography>
            <Box sx={{ position: 'relative', display: 'inline-flex', zIndex: 3 }}>
                <CircularProgress
                    size={78}
                    variant='determinate'
                    value={value}
                    sx={{
                        '*': {
                            strokeWidth: '2.4px',
                        },
                        svg: {
                            '& > *': {
                                color: '#0062FF',
                            },
                        },
                    }}
                />
                <Box
                    sx={{
                        width: '76px',
                        height: '76px',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: '3.5px solid black',
                        borderColor: 'rgba(0, 98, 255, 0.1)',
                        borderRadius: '50%',
                        zIndex: '-1',
                    }}
                />
                <Box
                    sx={{
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography fontSize={20} fontWeight={700} variant='caption' component='div'>
                        {`${Math.round(value)}%`}
                    </Typography>
                </Box>
            </Box>
        </Stack>
    );
};
