import { FormControl, Autocomplete, TextField } from '@mui/material';
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
export const DefaultSelect = ({ label, name, value, options, getOptionLabel, isOptionEqualToValue, sx, ...otherProps }) => {
    return (
        <FormControl variant='standard' sx={{ minWidth: '100%', alignItems: 'center', ...sx }}>
            <Autocomplete
                {...otherProps}
                popupIcon={<KeyboardArrowDownIcon style={{ color: '#262B40' }} />}
                autoSelect={false}
                value={value}
                options={options}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                onChange={(event, newValue) => {
                    otherProps.onChange && otherProps.onChange(event, newValue);
                }}
                style={{ width: '100%' }}
                renderInput={(params) => (
                    <TextField
                        {...otherProps}
                        {...params}
                        label={label}
                        name={name}
                        variant='standard'
                        onKeyDown={(e) => { if (e.key !== 'Tab') { e.preventDefault(); } }}
                        InputLabelProps={{
                            ...params.InputProps,
                            shrink: true,
                            readOnly: true,
                            style: { color: "#A8A8A7" }
                        }}

                        FormHelperTextProps={{
                            sx: {
                                marginTop: 0,
                            },
                        }}
                        sx={{
                            width: '100%',
                            '.MuiFormLabel-root': {
                                fontSize: 15,
                                transform: 'unset',
                                color: '#A8A8A7',
                                position: 'static',
                                '&.Mui-disabled': {
                                    color: 'primary.main',
                                },
                            },
                            '.MuiInput-input': {
                                py: '10px',
                                fontWeight: 700,
                                fontSize: 15,
                                '&::placeholder': {
                                    color: '#A8A8A7',
                                    opacity: 1,
                                },
                            },
                            '.MuiInputBase-root': {
                                mt: label ? 1.4 : 0,
                                '&::before': {
                                    borderBottom: '2px dotted',
                                    borderColor: 'primary.main',
                                },
                                '&.Mui-disabled': {
                                    '&::before': {
                                        display: 'none'
                                    }
                                },
                            },

                        }}

                    />
                )}
            />
        </FormControl>
    );
};
