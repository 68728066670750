import { TextField, Stack, Typography } from '@mui/material';
import React from 'react';

export const DefaultTextFIeld = ({ label, placeholder, ...otherProps }) => {
    return (
        <Stack direction={'row'} alignItems={'start'} justifyContent={'space-between'}>
            <Typography color={'#A8A8A7'} fontSize={13}>{label}</Typography>
            <TextField
                variant='outlined'
                placeholder={placeholder}
                sx={{
                    width: "70%",
                    border: '1px solid',
                    borderColor: 'grey.light',
                    borderRadius: '10px',
                    fontSize: 14,
                    '.MuiInputBase-root': {
                        height: otherProps.multiline ? "100%" : '40px',
                    },
                    '.MuiInputBase-input': {
                        height: '100% !important',
                        overflowY: 'auto !important',
                        '&::-webkit-scrollbar': {
                            width: '4px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            width: '4px',
                            bgcolor: 'grey.400',
                        },
                    },
                }}
                {...otherProps}
            />
        </Stack>
    );
};
