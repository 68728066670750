import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import 'mapbox-gl/dist/mapbox-gl.css';
import './assets/styles/general.scss';
import { App } from './App.jsx';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { theme } from './core/theme/theme.js';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from './contexts/JWTContext';
import { SettingsProvider } from './contexts/SettingsContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <StrictMode>
        <SettingsProvider>
            <BrowserRouter>
                <AuthProvider>
                    <ThemeProvider theme={theme}>
                        <SnackbarProvider>
                            <App />
                        </SnackbarProvider>
                    </ThemeProvider>
                </AuthProvider>
            </BrowserRouter>
        </SettingsProvider>
    </StrictMode>, document.getElementById('root')
);
