import { Cluster } from '../../assets/icons/Cluster';
import { InspectionsIcon } from '../../assets/icons/InspectionsIcon';
import { InventoryIcon } from '../../assets/icons/InventoryIcon';
import { Metrics } from '../../components/Metrics';
import { Details } from './Details';
import { tabs } from './data/tabs';
import { ColorStatus } from '../../ui/PipeTallyColorStatus/PipeTallyColorStatus';
import { Tabs } from '../../ui/Tabs';
import { Backdrop, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Attachments } from './Attachments.jsx';
import { Notes } from './Notes.jsx';
import { Map } from './Map.jsx';
import api from '../../lib/axios'
import { arrayStatus } from '../../views/PipeTally/data/PipeTallyData.jsx';
import { useNavigate } from 'react-router-dom';
import { PipeTallyNew } from './PipeTallyNew';
import { Transfer } from './Transfer';
export const PipeTallyDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const selectedDataId = location.state.id
    const [activeTab, setActiveTab] = useState(tabs[0].value);
    const [isLoading, setIsLoading] = useState(false);
    const [objPipeTally, setObjPipeTally] = useState({})
    const [isEditOpened, setIsEditOpened] = useState(selectedDataId == "" ? true : false);
    const [metrics1, setMetrics1] = useState([
        {
            title: 'Total Footage',
            value: '0',
        },
        {
            title: 'Total Joints',
            value: '0',
        },
        {
            title: 'Tonnage',
            value: '00.00',
        },
    ]);
    const metrics2 = [
        {
            title: 'Health Score',
            value: '82%',
            hasIndicator: true,
        },
        {
            title: 'Utilization',
            value: '60%',
            icon: <Cluster />,
        },
        {
            title: 'Last 30 Days',
            value: '+50%',
            isGreen: true,
        },
    ];
    const handleChangeTab = (tab) => {
        if (!isEditOpened) {
            setActiveTab(tab);
            if (tab === tabs[0].value) {
                if (selectedDataId != "") {
                    getInventoryData()
                }
            }
        }
    };
    const onEditClick = () => {
        setIsEditOpened(true);
    };

    useEffect(() => {
        if (selectedDataId != "") {
            getInventoryData()
        }
    }, []);

    function formatNumber(num) {
        const stringValue = String(Math.floor(num));
        const firstFourDigits = stringValue.slice(0, 4);
        const firstFourDigitsDouble = parseFloat(firstFourDigits);

        if (!isNaN(firstFourDigitsDouble)) {
            const result = firstFourDigitsDouble / 100;
            const formattedResult = result.toFixed(2).padStart(5, '0');
            return formattedResult; // Output: "44.52"
        }
        return "00.00";
    }

    function getInventoryData() {
        setIsLoading(true)
        api.get('/apis/Tallies/getDetailById/' + selectedDataId).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setObjPipeTally(resData)
                var weight = resData.pipeProfile.attributes.find(obj => obj.title == "Weight (lb/ft)")?.value
                var floatWeight = isNaN(parseFloat(weight)) ? 0.0 : parseFloat(weight)
                var floatFootage = isNaN(parseFloat(resData.footage.replace(/,/g, ''))) ? 0.0 : parseFloat(resData.footage.replace(/,/g, ''));
                var tonnage = floatFootage * floatWeight
                tonnage = formatNumber(tonnage)
                var matrix = [
                    {
                        title: 'Total Footage',
                        value: resData.footage,
                    },
                    {
                        title: 'Total Joints',
                        value: resData.quantity,
                    },
                    {
                        title: 'Tonnage',
                        value: tonnage,
                    },
                ]
                setMetrics1(matrix)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }
    const isFirstTab = activeTab === tabs[0].value
    const isSecondTab = activeTab === tabs[1].value
    const isThirdTab = activeTab === tabs[2].value
    const isForthTab = activeTab === tabs[3].value
    const isFifthTab = activeTab === tabs[4].value

    return (
        <Stack py={2} px={2.5} width={'100%'}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mb={3}>
                <Stack direction={'row'} spacing={8}>
                    <Stack spacing={2}>
                        <Typography fontSize={20}>Tally ID</Typography>
                        <Typography fontSize={25} fontWeight={700}>
                            {objPipeTally.tallyNo}
                        </Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                    <Stack spacing={2}>
                        <Stack direction={'row'} spacing={3} alignItems={'center'}>
                            <Typography fontSize={20}>Status</Typography>
                            <ColorStatus code={(objPipeTally.status)} />
                        </Stack>
                        <Typography fontSize={25}>{arrayStatus.find(obj => obj.id == objPipeTally.status)?.value}</Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                    <Stack spacing={2}>
                        <Stack direction={'row'} spacing={3} alignItems={'center'}>
                            <Typography fontSize={20}>Title</Typography>
                        </Stack>
                        <Typography fontSize={25}>{objPipeTally.title}</Typography>
                    </Stack>
                </Stack>
            </Stack>
            <Stack direction={'row'} gap={4} rowGap={2} flexWrap={'wrap'} mb={3}>
                <Metrics icon={<InventoryIcon />} data={metrics1} />
                <Metrics icon={<InspectionsIcon />} data={metrics2} />
            </Stack>
            <Tabs data={tabs} activeTab={activeTab} setActiveTab={handleChangeTab} />
            <Stack
                flex={1}
                border={'1px solid'}
                borderColor={'grey.light'}
                borderRadius={'5px'}
                overflow={'hidden'}
                bgcolor={'common.white'}
                position={'relative'}
            >
                {isFirstTab && <Details inventoryID={selectedDataId} disabled={isEditOpened} onEditClick={onEditClick} objTally={objPipeTally} />}
                {isSecondTab && <Transfer disabled={isEditOpened} tallyID={selectedDataId} currentSiteID={objPipeTally.locationID} />}
                {isThirdTab && <Attachments inventoryID={selectedDataId} disabled={isThirdTab} />}
                {isForthTab && <Notes inventoryID={selectedDataId} disabled={isThirdTab} />}
                {isFifthTab && <Map arrayInventory={[objPipeTally]} />}
                {isEditOpened &&
                    <PipeTallyNew
                        inventoryID={selectedDataId}
                        closeDetails={(e) => {
                            if (selectedDataId == "") {
                                navigate(-1)
                            } else {
                                setIsEditOpened(false)
                                if (e) {
                                    getInventoryData()
                                }
                            }

                        }}
                    />
                }
            </Stack>
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack>
    );
};
