import mapboxgl from 'mapbox-gl';
import { Box, Backdrop, CircularProgress, Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import api from '../../lib/axios';
import moment from 'moment';
import pin from '../../assets/images/pin.png';
mapboxgl.accessToken = 'pk.eyJ1Ijoic3RlZWxkbmEiLCJhIjoiY2x3dGNzNjRtMDI4aTJqb24wc2tqeGF4byJ9.XjKo_-RCP80Ytrn6K07MxA';

export const DetailMap = ({ sx, truckData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const mapContainer = useRef(null);
    const map = useRef(null);

    const routeMap = (arrayData, truckData) => {

        if (map.current) {
            map.current.remove();
            map.current = null
        }
        var lat = 42.16
        var lng = -71.30
        if (arrayData.length > 0) {
            lng = arrayData[0].positionLongitude
            lat = arrayData[0].positionLatitude
        }
        if (!map.current && mapContainer.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/steeldna/clwunfa5n01ak01pnflmb60p4',
                center: [lng, lat],
                zoom: 9,
            });
            map.current.addControl(new mapboxgl.NavigationControl());

            let arrayCoordinates = []

            arrayData.map((data) => {

                if (data.positionLongitude != null && data.positionLatitude != null) {
                    var longitude = data.positionLongitude
                    var latitude = data.positionLatitude
                    arrayCoordinates.push([longitude, latitude])
                }

            })

            map.current.on('load', () => {
                if (arrayData.length > 0) {
                    fetchRouteFromDirectionsAPI(arrayCoordinates).then((route) => {
                        console.log("route", route)
                        if (!map.current.getSource('route')) {
                            map.current.addSource('route', {
                                'type': 'geojson',
                                'data': {
                                    'type': 'Feature',
                                    'properties': {},
                                    'geometry': route
                                }
                            });
                        }
                        if (!map.current.getLayer('route')) {
                            map.current.addLayer({
                                'id': 'route',
                                'type': 'line',
                                'source': 'route',
                                'layout': {
                                    'line-join': 'round',
                                    'line-cap': 'round'
                                },
                                'paint': {
                                    'line-color': '#0061FF',
                                    'line-width': 6
                                }
                            });
                        }

                        if (route.coordinates != null && route.coordinates.length > 0) {
                            var startMarkerEl = document.createElement('div');
                            startMarkerEl.className = 'custom-marker';
                            startMarkerEl.style.backgroundImage = `url(${pin})`;
                            startMarkerEl.style.backgroundSize = 'cover';
                            startMarkerEl.style.width = '25px';
                            startMarkerEl.style.height = '25px';

                            var startMarker = new mapboxgl.Marker({ element: startMarkerEl })
                                .setLngLat(route.coordinates[0])
                                .addTo(map.current);

                            startMarker.getElement().addEventListener('click', function () {
                                const coordinates = startMarker.getLngLat().toArray();
                                showPopUp(truckData, coordinates)
                            });

                            var endMarkerEl = document.createElement('div');
                            endMarkerEl.className = 'custom-marker';
                            endMarkerEl.style.backgroundImage = `url(${pin})`;
                            endMarkerEl.style.backgroundSize = 'cover';
                            endMarkerEl.style.width = '25px';
                            endMarkerEl.style.height = '25px';
                            var endMarker = new mapboxgl.Marker({ element: endMarkerEl })
                                .setLngLat(route.coordinates[route.coordinates.length - 1])
                                .addTo(map.current);

                            endMarker.getElement().addEventListener('click', function () {
                                const coordinates = endMarker.getLngLat().toArray();
                                showPopUp(truckData, coordinates)
                            });
                        }

                        map.current.on('click', 'route', (e) => {
                            const coordinates = e.lngLat.toArray();
                            showPopUp(truckData, coordinates)
                        });

                    });
                }
            });
            const fetchRouteFromDirectionsAPI = async (coords) => {
                try {
                    const response = await fetch(
                        `https://api.mapbox.com/directions/v5/mapbox/driving/${coords.join(";")}?access_token=${mapboxgl.accessToken}&geometries=geojson&overview=full`,
                        {
                            method: "GET",
                            headers: {
                                Authorization: `Bearer ${mapboxgl.accessToken}`,
                            },
                        }
                    );

                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const data = await response.json();
                    if (data.routes != null && data.routes.length > 0) {
                        console.log("data from map box", data.routes[0].geometry)
                        return data.routes[0].geometry;
                    } else {
                        return []
                    }
                } catch (error) {
                    console.error('Error fetching route:', error);
                    return []; // or handle error appropriately
                }
            };


        }
    };

    function showPopUp(truckData, coordinates) {
        new mapboxgl.Popup({ closeOnClick: true })
            .setLngLat(coordinates)
            .setHTML(
                `<div class="marker-wrapper-detail"  style='border: 2px solid #000000; display: flex; flex-direction: column; align-items: center; text-align: center;'>
            <h3 class="marker-title">${truckData.truckName || "-"}</h3>
            <div class="marker-bottom">
                <div class="marker-item-name">${truckData.serialNumber || "-"}</div>
            </div>
        </div>
        `
            )
            .addTo(map.current);
    }

    const getDirection = (truckData) => {
        var startDate = moment(truckData.startDate).format("MM-DD-YYYY")
        var endDate = truckData.endDate ? moment(truckData.endDate).format("MM-DD-YYYY") : moment().format("MM-DD-YYYY")
        setIsLoading(true);
        var data = {
            trackerID: truckData.trackerID,
            startDate: startDate,
            endDate: endDate
        }
        api.post('/apis/Trackers/getDirection', data).then((res) => {

            if (res.data.success) {
                var resData = res.data.data
                if (resData.length > 0) {
                    truckData.serialNumber = resData[0].deviceSerialNumber
                }

                routeMap(resData, truckData)
                setIsLoading(false);
            } else {
                routeMap([], truckData)
                setIsLoading(false);
            }
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }

    useEffect(() => {
        if (truckData != null && truckData._id != null) {
            getDirection(truckData)
        } else {
            if (map.current) {
                map.current.remove();
                map.current = null
            }
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/steeldna/clwunfa5n01ak01pnflmb60p4',
                center: [-71.30, 42.16],
                zoom: 9,
            });
            map.current.addControl(new mapboxgl.NavigationControl());
        }
    }, [truckData]);

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    ...sx,
                }}
                ref={mapContainer}
            />
            <Backdrop style={{ zIndex: 10 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};
