import React from 'react';
import { Box } from '@mui/material';
import { getPipeTallyStatusColor } from '../../views/PipeTally/data/PipeTallyData.jsx';

export const ColorStatus = ({ code, size = 10 }) => {
    return (
        <Box
            sx={{
                width: size,
                height: size,
                borderRadius: '50%',
                backgroundColor: getPipeTallyStatusColor(code),
            }}
        />
    );
};
