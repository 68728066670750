import { NotificationsIcon } from '../../assets/icons/NotificationsIcon';
import { DefaultAvatar } from '../../ui/DefaultAvatar';
import { Dropdown } from '../../ui/Dropdown';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, Menu, MenuItem, Stack, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styleDropdown from '../../ui/Dropdown/index.module.scss';
import useAuth from '../../hooks/useAuth';
import api from '../../lib/axios';
import { useLogo } from '../../contexts/CompanyContext';
export const Header = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { logo, updateLogo, fullName, updateFullName } = useLogo();
    useEffect(() => {
        if (logo == null || logo == "") {
            getData()
        }
    }, []);

    function getData() {
        api.post('/apis/companies/byID').then((res) => {
            if (res.data.success) {
                var obj = res.data.data
                console.log("got data", obj)
                localStorage.setItem("companyName", obj.companyName)
                localStorage.setItem("fullName", localStorage.getItem("role") == "SuperAdmin" ? obj.contact : obj.accountFirstName + " " + obj.accountLastName)
                updateFullName(localStorage.getItem("role") == "SuperAdmin" ? obj.contact : obj.accountFirstName + " " + obj.accountLastName)
                if (obj.logoPath) {
                    updateLogo(obj.logoPath);
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);

    }

    const handleProfile = async () => {
        setAnchorEl(null);
        navigate('/profile/editProfile');
    }

    const handleChangePassword = async () => {
        setAnchorEl(null);
        navigate('/profile/changePassword');
    }

    const handleLogout = async () => {
        try {
            handleClose();
            updateFullName("")
            updateLogo("")
            await logout();
            navigate('authentication/login');
        } catch (err) {
            console.error(err);
            //   enqueueSnackbar('Unable to logout', {
            //     anchorOrigin: {
            //       horizontal: 'right',
            //       vertical: 'top'
            //     },
            //     variant: 'error'
            //   });
        }
    }
    const currentDate = new Date();

    const dateFormatOptions = {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    };

    const formattedDate = currentDate.toLocaleString('en-US', dateFormatOptions);

    const myString = fullName ? fullName : "";
    const words = myString.split(' ');
    const firstCharacters = words.map(word => word[0]);

    return (
        <Stack
            position={'relative'}
            direction={'row'}
            alignItems={'center'}
            component={'header'}
            justifyContent={'space-between'}
            height={75}
            pl={2.5}
            pr={6}
            flexShrink={0}
            sx={{
                borderBottom: '1px solid',
                borderColor: 'grey.light',
            }}
        >
            <Stack spacing={0.5}>
                <Typography fontSize={13}>
                    {/* Monday, November 11, 2023 */}
                    {formattedDate}
                </Typography>
                <Typography fontSize={13} fontWeight={700}>
                    {fullName} Logged In
                </Typography>
            </Stack>
            {(logo != null && logo !== "") && (
                <img
                    src={logo}
                    alt="logo"
                    style={{ height: '25px', width: 'auto' }}
                />
            )}

            <Stack direction={'row'} spacing={6} alignItems={'center'}>
                <Dropdown>
                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                        <Box
                            sx={{
                                position: 'relative',
                                svg: {
                                    width: 24,
                                    height: 24,
                                    position: 'static',
                                    transform: 'unset',
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    width: 10,
                                    height: 10,
                                    backgroundColor: 'green.main',
                                    borderRadius: '10px',
                                    position: 'absolute',
                                    right: 0,
                                    top: 2,
                                    zIndex: 3,
                                }}
                            />
                            <NotificationsIcon />
                        </Box>
                        <Typography fontSize={13}>Notifications</Typography>
                    </Stack>
                </Dropdown>
                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                    <DefaultAvatar>{firstCharacters.join('')}</DefaultAvatar>
                    {/* <Dropdown>
                        <Stack
                            alignItems={'center'}
                            justifyContent={'center'}
                            color={'#8F98AB'}
                            fontSize={13}
                            fontWeight={700}
                        >
                            Anton
                        </Stack>
                    </Dropdown> */}
                    <div>
                        <Box className={styleDropdown.button} onClick={handleClick}>
                            <Stack
                                alignItems={'center'}
                                justifyContent={'center'}
                                color={'#8F98AB'}
                                fontSize={13}
                                fontWeight={700}
                            >
                                {localStorage.getItem('fullName')}
                            </Stack>
                            <KeyboardArrowDown />
                        </Box>
                        <Menu
                            id='basic-menu'
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            {localStorage.getItem("isGuestLogin") != "1" && <MenuItem onClick={handleProfile}>Profile</MenuItem>}
                            {localStorage.getItem("isGuestLogin") != "1" && <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>}
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </div>
                </Stack>
            </Stack>
        </Stack>
    );
};
