export const tabs = [
    {
        title: 'Pipe',
        value: 'pipe',
    },
    {
        title: 'Transfer',
        value: 'transfer',
    },
    {
        title: 'Attachments',
        value: 'attachments',
    },
    {
        title: 'Notes',
        value: 'notes',
    },
    {
        title: 'Map',
        value: 'map',
    },
];
