import { LiveTrackingIcon } from '../../../assets/icons/LiveTrackingIcon';
import { InspectionsIcon } from '../../../assets/icons/InspectionsIcon';
import { InventoryIcon } from '../../../assets/icons/InventoryIcon';
import { MapViewIcon } from '../../../assets/icons/MapViewIcon';
import { PipeProfilesIcon } from '../../../assets/icons/PipeProfilesIcon';
import { PipeTallyIcon } from '../../../assets/icons/PipeTallyIcon';
import { SettingsIcon } from '../../../assets/icons/SettingsIcon';

export const guestMenu = [
    {
        title: 'Inventory',
        link: '/inventory',
        icon: <InventoryIcon />,
    },
    {
        title: 'Map View',
        link: '/mapView',
        icon: <MapViewIcon />,
    },
];

export const mainMenu = [
    {
        title: 'Inventory',
        link: '/inventory',
        icon: <InventoryIcon />,
    },
    {
        title: 'Pipe Tally',
        link: '/pipe-tally',
        icon: <PipeTallyIcon />,
    },
    // {
    //     title: 'Inspections',
    //     link: '/inspections',
    //     icon: <InspectionsIcon />,
    // },
    {
        title: 'Live Tracking',
        link: '/live-tracking',
        icon: <LiveTrackingIcon />,
    },
    {
        title: 'Map View',
        link: '/mapView',
        icon: <MapViewIcon />,
    },
    {
        title: 'Pipe Profiles',
        link: '/pipe-profiles',
        icon: <PipeProfilesIcon />,
    },
];

export const systemMenu = [
    {
        title: 'Settings',
        link: '/settings',
        icon: <SettingsIcon />,
        items: [
            {
                title: 'Users',
                link: '/settings/users',
                roles: ['SuperAdmin', '1']
            },
            {
                title: 'Company',
                link: '/settings/company',
                roles: ['SuperAdmin', '1', '2', '3']
            },
            {
                title: 'Sites',
                link: '/settings/sites',
                roles: ['SuperAdmin', '1', '2', '3']
            },
            {
                title: 'Zones',
                link: '/settings/zones',
                roles: ['SuperAdmin', '1', '2', '3']
            },
            {
                title: 'Providers',
                link: '/settings/providers',
                roles: ['SuperAdmin', '1', '2', '3']
            },
            {
                title: 'Configuration',
                link: '/settings/configuration',
                roles: ['SuperAdmin', '1', '2', '3']
            },
            {
                title: 'Trackers',
                link: '/settings/trackers',
                roles: ['SuperAdmin', '1', '2', '3']
            },
        ],
    },
];
